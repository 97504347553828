import React from "react";
import AppRoutes from "./routes/AppRoutes";
import "./web.css";
import "./App.css";
import ToastNotification from "./components/core/ToastNotifications";
import Loader from "./components/core/Loader";

function App() {
  return (
    <>
      {/* <Loader /> */}
      <AppRoutes />
      <ToastNotification />
    </>
  );
}

export default App;
