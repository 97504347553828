import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  user: null,
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    removeToken: (state) => {
      state.token = null;
    },
    clearUser: (state) => {
      state.user = null;
      state.token = null;
    },
  },
});

export const userSelector = (state: any) => state.user.user;
export const tokenSelector = (state: any) => state.user.token;
export const resetTokenSelector = (state: any) => state.user.reset_pass_token;

const { actions, reducer } = userSlice;

export const { setUser, setToken, removeToken, clearUser } = actions;

export default reducer;
