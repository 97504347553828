import React, { useEffect, useState } from "react";
import {
  editProfileImage,
  getProfileInfo,
} from "../../../services/website/profileService";
import { ProfileDetails } from "../../../interface/ProfileDetails";
import DummyProfile from "../../../assets/images/dummy_logo.png";
import Modal from "../../../components/core/Modal";
import { pathRoute } from "../../../routes/pathRoute";
import IconCamera from "../../../assets/images/icon_camera.svg";
import CoverPopup from "./CoverPopup";
import IconTab from "../../../assets/images/icon_tab.svg";
import { IoQrCode } from "react-icons/io5";
import Paragraph from "../../../components/core/typography/Paragraph";
import { ImLocation } from "react-icons/im";
import TabColorPopup from "./TabColorPopup";
import QRPopup from "./QRPopup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { setUser } from "../../../redux/slices/userSlice";

interface profileInterface {
  isEditProfile: boolean;
}

const MyProfileLeftSection = ({ isEditProfile }: profileInterface) => {
  const [isOpen, setIsOpen] = useState(false);
  const [coverPopup, setCoverPopup] = useState(false);
  const [tabColorPopup, setTabColorPopup] = useState(false);
  const [qrPopup, setQRPopup] = useState(false);
  const [profileData, setProfileData] = useState<ProfileDetails | undefined>();
  const dispatch = useDispatch();

  const fetchProfileData = async () => {
    try {
      const response = await getProfileInfo();
      if (response?.data?.code === 200) {
        setProfileData(response?.data?.data);
        dispatch(setUser(response?.data?.data));
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };
  const closeCoverPopup = () => {
    setCoverPopup(false);
  };
  const closeTabColorPopup = () => {
    setTabColorPopup(false);
  };
  const closeQRPopup = () => {
    setQRPopup(false);
  };

  const handleClosePopup = () => {
    closeCoverPopup();
    fetchProfileData();
  };

  const handleProfileImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    dispatch(showLoader());
    try {
      if (file) {
        if (file.type.includes("image")) {
          const formData = new FormData();
          formData.append("avatar", file);
          const response = await editProfileImage(formData);
          if (response?.data?.code === 201) {
            fetchProfileData();
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="xl:col-span-5 lg:col-span-10 col-span-12">
      <div className="border border-medium-grey rounded-2xl overflow-hidden">
        <div className="relative">
          <div className="relative h-[115px] bg-gradient-to-r from-[#E93641] to-black">
            {profileData?.coverImage && (
              <img
                src={profileData?.coverImage}
                alt="profile-cover"
                width={438}
                height={115}
                className="w-full h-[115px] object-cover"
              />
            )}
            <button
              onClick={() => setCoverPopup(!coverPopup)}
              // htmlFor="cover"
              className="w-6 h-6 rounded-full absolute bottom-3 right-3 inline-flex items-center justify-center bg-gradient-to-r from-[#8C8C8C] -from-[6.79%] to-[#222433]/0 to-[155.78%] backdrop-blur-[15px] cursor-pointer"
            >
              <img src={IconCamera} alt="icon-camera" width={15} height={15} />
            </button>
            <Modal
              open={coverPopup}
              header
              onClose={closeCoverPopup}
              width="sm:w-[448px] w-[300px]"
            >
              <CoverPopup handleClosePopup={handleClosePopup} />
            </Modal>
          </div>
          <div className="flex justify-between items-center sm:px-4 px-3 sm:-mt-[48px] -mt-[36px] mb-3">
            <div className="relative">
              <img
                src={getProfileImage(profileData?.avatar || "")}
                alt="profile-picture"
                width={100}
                height={100}
                className="rounded-full object-cover sm:w-[100px] sm:h-[100px] w-[80px] h-[80px]"
              />
              <label
                htmlFor="cover"
                className="w-6 h-6 rounded-full absolute bottom-3 -right-1.5 inline-flex items-center justify-center bg-gradient-to-r from-[#8C8C8C] -from-[6.79%] to-[#222433]/0 to-[155.78%] backdrop-blur-[15px] cursor-pointer"
              >
                <input
                  type="file"
                  name=""
                  id="cover"
                  className="sr-only"
                  onChange={handleProfileImage}
                />
                <img
                  src={IconCamera}
                  alt="icon-camera"
                  width={15}
                  height={15}
                />
              </label>
            </div>
            <div className="inline-flex gap-2 items-center">
              <img
                src={IconTab}
                alt="icon-tab"
                className="mt-10 cursor-pointer"
                onClick={() => setTabColorPopup(!tabColorPopup)}
              />
              <IoQrCode
                size={20}
                color="#4D4B4B"
                className="mt-10 cursor-pointer"
                onClick={() => setQRPopup(!qrPopup)}
              />
            </div>
            <Modal
              open={tabColorPopup}
              header
              onClose={closeTabColorPopup}
              width="sm:w-[400px] w-[300px]"
            >
              <TabColorPopup />
            </Modal>
            <Modal
              open={qrPopup}
              header
              onClose={closeQRPopup}
              width="sm:w-[424px] w-[300px]"
              modalPadding="pt-10 pb-30 px-6"
            >
              <QRPopup />
            </Modal>
          </div>
          <div className="flex items-center sm:flex-nowrap flex-wrap gap-2 justify-between sm:px-4 px-3 pb-3">
            <div className="">
              <Paragraph text18 className="font-medium mb-1 !leading-4">
                @{profileData?.nickname}
              </Paragraph>
              <Paragraph
                text16
                className="!text-[#4D4B4B] !leading-[14px] !font-normal flex items-center gap-1"
              >
                <ImLocation size={14} />
                {profileData?.country?.name}
              </Paragraph>
            </div>
            {isEditProfile ? (
              ""
            ) : (
              <Link
                to={pathRoute?.website?.editProfile}
                className="bg-site-yellow px-3 py-2 pb-1.5 rounded-[50px] inline-flex items-center gap-1 text-sm leading-4 font-medium"
              >
                Edit Profile
              </Link>
            )}
          </div>
          {isEditProfile ? (
            ""
          ) : (
            <>
              <div className="border-b border-medium-grey sm:px-4 px-3 pb-4">
                <div className="flex items-center gap-2">
                  <Paragraph text16 className="!leading-[15px] !font-normal">
                    {profileData?.followers} Followers
                  </Paragraph>
                  <span className="w-[3px] h-[3px] rounded-full bg-site-grey"></span>
                  <Paragraph text16 className="!leading-[15px] !font-normal">
                    13 Post
                  </Paragraph>
                </div>
              </div>

              <div className="py-4 sm:px-4 px-3">
                <Paragraph text16 className="!leading-4 mb-3 !font-medium">
                  Interest
                </Paragraph>
                <div className="flex items-center gap-2 flex-wrap mb-4">
                  {profileData?.interest?.map((item: any) => {
                    return (
                      <span className="border border-medium-grey rounded-2xl text-sm leading-4 font-medium px-3 py-1">
                        {item?.interest?.title}
                      </span>
                    );
                  })}
                </div>
                {profileData?.description && (
                  <>
                    <Paragraph
                      text16
                      className="!leading-4 mb-2.5 !font-medium"
                    >
                      About
                    </Paragraph>
                    <Paragraph
                      text14
                      className="leading-[18px] !text-[#4D4B4B] !font-normal"
                    >
                      {profileData?.description}
                    </Paragraph>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyProfileLeftSection;
