import React from "react";
import Logo from "../../../assets/images/black_logo.svg";
import HeadingOne from "../../../components/core/typography/HeadingOne";
import Paragraph from "../../../components/core/typography/Paragraph";
import FormLabel from "../../../components/core/typography/FormLabel";
import InputType from "../../../components/core/form-components/InputType";
import TextLink from "../../../components/core/form-components/TextLink";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../../components/core/form-components/Button";
import { pathRoute } from "../../../routes/pathRoute";
import { forgetPasswordValidationSchema } from "../../../validations/website/forgotPasswordValidationSchema";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { ForgotPasswordApi } from "../../../services/website/authService";
import { useNavigate } from "react-router-dom";

interface RegisterFormValues {
  email: string;
}
function ForgotPassword() {
  const initialValues: RegisterFormValues = {
    email: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values: RegisterFormValues) => {
    dispatch(showLoader());
    const params = {
      mail: values?.email,
      type: 2,
    };
    try {
      const response = await ForgotPasswordApi(params);
      if (response?.data?.code === 202) {
        navigate(pathRoute.auth.login);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="xl:px-[105px] lg:px-[60px] md:px-10 px-3 xxl:pt-15 lg:py-10 py-[30px]">
      <div className="text-center xl:mb-[60px] lg:mb-[40px] mb-[30px]">
        <img
          src={Logo}
          alt="logo"
          width={180}
          height={74}
          className="text-center inline-block"
        />
      </div>
      <Paragraph text24 className="mb-[10px] font-semibold">
        Forgot Password
      </Paragraph>
      <Paragraph text18 className="font-normal mb-6 !text-dark-grey">
        Enter your register email to retrieve your password.
      </Paragraph>
      <Formik
        initialValues={initialValues}
        validationSchema={forgetPasswordValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormLabel>
              Email address<span className="text-site-red">*</span>
            </FormLabel>
            <InputType
              placeholder="Enter email address"
              type="text"
              name="email"
              className=""
            />

            <Button
              primary
              type="submit"
              className="w-full mt-[30px]"
              disabled={isSubmitting}
            >
              Send
            </Button>
            <Paragraph
              text14
              className="text-center !font-normal mt-4 mb-[30px]"
            >
              Back to <TextLink to={pathRoute.auth.login}>Sign In</TextLink>
            </Paragraph>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ForgotPassword;
