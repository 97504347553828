import React from "react";
import Paragraph from "../core/typography/Paragraph";
import Dropdown from "../core/form-components/Dropdown";
import { IoEllipsisVertical } from "react-icons/io5";
import DummyProfile from "../../assets/images/dummy_logo.png";
import { SocialInterface } from "../../interface/social";
import TimeDifference from "./TimeDifference";
import ImageWithDefault from "./DefaultImage";
import { pathRoute } from "../../routes/pathRoute";
import { useNavigate } from "react-router-dom";

interface postInterface {
  toggleDropdown: (data?: string, item?: any) => void;
  item: SocialInterface;
  profileDropDownItems: any;
  handleActionsInweb?: (type: string, item?: any) => void;
}
const PostSideProfile = ({
  toggleDropdown,
  item,
  profileDropDownItems,
  handleActionsInweb,
}: postInterface) => {
  const navigate = useNavigate();

  const handleDetailPage = (id: number) => {
    navigate(`${pathRoute.website.userProfile}/${id}`);
  };

  return (
    <div className="flex absolute left-0 top-0 z-[1]">
      <div
        onClick={() => handleDetailPage(item?.user?.id ?? 0)}
        className="md:w-[60px] cursor-pointer md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden"
      >
        <ImageWithDefault
          src={item?.user?.avatar}
          alt="profile-photo"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="bg-white flex items-center md:gap-10 gap-4 ps-6 pe-4 md:py-3 py-2 rounded-se-[20px] rounded-ee-[20px] relative sm:-left-[15px] -left-[20px] -z-[1]">
        <div
          onClick={() => handleDetailPage(item?.user?.id ?? 0)}
          className="cursor-pointer"
        >
          <Paragraph text18 className="!leading-4">
            @{item?.user?.nickname}
          </Paragraph>
          <Paragraph
            text14
            className="!leading-[17px] !font-normal !text-dark-grey"
          >
            <TimeDifference date={item?.createdAt} />
          </Paragraph>
        </div>
        <Dropdown
          onClick={() => toggleDropdown("userProfile", item)}
          dropdownItems={profileDropDownItems}
          dropdownWidth="w-[174px]"
          handleActionsInweb={
            handleActionsInweb
              ? (e: any) => handleActionsInweb(e, item)
              : undefined
          }
        >
          <IoEllipsisVertical size={20} />
        </Dropdown>
      </div>
    </div>
  );
};

export default PostSideProfile;
