import React, { useEffect, useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/core/form-components/Button";
import { LuListFilter } from "react-icons/lu";
import OffCanvas from "../../../components/core/OffCanvas";
import SearchFilter from "../social/SearchFilter";
import Art1 from "../../../assets/images/popular-week-art1.png";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../redux/slices/siteLoaderSlice";
import {
  deletePiecesList,
  getPiecesList,
} from "../../../services/website/piecesService";
import InfiniteScroll from "react-infinite-scroll-component";
import { PiecesInterface } from "../../../interface/Pieces";
import NoDataFound from "../../../assets/images/empty_repost.svg";
import { IoMdPlay } from "react-icons/io";
import DummyProfile from "../../../assets/images/dummy_logo.png";
import { pathRoute } from "../../../routes/pathRoute";
import { userSelector } from "../../../redux/slices/userSlice";
import { BsThreeDotsVertical } from "react-icons/bs"; // Three dots icon from react-icons
import SearchInput from "../../../components/core/form-components/SearchInput";

function Piece() {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [currentResponsePage, setCurrentResponsePage] = useState(1);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [piecesData, setPiecesData] = useState<PiecesInterface[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const webProfileData = useSelector(userSelector);
  const isLoading = useSelector(siteLoaderSelector); // Select the loading state from Redux
  const [searchItem, setSearchItem] = useState("");

  const fetchPieces = async (page: number) => {
    const queryString = `?page=${page}&limit=${itemsPerPage}&criteria=${searchItem}&filter=0&category=&tag=&framed=0&mainColor=&secondaryColor=&priceMin=1`;
    dispatch(showLoader());
    setLoading(true);

    try {
      const response = await getPiecesList(queryString);
      const count = response?.data?.total || 0;
      if (response?.data?.code === 200) {
        setPiecesData((prevData) => [...prevData, ...response?.data?.data]);
        setTotalItems(count);
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
      setLoading(false);
    }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      fetchPieces(currentResponsePage);
    }, 300);

    return () => {
      clearTimeout(debounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItem, currentResponsePage]);

  const renderMedia = (item: PiecesInterface) => {
    if (!item.video && item.images?.length === 0) {
      return (
        <div className="rounded-[20px] overflow-hidden mb-3">
          <img
            src={DummyProfile}
            alt="dummy-image"
            className="aspect-square w-full"
            loading="lazy"
          />
        </div>
      );
    } else if (item.video) {
      return (
        <div className="block rounded-[20px] overflow-hidden relative w-auto">
          <img
            id={`image-${item.id}`}
            src={item.video.imageUrl}
            alt="Video thumbnail"
            className="h-[242px] rounded-[20px] bg-light-grey w-full"
            loading="lazy"
          />
          <button
            aria-label="Play video"
            className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center bg-gradient-to-r from-site-black to-site-black/0 backdrop-blur-md"
          >
            <IoMdPlay color="#fff" size={22} />
          </button>
        </div>
      );
    } else if (item.images) {
      return (
        <div className="rounded-[20px] overflow-hidden mb-3">
          <img
            src={item.images[0]?.imageUrl}
            alt="piece-image"
            className="aspect-square w-full"
            loading="lazy"
          />
        </div>
      );
    }
  };

  const handleDetailPage = (id?: number) => {
    navigate(`${pathRoute.website.artDetail}/${id}`, {
      state: { isWebsite: true, userId: webProfileData?.id },
    });
  };

  console.log("searchItem", searchItem);
  return (
    <div className="xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3">
      <div className="flex items-center justify-between gap-3 mb-5">
        <Paragraph text24 className="font-semibold ">
          Pieces
        </Paragraph>
        <div className="flex items-center gap-3 text-site-black ">
          <SearchInput
            setSearchItem={setSearchItem} // React.Dispatch<React.SetStateAction<string>>
            searchItem={searchItem} // string
            placeholder="Search" // string
            className="sm:w-auto w-full" // string
            iconRight={true} // boolean
            sm={true} // boolean
          />
          <span className="text-base leading-[20px]">Filter by</span>{" "}
          <Button onlyIcon primary onClick={() => setIsOffcanvasOpen(true)}>
            <LuListFilter size={24} />
          </Button>
        </div>
      </div>
      <OffCanvas
        isOpen={isOffcanvasOpen}
        onClose={() => setIsOffcanvasOpen(false)}
        position="right"
        size="400px"
        backdrop
        offCanvasTitle="Filter"
      >
        <SearchFilter />
      </OffCanvas>
      {piecesData?.length === 0 && !isLoading ? (
        <div className="break-inside-avoid text-center">
          <img
            src={NoDataFound}
            alt="no-data-found"
            className="mb-6 inline-block "
            width={"250px"}
            height={"142px"}
          />
          <Paragraph text18 className="!font-medium">
            No result found
          </Paragraph>
        </div>
      ) : (
        <div
          id="scrollableDiv"
          style={{ overflowY: "auto", maxHeight: "80vh", height: "100%" }}
        >
          <InfiniteScroll
            dataLength={piecesData?.length}
            next={() => setCurrentResponsePage((prevPage) => prevPage + 1)}
            hasMore={piecesData?.length < totalItems}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            <div className="grid xxl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 md:gap-[18px] gap-4">
              {piecesData?.map((item: PiecesInterface) => {
                return (
                  <div
                    key={item.id}
                    className="block col-span-1 cursor-pointer"
                    onClick={() => handleDetailPage(item?.id)}
                  >
                    <div className="relative">{renderMedia(item)}</div>
                    <Paragraph text20 className="font-medium mb-1">
                      {item?.title}
                    </Paragraph>
                    <Paragraph text18 className="!font-normal">
                      {item?.price}€
                    </Paragraph>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}

export default Piece;
