import React, { useEffect, useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import IconDollar from "../../../assets/images/icon_dollar.svg";
import IconUserDelete from "../../../assets/images/icon_userdelete.svg";
import IconMute from "../../../assets/images/icon_mute.svg";
import IconHeart from "../../../assets/images/icon_heart.svg";
import IconComment from "../../../assets/images/icon_comment.svg";
import IconFollowing from "../../../assets/images/icon_following.svg";
import IconPost from "../../../assets/images/icon_post.svg";
import IconLikeComment from "../../../assets/images/icon_like_comment.svg";
import IconRequest from "../../../assets/images/icon_request.svg";
import IconEvent from "../../../assets/images/icon_event.svg";
import IconBlackLive from "../../../assets/images/icon_black_like.svg";
import { IoCloseCircle, IoEllipsisVertical } from "react-icons/io5";
import Dropdown from "../../../components/core/form-components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  deactivateNotification,
  deleteNotification,
  getNotifications,
  muteNotification,
} from "../../../services/website/notificationService";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../redux/slices/siteLoaderSlice";
import NoDataFound from "../../../assets/images/empty_repost.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { NotificationInterface } from "../../../interface/Notification";
import TimeDifference from "../../../components/web-component/TimeDifference";
import { notificationType } from "../../../constant/constant";
import { notificationPrefSelector } from "../../../redux/slices/website/notificationSlice";

function Notifications() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentResponsePage, setCurrentResponsePage] = useState(1);
  const [notificationList, setNotificationList] = useState<
    NotificationInterface[]
  >([]);
  const notificationListData = useSelector(notificationPrefSelector);

  const [totalItems, setTotalItems] = useState(0);
  const dispatch = useDispatch();
  const isLoading = useSelector(siteLoaderSelector); // Select the loading state from Redux

  const profileDropDownItems = [
    {
      icon: <IoCloseCircle size={15} />,
      Link: "",
      LinkName: "Delete Notification",
      handleAction: true,
    },
    {
      icon: (
        <img
          src={IconUserDelete}
          alt="icon-user-delete"
          width={14}
          height={14}
        />
      ),
      Link: "",
      LinkName: "Deactivate account",
      handleAction: true,
    },
    {
      icon: <img src={IconMute} alt="icon-mute" width={12} height={12} />,
      Link: "",
      LinkName: "Mute",
      handleAction: true,
    },
  ];
  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };

  const fetchNotifications = async (page: number) => {
    const queryString = `?limit=10&page=${page}`;
    dispatch(showLoader());

    try {
      const response = await getNotifications(queryString);
      const count = response?.data?.total || 0;
      if (response?.data?.code === 200) {
        setNotificationList((prevData) => [
          ...prevData,
          ...response?.data?.myNotifications,
        ]);
        setTotalItems(count);
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchNotifications(currentResponsePage);
  }, [currentResponsePage]);

  const getTextName = (type: string) => {
    console.log("fdsfdsfsdf", type, notificationType.Comment);

    switch (type) {
      case notificationType.Like:
        return "liked your post";
      case notificationType.Create:
        return "liked your post";
      case notificationType.Comment:
        return "commented on your post";
      case notificationType.Follow:
        return "started following you";
      case notificationType.Purshase:
        return "just purchased your project";
      case notificationType.Favorite:
        return "liked your artwork";
      case notificationType.Response:
        return "replied to your comment";
      case notificationType.LikeComment:
        return "liked your comment";
      case notificationType.LikeResponse:
        return "liked your response";
      case notificationType.Acceptance:
        return "accepted to collaborate with you";
      case notificationType.Request:
        return "requested to collaborate with you";
      case notificationType.Event:
        return "";
      case notificationType.MediaLikeComment:
        return "liked your comment";
      case notificationType.MediaLikeResponse:
        return "liked your response";
      case notificationType.MediaResponse:
        return "replied to your comment";

      default:
        console.log("Unknown action type:", type);
        return null; // Explicit return for unknown types
    }
  };

  const getIcon = (type: string) => {
    switch (type) {
      case notificationType.Like:
        return IconHeart;
      case notificationType.Comment:
        return IconComment;
      case notificationType.Follow:
        return IconFollowing;
      case notificationType.Purshase:
        return IconDollar;
      case notificationType.Favorite:
        return IconHeart;
      case notificationType.Create:
        return IconBlackLive;
      case notificationType.LikeComment:
        return IconComment;
      case notificationType.Response:
        return IconBlackLive;
      case notificationType.LikeResponse:
        return IconBlackLive;
      case notificationType.Acceptance:
        return IconBlackLive;
      case notificationType.Request:
        return IconBlackLive;
      case notificationType.Event:
        return IconEvent;

      default:
        console.log("Unknown action type:", type);
        return "";
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      event.currentTarget.scrollHeight ===
      event.currentTarget.scrollTop + event.currentTarget.clientHeight;
    if (bottom && !isLoading && notificationList.length < totalItems) {
      setCurrentResponsePage((prevPage) => prevPage + 1); // Load the next page
    }
  };

  const handleDelete = async (id: any) => {
    dispatch(showLoader());
    try {
      const response = await deleteNotification(id);
      console.log("dfdsfsdfsdfs", response);
      if (response?.data?.code == 204) {
        setNotificationList((prevData) =>
          prevData?.filter((item) => item.id !== id)
        );
        setTotalItems((prevTotal) => prevTotal - 1);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };
  const handleDeactivate = async (id: any) => {
    dispatch(showLoader());
    try {
      const response = await deactivateNotification(id);
      if (response?.data?.code === 201) {
        setNotificationList((prevData) => {
          const filteredData = prevData?.filter(
            (item) => item.author?.id !== id
          );
          setTotalItems(
            (prevTotal) => prevTotal - (prevData.length - filteredData.length)
          );
          return filteredData;
        });
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  console.log("notificationListData", notificationListData);

  const handleMute = async (item: any) => {
    dispatch(showLoader());
    let type = item?.type.toString();
    let commentsNotifications =
      type === notificationType.Comment
        ? false
        : notificationListData?.commentsNotifications;
    let likesNotifications =
      type === notificationType.Like
        ? false
        : notificationListData?.likesNotifications;
    let followsNotifications =
      type === notificationType.Follow
        ? false
        : notificationListData?.followsNotifications;
    let purchasesNotifications =
      type === notificationType.Purshase
        ? false
        : notificationListData?.purchasesNotifications;
    let publicationsNotifications =
      type === notificationType.Create
        ? false
        : notificationListData?.publicationsNotifications;

    let masterClassNotifications =
      type === notificationType.Create
        ? false
        : notificationListData?.masterClassNotifications;
    let params = {
      publicationsNotifications: publicationsNotifications,
      commentsNotifications: commentsNotifications,
      likesNotifications: likesNotifications,
      followsNotifications: followsNotifications,
      purchasesNotifications: purchasesNotifications,
      masterClassNotifications: masterClassNotifications,
    };
    try {
      const response = await muteNotification(params);
      console.log("fsdfsfdsf", response);
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleActionsInweb = (type: string, item: any) => {
    switch (type) {
      case "Delete Notification":
        handleDelete(item?.id);
        break;
      case "Deactivate account":
        handleDeactivate(item?.author?.id);
        break;
      case "Mute":
        handleMute(item);
        break;
      default:
        console.log("Unknown action type:", type);
        break;
    }
  };

  console.log("notificationList", notificationList);
  return (
    <div className="xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3">
      <Paragraph text24 className="font-semibold mb-6">
        Notifications
      </Paragraph>
      <div className="grid-cols-12 grid gap-6">
        <div className="xl:col-span-8 col-span-12">
          <div className="border border-medium-grey rounded-2xl lg:p-30 md:p-6 sm:p-5 p-4">
            {notificationList?.length === 0 && !isLoading ? (
              <div className="break-inside-avoid text-center">
                <img
                  src={NoDataFound}
                  alt="no-data-found"
                  className="mb-6 inline-block"
                  width={"250px"}
                  height={"142px"}
                />
                <Paragraph text18 className="!font-medium">
                  No result found
                </Paragraph>
              </div>
            ) : (
              <div
                onScroll={handleScroll}
                style={{ overflowY: "auto", maxHeight: "calc(100vh - 249px)" }}
              >
                <ul className="flex flex-col gap-3">
                  {notificationList?.map((item: NotificationInterface) => {
                    return (
                      <li className="border border-medium-grey rounded-xl p-3 flex items-center justify-between gap-3">
                        <div className="inline-flex items-center gap-[14px]">
                          <span
                            className={`w-2 h-2 ${
                              item?.seenAt ? "bg-gray-500" : "bg-green-500"
                            }  rounded-full flex-shrink-0`}
                            aria-label="status"
                          ></span>
                          <span className="inline-flex items-center justify-center flex-shrink-0 ">
                            <img
                              src={getIcon(item?.type.toString() || "")}
                              alt="icon-dollar"
                              width={30}
                              height={30}
                            />
                          </span>
                          <Paragraph
                            text16
                            className="!text-dark-grey !leading-5"
                          >
                            <span className="!text-site-black">
                              {item?.author?.nickname}
                            </span>{" "}
                            {getTextName(item?.type.toString())}
                            <span className="block text-sm leading-[13px] mt-1">
                              <TimeDifference date={item?.createdAt} />
                            </span>
                          </Paragraph>
                        </div>
                        <Dropdown
                          onClick={() => toggleDropdown("userProfile")}
                          dropdownItems={profileDropDownItems}
                          dropdownWidth="w-[174px]"
                          handleActionsInweb={
                            handleActionsInweb
                              ? (e: any) => handleActionsInweb(e, item)
                              : undefined
                          }
                        >
                          <IoEllipsisVertical size={20} />
                        </Dropdown>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
