import React, { useEffect, useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../redux/slices/siteLoaderSlice";
import { PeopleInterface } from "../../../interface/People";
import { getPeopleList } from "../../../services/website/peopleService";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { pathRoute } from "../../../routes/pathRoute";
import ImageWithDefault from "../../../components/web-component/DefaultImage";
import NoDataFound from "../../../assets/images/empty_repost.svg";

function People() {
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [currentResponsePage, setCurrentResponsePage] = useState(1);
  const [peopleData, setPeopleData] = useState<PeopleInterface[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const isLoading = useSelector(siteLoaderSelector); // Select the loading state from Redux

  const fetchPieces = async (page: number) => {
    const queryString = `?page=${page}&limit=${itemsPerPage}&criteria=&filter=1&category=&tag=&framed=0&mainColor=&secondaryColor=&priceMin=1`;
    dispatch(showLoader());

    try {
      const response = await getPeopleList(queryString);
      const count = response?.data?.total || 0;
      if (response?.data?.code === 200) {
        setPeopleData((prevData) => [...prevData, ...response?.data?.data]);
        setTotalItems(count);
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchPieces(currentResponsePage);
  }, [currentResponsePage]);

  const handleDetailPage = (id: number) => {
    navigate(`${pathRoute.website.userProfile}/${id}`);
  };
  return (
    <div className="xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3">
      <div className="flex items-center justify-between gap-3 mb-5">
        <Paragraph text24 className="font-semibold ">
          People
        </Paragraph>
      </div>
      {peopleData?.length === 0 && !isLoading ? (
        <div className="break-inside-avoid text-center">
          <img
            src={NoDataFound}
            alt="no-data-found"
            className="mb-6 inline-block"
            width={"250px"}
            height={"142px"}
          />
          <Paragraph text18 className="!font-medium">
            No result found
          </Paragraph>
        </div>
      ) : (
        <div
          id="scrollableDiv"
          style={{ overflowY: "auto", maxHeight: "80vh", height: "100%" }}
        >
          <InfiniteScroll
            dataLength={peopleData?.length}
            next={() => setCurrentResponsePage((prevPage) => prevPage + 1)}
            hasMore={peopleData?.length < totalItems}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            <div className="grid xxl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 md:gap-[18px] gap-4">
              {peopleData?.map((item: PeopleInterface) => {
                return (
                  <div
                    className="col-span-1 rounded-xl overflow-hidden cursor-pointer"
                    onClick={() => handleDetailPage(item?.id || 0)}
                  >
                    <div className="relative">
                      <ImageWithDefault
                        src={item?.avatar}
                        alt="popular-art"
                        width={152}
                        height={144}
                        className="w-full h-full object-cover"
                      />

                      <div className="absolute w-full left-0 bottom-0 flex justify-center items-center gap-[6px] px-[10px] py-[6px] bg-gradient-to-r from-red -from-[4.18%] backdrop-blur-[19px] to-transparent to-[104.23%]">
                        <Paragraph
                          text14
                          className="!text-black !font-normal text-center"
                        >
                          {item?.nickname}
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}

export default People;
