import React, { useEffect, useState } from "react";
import DummyProfile from "../../../assets/images/dummy_profile.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaPlayCircle } from "react-icons/fa";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../components/core/Breadcrumb";
import { tokenSelector } from "../../../redux/slices/userSlice";
import { ArtDetails } from "../../../interface/ArtDetails";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { getArtRoomDetailsById } from "../../../services/profileService";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";
import { pathRoute } from "../../../routes/pathRoute";
import { getWebsiteArtRoomDetails } from "../../../services/website/profileService";
import { deletePiecesList } from "../../../services/website/piecesService";
import Dropdown from "../../../components/core/form-components/Dropdown";
import { IoEllipsisVertical, IoQrCode } from "react-icons/io5";
import { BiSolidPencil } from "react-icons/bi";
import IconCopy from "../../../assets/images/icon-copy.svg";
import IconReport from "../../../assets/images/icon-info.svg";
import IconHide from "../../../assets/images/icon-hide.svg";
import IconDelete from "../../../assets/images/icon-delete.svg";
import Modal from "../../../components/core/Modal";
import ReportProjectContent from "../../../components/core/ReportProjectContent";
import {
  blockUser,
  followUsers,
  reportUser,
  unfollowUsers,
} from "../../../services/website/peopleService";
import { HiUserAdd } from "react-icons/hi";
import { MdBlock } from "react-icons/md";
import ImageWithDefault from "../../../components/web-component/DefaultImage";

function ArtRoomDetail() {
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const profileUrlToken = `${pathRoute.web.profile}?token=${token}`;
  const artUrlToken = `${pathRoute.web.artDetail}/${id}?token=${token}`;
  const [isOpen, setIsOpen] = useState(false);
  const [reportPopup, setReportPopup] = useState(false);
  const [reportId, setReportId] = useState<number | null>(null);
  const [artDetails, setArtDetails] = useState<ArtDetails | undefined>(
    undefined
  );
  const isWebsite = location?.state?.isWebsite;
  const userId = location?.state?.userId;
  console.log("artDetails", artDetails?.owner?.id, userId);

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  const fetchArtRoomDetails = async (id: string) => {
    if (id) {
      dispatch(showLoader());
      try {
        const response = await getArtRoomDetailsById(parseInt(id));
        if (response?.data?.code === 200) {
          setArtDetails(response?.data?.art);
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    }
  };

  const fetchWebArtRoomDetails = async (id: string) => {
    if (id) {
      dispatch(showLoader());
      try {
        const response = await getWebsiteArtRoomDetails(parseInt(id));
        if (response?.data?.code === 200) {
          setArtDetails(response?.data?.art);
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    }
  };

  useEffect(() => {
    if (id && isWebsite) {
      fetchWebArtRoomDetails(id || "");
    } else {
      fetchArtRoomDetails(id || "");
    }
  }, [id]);

  const openLightbox = (videoUrl: string) => {
    console.log("Opening lightbox with video URL:", videoUrl);
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const handleBuyNowClick = () => {
    if (isWebsite) {
      const urlWithToken = `${pathRoute.website.checkout}/${id}`;
      navigate(urlWithToken, { state: { isWebsite: true } });
    } else {
      const urlWithToken = `${pathRoute.web.checkout}/${id}?token=${token}`;
      navigate(urlWithToken, { state: { isWebsite: false } });
    }
  };

  const breadcrumbPages = [
    { name: "Profile", path: profileUrlToken },
    { name: artDetails?.title || "", path: artUrlToken },
  ];

  const handleEdit = async (id: number) => {
    if (token) {
      const editPieces = `${pathRoute.website.editPost}/${id}`;
      navigate(editPieces);
    } else {
      navigate(pathRoute.auth.login);
    }
  };

  const handleDelete = async (id: number) => {
    dispatch(showLoader());

    try {
      if (token) {
        const response = await deletePiecesList(id);
        if (response?.data?.code === 201) {
          navigate(-1);
        }
      } else {
        navigate(pathRoute.auth.login);
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const renderMedia = (item: any) => {
    if (!item?.video && item?.images?.length === 0) {
      return (
        <div className="rounded-[20px] overflow-hidden mb-3">
          <img
            src={DummyProfile}
            alt="dummy-image"
            className="aspect-square w-full"
            loading="lazy"
          />
        </div>
      );
    } else if (item?.video) {
      return (
        <div className="relative">
          <img
            src={item.video?.imageUrl}
            className="max-h-[390px] w-full rounded-[20px] bg-light-grey cursor-default"
            alt="video-thumbnail"
          />
          <span className="absolute inset-0 flex items-center justify-center ">
            <FaPlayCircle
              size={40}
              className="text-base cursor-pointer"
              onClick={() => openLightbox(item?.video?.videoUrl)}
            />
          </span>
        </div>
      );
    } else if (item?.images) {
      return (
        <img
          src={item?.images && item?.images[0]?.imageUrl}
          alt="artt-imgg"
          className="inline-block max-h-[360px] "
        />
      );
    }
  };

  const profileDropDownItems = [
    {
      icon: <BiSolidPencil />,
      Link: "",
      LinkName: "Edit",
      handleAction: true,
    },
    {
      icon: <img src={IconDelete} alt="icon-delete" width={12} height={12} />,
      Link: "",
      LinkName: "Delete",
      handleAction: true,
    },
  ];

  const userDropDownItems = (isFollowedByMe: boolean) => [
    {
      icon: <HiUserAdd size={16} />,
      Link: "",
      LinkName: isFollowedByMe ? "Following" : "Follow", // Conditional text
      handleAction: true,
    },
    {
      icon: (
        <img src={IconReport} alt="icon-user-delete" width={12} height={12} />
      ),
      Link: "",
      LinkName: "Report",
      handleAction: true,
    },
    {
      icon: <MdBlock />,
      Link: "",
      LinkName: "Block",
      handleAction: true,
    },
  ];

  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };

  const handleFollowUser = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await followUsers(item?.owner?.id, {});
      if (response?.data?.code === 200) {
        fetchWebArtRoomDetails(id || "");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleUnFollowUser = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await unfollowUsers(item?.owner?.id);
      if (response?.data?.code === 200) {
        fetchWebArtRoomDetails(id || "");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleBlock = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await blockUser(item?.owner?.id);
      if (response?.data?.code === 201) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleActionsInweb = (type: string, item: any) => {
    switch (type) {
      case "Delete":
        handleDelete(item?.publicationId || 0);
        break;
      case "Edit":
        handleEdit(item?.id || 0);
        break;
      case "Report":
        setReportPopup(!reportPopup);
        break;
      case "Block":
        handleBlock(item);
        break;
      case "Follow":
        handleFollowUser(item);
        break;
      case "Following":
        handleUnFollowUser(item);
        break;
      default:
        console.log("Unknown action type:", type);
        break;
    }
  };

  const closeReportPopup = () => {
    setReportPopup(false);
  };

  const handleReportId = (id: number) => {
    setReportId(id);
  };

  const handleReportApi = async () => {
    dispatch(showLoader());
    try {
      let data = {
        flagId: reportId,
      };
      const response = await reportUser(data, artDetails?.publicationId);
      if (response?.data?.code === 201) {
        setReportPopup(false);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };
  console.log("Gdfgdfgdf", artDetails);
  return (
    <>
      <section>
        <div
          className={`${
            isWebsite ? "py-4 xl:px-10 lg:px-6 md:px-4 px-3" : "container"
          } `}
        >
          {!isWebsite && (
            <div className="pt-4">
              <Breadcrumb pages={breadcrumbPages} />
            </div>
          )}

          <div className={`${isWebsite ? "pt-6" : "md:py-[50px] py-[30px]"} `}>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
              <div className="col-span-1">
                <div className="sticky top-4">
                  <div className="bg-light-grey w-full rounded-[20px] flex items-center justify-center  mb-3">
                    {renderMedia(artDetails)}
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                {isWebsite ? (
                  <div className="flex items-center justify-between gap-2 mb-3">
                    <Paragraph
                      text24
                      className="!font-semibold text-site-black"
                    >
                      {artDetails?.title}
                    </Paragraph>
                    <Dropdown
                      onClick={() => toggleDropdown("userProfile")}
                      dropdownItems={
                        artDetails?.owner?.id === userId
                          ? profileDropDownItems
                          : userDropDownItems(
                              artDetails?.owner?.followedByMe || false
                            )
                      }
                      dropdownWidth="w-[174px]"
                      handleActionsInweb={
                        handleActionsInweb
                          ? (e: any) => handleActionsInweb(e, artDetails)
                          : undefined
                      }
                    >
                      <IoEllipsisVertical size={24} />
                    </Dropdown>
                    {/* <IoEllipsisVertical
                      size={24}
                      onClick={() => setReportPopup(!reportPopup)}
                    /> */}
                    <Modal
                      open={reportPopup}
                      header
                      onClose={closeReportPopup}
                      width="sm:w-[448px] w-[300px]"
                      modalPadding="p-6"
                    >
                      <ReportProjectContent
                        onSelectionChange={handleReportId}
                      />
                      <div className="flex items-center justify-center md:gap-4 gap-3">
                        <Button
                          borderButton
                          className="w-full"
                          onClick={() => setReportPopup(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          primary
                          onClick={handleReportApi}
                          className="w-full"
                        >
                          Send
                        </Button>
                      </div>
                    </Modal>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex items-center gap-2 mb-3">
                  <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                    {/* <img
                      src={getProfileImage(artDetails?.owner?.avatar ?? "")}
                      alt="profile-photo"
                      className="w-full h-full object-cover"
                    /> */}
                    <ImageWithDefault
                      src={artDetails?.owner?.avatar ?? ""}
                      alt="profile-photo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <Paragraph text16 className="font-medium">
                    <span className="text-site-dark-black/40 ">by</span>{" "}
                    {artDetails?.owner?.nickname}
                  </Paragraph>
                </div>
                {artDetails?.owner?.description && (
                  <Paragraph text18 className="mb-4 font-normal">
                    {artDetails?.owner?.description}
                  </Paragraph>
                )}
                <Paragraph text18 className="mb-2 font-normal">
                  <span className="font-medium">{t("profile.art")} | </span>
                  {artDetails?.width}*{artDetails?.height}cm
                </Paragraph>
                <Paragraph text18 className="mb-4 font-normal">
                  <span className="font-medium">
                    {t("profile.available_piece")} |{" "}
                  </span>
                  {artDetails?.quantity} {t("profile.copies_left")}.
                </Paragraph>
                <Paragraph text24 className="!font-semibold md:mb-7 mb-5">
                  €{artDetails?.price}
                </Paragraph>
                {isWebsite ? (
                  artDetails?.owner?.id === userId ? (
                    ""
                  ) : (
                    <Button
                      primary={artDetails?.quantity === 0 ? false : true}
                      className="w-full md:mb-[30px] mb-5"
                      onClick={handleBuyNowClick}
                      disabled={artDetails?.quantity === 0}
                    >
                      {artDetails?.quantity === 0
                        ? t("profile.out_of_Stock")
                        : t("profile.buy_now")}
                    </Button>
                  )
                ) : (
                  <Button
                    primary={artDetails?.quantity === 0 ? false : true}
                    className="w-full md:mb-[30px] mb-5"
                    onClick={handleBuyNowClick}
                    disabled={artDetails?.quantity === 0}
                  >
                    {artDetails?.quantity === 0
                      ? t("profile.out_of_Stock")
                      : t("profile.buy_now")}
                  </Button>
                )}

                {artDetails?.description && !isWebsite && (
                  <>
                    <Paragraph text20 className="font-medium mb-3">
                      {t("profile.description")}
                    </Paragraph>
                    <Paragraph
                      text18
                      className="font-normal !text-dark-grey mb-4"
                    >
                      {artDetails?.description}
                    </Paragraph>
                  </>
                )}
                <ul className="flex items-center lg:gap-[65px] md:gap-7 sm:gap-5 gap-3">
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.weight")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.weight} kg
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.width")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.width} cm
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.height")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.height} cm
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.depth")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.depth} cm
                    </Paragraph>
                  </li>
                </ul>
                {isWebsite && (
                  <>
                    <div className="border-t border-medium-grey mt-4 pt-4">
                      <ul>
                        <li className="flex items-center justify-between gap-3 pb-4">
                          <Paragraph text18 className="!leading-4 !font-normal">
                            Category
                          </Paragraph>
                          <Paragraph
                            text18
                            className="!leading-4 !font-normal !text-dark-grey"
                          >
                            {artDetails?.category?.name}
                          </Paragraph>
                        </li>
                        <li className="flex items-center justify-between gap-3 pb-4">
                          <Paragraph text18 className="!leading-4 !font-normal">
                            Tags
                          </Paragraph>
                          <Paragraph
                            text18
                            className="!leading-4 !font-normal !text-dark-grey"
                          >
                            {artDetails?.creator?.name || "No tag available"}
                          </Paragraph>
                        </li>
                        <li className="flex items-center justify-between gap-3 pb-4">
                          <Paragraph text18 className="!leading-4 !font-normal">
                            Type of post
                          </Paragraph>
                          <Paragraph
                            text18
                            className="!leading-4 !font-normal !text-dark-grey"
                          >
                            All
                          </Paragraph>
                        </li>
                        <li className="flex items-center justify-between gap-3 pb-4">
                          <Paragraph text18 className="!leading-4 !font-normal">
                            Colourimetry
                          </Paragraph>
                          <div className="flex items-center gap-2">
                            <span
                              className="w-6 h-6 rounded-full inline-block"
                              style={{
                                backgroundColor:
                                  artDetails?.secondaryColor?.hexcode ||
                                  "transparent",
                              }}
                            ></span>
                            <span
                              className="w-6 h-6 rounded-full inline-block"
                              style={{
                                backgroundColor:
                                  artDetails?.mainColor?.hexcode ||
                                  "transparent",
                              }}
                            ></span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="border-t border-medium-grey pt-4">
                      <Paragraph text20 className="font-medium mb-3">
                        {t("profile.description")}
                      </Paragraph>
                      <Paragraph text18 className="font-normal !text-dark-grey">
                        {artDetails?.description}
                      </Paragraph>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {lightboxOpen && (
        <div>
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
    </>
  );
}

export default ArtRoomDetail;
