import React, { useEffect, useState } from "react";
import SlidingTabBar from "../../../components/core/SlidingTabBar";
import { Tab } from "../../../interface/Tabs";
import ForYou from "./ForYou";
import Discover from "./Discover";
import { tokenSelector } from "../../../redux/slices/userSlice";
import { useSelector } from "react-redux";

function Social() {
  const token = useSelector(tokenSelector);

  const tabs: Tab[] = [
    { id: "art_room", name: "For you", content: <ForYou /> },
    { id: "feed", name: "Discover", content: <Discover /> },
  ];

  return (
    <div className="grid grid-cols-12 h-full">
      <div className="col-span-12">
        {token ? (
          <SlidingTabBar tabs={tabs} borderedTab></SlidingTabBar>
        ) : (
          <Discover />
        )}
      </div>
    </div>
  );
}

export default Social;
