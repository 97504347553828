import React, { useEffect, useState } from "react";
import Paragraph from "../../../../components/core/typography/Paragraph";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import { useDispatch, useSelector } from "react-redux";
import MasterClassDummy1 from "../../../../assets/images/master_class_dummy_1.png";
import { IoMdPlay } from "react-icons/io";
import { GoClockFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../../redux/slices/siteLoaderSlice";
import { pathRoute } from "../../../../routes/pathRoute";
import { userSelector } from "../../../../redux/slices/userSlice";
import { getMasterClassSubscriptions } from "../../../../services/website/accountService";
import NoDataFound from "../../../../assets/images/empty_repost.svg";
import {
  MasterClassSubInterface,
  masterInterface,
} from "../../../../interface/MasterClassSub";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";

function MyMasterclass() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const dispatch = useDispatch();
  const [masterClassList, setMasterClassList] = useState<
    MasterClassSubInterface[]
  >([]);
  const isLoading = useSelector(siteLoaderSelector); // Select the loading state from Redux
  const navigate = useNavigate();
  const webProfileData = useSelector(userSelector);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);

  const openLightbox = (videoUrl: string) => {
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const fetchPurchaseList = async () => {
    dispatch(showLoader());
    try {
      const response = await getMasterClassSubscriptions(webProfileData?.id);
      if (response?.data?.code === 200) {
        setMasterClassList(response.data.planList);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchPurchaseList();
  }, []);

  const handleDetailPage = (item: any) => {
    const routes = `${pathRoute.website.masterClassDetail}/${item?.id}`;
    navigate(routes);
  };
  console.log("sdfsdfdsfsd", masterClassList);

  const formatDuration = (duration: any) => {
    const durationInSeconds = duration;

    const minutes = Math.floor(durationInSeconds / 60);
    const remainingSeconds = durationInSeconds % 60;

    return durationInSeconds > 60
      ? `${minutes} min ${remainingSeconds.toFixed(0)} sec`
      : durationInSeconds + "sec";
  };

  return (
    <div>
      <Paragraph text24 className="font-semibold mb-6 ">
        {LanguageTranslation({
          labelName: "my_masterclass",
          languageCode: langId || "fr",
        }) || "My Masterclass"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
        {masterClassList?.length === 0 && !isLoading ? (
          <div className="break-inside-avoid text-center">
            <img
              src={NoDataFound}
              alt="no-data-found"
              className="mb-6 inline-block"
              width={"250px"}
              height={"142px"}
            />
            <Paragraph text18 className="!font-medium">
              No result found
            </Paragraph>
          </div>
        ) : (
          <>
            <div className="flex items-start justify-between gap-3 mb-6">
              <div className="">
                <Paragraph text22 className="!font-medium mb-1">
                  {masterClassList?.[0]?.title}
                </Paragraph>
                <Paragraph text16 className="!font-normal">
                  {masterClassList?.[0]?.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: masterClassList?.[0]?.description || "",
                      }}
                    />
                  )}
                </Paragraph>
              </div>
              <Paragraph text24 className="!font-semibold">
                €{masterClassList?.[0]?.price}
              </Paragraph>
            </div>
            <ul className="max-h-[calc(100vh-296px)] overflow-y-auto">
              {masterClassList?.[0]?.masterclass_list?.map(
                (item: masterInterface) => {
                  return (
                    <li
                      onClick={() => handleDetailPage(item)}
                      className="border cursor-pointer border-medium-grey rounded-xl p-3 flex items-center gap-6 mb-3"
                    >
                      <div className="overflow-hidden rounded-[4px] relative before:absolute before:w-full before:h-full before:bg-site-black/30">
                        <img
                          src={item?.thumbnail}
                          alt="video-thumb"
                          width={60}
                          height={90}
                          className="aspect-[2/3]"
                        />
                        <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                          <IoMdPlay
                            color="#fff"
                            size={8}
                            onClick={() => openLightbox(item?.video)}
                          />
                        </span>
                      </div>
                      <div>
                        <Paragraph text20 className="!font-medium mb-2">
                          {item?.title}
                        </Paragraph>
                        <span className="flex items-center gap-1 text-base leading-[16px] text-dark-grey">
                          <GoClockFill color="#4D4B4B" size={18} />
                          Class: {formatDuration(item?.videoDuration)}
                        </span>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </>
        )}
      </div>
      {lightboxOpen && (
        <div
          className={
            masterClassList?.[0]?.masterclass_list?.length === 1
              ? "lightbox-no-navigation"
              : ""
          }
        >
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default MyMasterclass;
