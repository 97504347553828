import React from "react";

interface LanguageInterface {
  labelName: string;
  languageCode: string;
}

interface LanguageTranslation {
  languageName: string;
  Language: {
    languageCode: string;
  };
  labelValue: string;
}

interface LabelObject {
  id: number;
  labelName: string;
  LabelTranslations: LanguageTranslation[];
}

export function LanguageTranslation({
  labelName,
  languageCode,
}: LanguageInterface) {
  const storedDataRaw = localStorage.getItem("labels");
  const storedData: LabelObject[] | null = storedDataRaw
    ? JSON.parse(storedDataRaw)
    : null;

  console.log("dataas", storedData);

  // Ensure the `item` type matches the shape of your `LabelObject`
  const labelObject = storedData?.find(
    (item: LabelObject) => item.labelName === labelName
  );

  // Find the specific label value for the given languageCode
  const label = labelObject?.LabelTranslations?.find(
    (label: LanguageTranslation) =>
      label?.Language?.languageCode === languageCode
  );

  return label
    ? label.labelValue
    : labelObject?.LabelTranslations.find(
        (label: LanguageTranslation) => label?.Language?.languageCode === "fr"
      )?.labelValue || "";
}
