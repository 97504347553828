/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import Logo from "../../../assets/images/black_logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";
import { pathRoute } from "../../../routes/pathRoute";
import { ResendOtp, VerifyOtp } from "../../../services/website/authService";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../../../redux/slices/userSlice";
import { setSignUpDetails } from "../../../redux/slices/website/signUpOtpDetailsSlice";
import { setNotificationPref } from "../../../redux/slices/website/notificationSlice";

function OTP(): JSX.Element {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const [otp, setOtp] = useState<string[]>(Array(4).fill(""));
  const [errorMessage, setErrorMessage] = useState("");
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [countDown, setCountDown] = useState(30);

  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (countDown > 0) {
      const timerId = setInterval(() => {
        setCountDown((prevCount) => prevCount - 1);
      }, 1000);
      return () => clearInterval(timerId);
    } else {
      setShowResendOtp(true); // Show resend button when countdown reaches 0
    }
  }, [countDown]);

  useEffect(() => {
    if (!state?.otpData?.userId) {
      navigate(pathRoute?.auth?.login);
    }
  }, [state?.otpData?.userId, navigate]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    } else {
      e.target.value = "";
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      if (otp[index] === "") {
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  const handleResendCode = async () => {
    dispatch(showLoader());
    const params = {
      userId: state?.otpData?.userId,
      phoneNb: state?.otpData?.phoneNb,
      countryCode: state?.otpData?.countryCode,
      countryId: state?.countryId,
    };
    try {
      const response = await ResendOtp(params);
      if (response?.status === 201) {
        setShowResendOtp(false); // Hide resend button
        setCountDown(30); // Restart countdown
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const onSubmit = async () => {
    dispatch(showLoader());
    if (otp.join("").length === 4) {
      setErrorMessage("");
      const params = {
        type: state?.type,
        otp: otp.join(""),
        userId: state?.otpData?.userId,
      };
      try {
        const response = await VerifyOtp(params);
        if (response?.data?.code === 201 && state?.type === 2) {
          let notifiData = {
            commentsNotifications: response?.data?.data?.commentsNotifications,
            likesNotifications: response?.data?.data?.likesNotifications,
            purchasesNotifications:
              response?.data?.data?.purchasesNotifications,
            publicationsNotifications:
              response?.data?.data?.publicationsNotifications,
            masterClassNotifications:
              response?.data?.data?.masterClassNotifications,
            followsNotifications: response?.data?.data?.followsNotifications,
          };
          dispatch(setNotificationPref(notifiData));
          dispatch(setUser(response?.data?.data));
          dispatch(setToken(response?.data?.data?.accessToken));
          navigate(pathRoute?.auth?.social);
        } else if (response?.data?.code === 201 && state?.type === 1) {
          // for sign up
          dispatch(setSignUpDetails(response?.data?.data));
          navigate(`${pathRoute?.auth?.profileSetup}`, {
            state: { userId: response?.data?.data?.userId },
          });
          console.log("Dfdsf", response?.data?.data);
        } else {
          setOtp(Array(4).fill(""));
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setErrorMessage("Please enter OTP");
    }
    dispatch(hideLoader());
  };

  return (
    <div className="xl:px-[105px] lg:px-[60px] md:px-10 px-3 xxl:pt-15 lg:py-10 py-[30px]">
      <div className="text-center xl:mb-[60px] lg:mb-[40px] mb-[30px]">
        <img
          src={Logo}
          alt="logo"
          width={180}
          height={74}
          className="text-center inline-block"
        />
      </div>
      <Paragraph text24 className="mb-[10px] !font-semibold">
        OTP Verification
      </Paragraph>
      <Paragraph text18 className="font-normal mb-6 !text-dark-grey">
        Enter verification code we just sent on your mobile number.
      </Paragraph>
      <Formik initialValues={{ otp: "" }} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-12">
              <div className="col-span-12 mb-3">
                <div className="flex sm:gap-6 gap-4">
                  {Array(4)
                    .fill(0)
                    .map((_, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength={1}
                        className="text-site-black text-2xl leading-6 font-normal w-[48px] h-[48px] text-center px-3 py-4 rounded-lg border border-site-grey focus:outline-0 focus:border-primary-blue"
                        value={otp[index]}
                        ref={(el) => (inputRefs.current[index] = el)}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                      />
                    ))}
                </div>
                {errorMessage && (
                  <div className="text-site-red text-sm font-medium">
                    {errorMessage}
                  </div>
                )}
              </div>
              {countDown > 0 && (
                <div className="col-span-12">
                  <Paragraph text14 className="!font-normal">
                    Expiring in{" "}
                    <span className="text-site-red">
                      {Math.floor(countDown / 60)
                        .toString()
                        .padStart(2, "0")}
                      :{(countDown % 60).toString().padStart(2, "0")}
                    </span>
                  </Paragraph>
                </div>
              )}
              <div className="col-span-12 mb-3 mt-6">
                <Button
                  primary
                  className="w-full"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Verify
                </Button>
              </div>
              {showResendOtp && (
                <div className="col-span-12 text-center">
                  <Paragraph text14 className="!text-dark-grey !font-normal">
                    Didn't receive OTP?{" "}
                    <button
                      type="button"
                      className="text-site-black font-semibold hover:text-site-yellow transition-all duration-300"
                      onClick={handleResendCode}
                    >
                      Resend
                    </button>
                  </Paragraph>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default OTP;
