import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
} from "react";
import { GoChevronDown } from "react-icons/go";

interface AccordionProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> {
  value: string | null;
  onChange?: (value: string | null) => void;
}

interface AccordionContextType {
  selected: string | null;
  setSelected: React.Dispatch<React.SetStateAction<string | null>>;
}

const AccordionContext = createContext<AccordionContextType | undefined>(
  undefined
);

function Accordion({
  children,
  value,
  onChange,
  ...props
}: PropsWithChildren<AccordionProps>) {
  const [selected, setSelected] = useState<string | null>(value);

  useEffect(() => {
    onChange?.(selected);
  }, [selected, onChange]);

  return (
    <div {...props}>
      <AccordionContext.Provider value={{ selected, setSelected }}>
        {children}
      </AccordionContext.Provider>
    </div>
  );
}

export default Accordion;

interface AccordionItemProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
  trigger: React.ReactNode;
}

export function AccordionItem({
  children,
  value,
  trigger,
  ...props
}: PropsWithChildren<AccordionItemProps>) {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error("AccordionItem must be used within an Accordion");
  }

  const { selected, setSelected } = context;
  const open = selected === value;
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div className="mb-6" {...props}>
      <button
        onClick={() => setSelected(open ? null : value)}
        className="w-full flex items-center justify-between gap-3 px-4 py-3 border border-medium-grey rounded-lg text-base leading-[20px] font-medium "
        type="button"
      >
        {trigger}
        <GoChevronDown
          size={24}
          color="#808186"
          className={`transition-transform ${open ? "rotate-180" : ""}`}
        />
      </button>
      <div
        className="overflow-y-hidden transition-all"
        style={{ height: open ? ref.current?.offsetHeight || 0 : 0 }}
      >
        <div className="pt-4" ref={ref}>
          {children}
        </div>
      </div>
    </div>
  );
}
