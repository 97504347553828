import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { pathRoute } from "./pathRoute";
import { useSelector } from "react-redux";
import { tokenSelector } from "../redux/slices/userSlice";

interface GuestRouteProps {
  children: ReactNode; // Children to render if the user is a guest
}

const GuestRoute = ({ children }: GuestRouteProps) => {
  const location = useLocation();
  const token = useSelector(tokenSelector);
  console.log("guest", token);
  const isToken = token ? true : false;

  const renderDashboard = pathRoute.auth.social;

  if (isToken) {
    return <Navigate to={renderDashboard} state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default GuestRoute;
