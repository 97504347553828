import * as Yup from "yup";

export default [
  Yup.object({
    name: Yup.string()
      .trim()
      .max(50, "Maximum 50 characters allowed")
      .min(1, "Minimum 1 character required")
      .required("Name is required"),
    surname: Yup.string()
      .trim()
      .max(50, "Maximum 50 characters allowed")
      .min(1, "Minimum 1 character required")
      .required("Surname is required"),
    nickName: Yup.string()
      .trim()
      .max(50, "Maximum 50 characters allowed")
      .min(1, "Minimum 1 character required")
      .required("Please enter Pseudonym name"),
    email: Yup.string()
      .email("Email is invalid")
      .trim()
      .max(255, "Maximum 255 Characters allowed")
      .required("Email is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "Email is invalid"),
  }),
  Yup.object({}),
  Yup.object({
    address1: Yup.string().trim().required("Address1 is required"),
    city: Yup.string().trim().required("City is required"),
    zipcode: Yup.string().trim().required("Postcode is required"),
  }),
];
