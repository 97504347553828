import React, { useState } from "react";
import Breadcrumb from "../../../components/core/Breadcrumb";
import { pathRoute } from "../../../routes/pathRoute";
import { FaCircleCheck } from "react-icons/fa6";
import OpportunityDummy from "../../../assets/images/opportunitiy_dummy.png";
import HeadingOne from "../../../components/core/typography/HeadingOne";
import Paragraph from "../../../components/core/typography/Paragraph";
import IconUsers from "../../../assets/images/icon_users.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/core/form-components/Button";
import Modal from "../../../components/core/Modal";
import AppliedSuccessfull from "./AppliedSuccessfull";
import ImageWithDefault from "../../../components/web-component/DefaultImage";
import { OpportunityType } from "../../../constant/constant";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { applyOpportunity } from "../../../services/website/socialService";
import { tokenSelector } from "../../../redux/slices/userSlice";

function OpportunityDetail() {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const item = location?.state?.item;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);

  const closeModal = () => {
    setOpen(!open);
    navigate(pathRoute.website.opportunities);
  };

  const getLabel = (opportunity: any) => {
    if (opportunity?.type === OpportunityType.Exhibition)
      return "Call for tender";
    else return "Collaboration";
  };

  const handleApplyNow = async (id: number) => {
    dispatch(showLoader());
    try {
      if (token) {
        const response = await applyOpportunity(id);
        if (response?.data?.code === 201) {
          setOpen(true);
        }
      } else {
        navigate(pathRoute.auth.login);
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="grid grid-cols-12 lg:gap-6 md:gap-4 gap-3 py-4 xl:px-10 lg:px-6 md:px-4 px-3">
      <div className="xl:col-span-5 md:col-span-6 col-span-12">
        <ImageWithDefault
          src={item?.image}
          alt="opportunity"
          width={541}
          height={389}
          className="w-full"
        />
      </div>
      <div className="xxl:col-span-5 xl:col-span-7 md:col-span-6 col-span-12">
        <Paragraph text24 className="font-medium mb-3">
          {item?.title}
        </Paragraph>
        <Paragraph
          text18
          className="!leading-4 !text-dark-grey !font-normal flex items-center gap-1 mb-3"
        >
          <img src={IconUsers} alt="icon-users" className="w-6 h-6" />
          Candidates: {item?.numberOfApplicants}
        </Paragraph>
        <Paragraph
          text18
          className=" mb-3 !leading-5 font-semibold text-site-green"
        >
          {getLabel(item)}
        </Paragraph>

        {item?.description && (
          <div
            dangerouslySetInnerHTML={{
              __html: item?.description || "",
            }}
          />
        )}
        {item?.isApplied ? (
          <Button
            primary
            className="w-full !bg-site-green mt-4 !text-white !cursor-default"
          >
            You have already applied
            <FaCircleCheck color="#fff" size={16} />
          </Button>
        ) : (
          <Button
            primary
            className="w-full mt-4"
            onClick={() => handleApplyNow(item?.id)}
          >
            Apply Now
          </Button>
        )}
        <Modal open={open} onClose={closeModal} header width=" max-w-[424px]">
          <AppliedSuccessfull onClose={closeModal} />
        </Modal>
      </div>
    </div>
  );
}

export default OpportunityDetail;
