import React, { useEffect, useState } from "react";
import Paragraph from "../../../../../components/core/typography/Paragraph";
import Accordion, {
  AccordionItem,
} from "../../../../../components/core/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { languageIdSelector } from "../../../../../redux/slices/website/languageSlice";
import { GetAllInterests } from "../../../../../services/website/interestCategoryService";
import {
  hideLoader,
  showLoader,
} from "../../../../../redux/slices/siteLoaderSlice";
import { useFormikContext } from "formik";

interface Interests {
  id: number;
}
function WhatsInterest() {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [interestsData, setInterestsData] = useState([]);
  const dispatch = useDispatch();
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const { values, setFieldValue } = useFormikContext<{
    interests: Interests[];
  }>();

  useEffect(() => {
    const fetchAllInterests = async () => {
      dispatch(showLoader());

      try {
        const response = await GetAllInterests(langId);
        setInterestsData(response?.data?.data || []);
      } catch (error) {
        console.error("Failed to fetch interests:", error);
      } finally {
        dispatch(hideLoader());
      }
    };

    fetchAllInterests();
  }, [dispatch, langId]);

  const handleAccordionChange = (value: string | null) => {
    setSelectedValue(value);
  };

  // Function to toggle interests in the Formik field
  const handleInterestChange = (id: number) => {
    const updatedInterests = values.interests.some(
      (interest) => interest.id === id
    )
      ? values.interests.filter((interest) => interest.id !== id) // Remove if already present
      : [...values.interests, { id }]; // Add new interest

    setFieldValue("interests", updatedInterests);
  };
  console.log("dsdasdas", values?.interests);
  return (
    <div>
      <Paragraph text20 className="mb-4 font-medium">
        What’s Your Interest?
      </Paragraph>

      <Accordion value={selectedValue} onChange={handleAccordionChange}>
        {interestsData?.map((item: any, index: number) => {
          return (
            <AccordionItem
              key={`item${index}`}
              value={`item${index + 1}`}
              trigger={item?.category_title}
            >
              <div className="flex items-center gap-3">
                {item?.sub_interests?.map((subItems: any) => {
                  const isChecked = values.interests.some(
                    (interest) => interest.id === subItems?.id
                  );

                  return (
                    <label
                      key={subItems?.id}
                      htmlFor={`interest-${subItems?.id}`}
                      className="relative inline-block"
                    >
                      <input
                        type="checkbox"
                        id={`interest-${subItems?.id}`}
                        name="interests"
                        checked={isChecked}
                        className="absolute opacity-0 peer"
                        onChange={() => handleInterestChange(subItems?.id)}
                      />
                      <span className="text-sm text-site-black font-medium border border-site-black rounded-3xl px-4 py-2 cursor-pointer peer-checked:bg-site-black peer-checked:text-white peer-checked:border-site-black transition-colors inline-block">
                        {subItems?.title}
                      </span>
                    </label>
                  );
                })}
              </div>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
}

export default WhatsInterest;
