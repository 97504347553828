import React, { useEffect, useState } from "react";
import NoDataFound from "../../../assets/images/empty_event.svg";
import EventImg from "../../../assets/images/event_img.png";
import Paragraph from "../../../components/core/typography/Paragraph";
import { Link, useParams } from "react-router-dom";
import { ImLocation } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../redux/slices/userSlice";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../redux/slices/siteLoaderSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { EventInterface } from "../../../interface/Event";
import { getEventInfo } from "../../../services/website/profileService";

function Event(this: any) {
  const webProfileData = useSelector(userSelector);
  const [eventData, setEventData] = useState<EventInterface[]>([]);
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { id } = useParams();
  const [isUserId, setIsUserId] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const isLoading = useSelector(siteLoaderSelector); // Select the loading state from Redux

  const formatEventDate = (startDateTime: string) => {
    const date = new Date(startDateTime);

    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      month: "short",
      day: "numeric",
    };
    const formattedDate = date?.toLocaleDateString("en-US", options).slice(4);

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedTime = `${hours}:${minutes}${ampm}`;

    return `${formattedDate} . ${formattedTime}`;
  };

  useEffect(() => {
    if (id) {
      setIsUserId(id);
    } else {
      setIsUserId(webProfileData?.id);
    }
  }, [id]);

  const fetchEvent = async (page: number, id: any) => {
    let queryString = `?type=2&filter=0&page=${page}&limit=${itemsPerPage}&keyword=`;
    dispatch(showLoader());

    try {
      const response = await getEventInfo(id, queryString);
      const count = response?.data?.total || 0;
      if (response?.data?.publications) {
        setEventData((prevData) => [
          ...prevData,
          ...response.data.publications,
        ]);
        setTotalItems(count);
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (isUserId) {
      fetchEvent(currentPage, isUserId);
    }
  }, [currentPage, isUserId]);

  return (
    <>
      {eventData?.length === 0 && !isLoading ? (
        <div className="break-inside-avoid text-center">
          <img
            src={NoDataFound}
            alt="no-data-found"
            className="mb-6 inline-block"
            width={"250px"}
            height={"142px"}
          />
          <Paragraph text18 className="!font-medium">
            No result found
          </Paragraph>
        </div>
      ) : (
        <div
          className={"px-4"}
          id="scrollableDiv"
          style={{ overflowY: "auto", maxHeight: "80vh", height: "100%" }}
        >
          <InfiniteScroll
            dataLength={eventData?.length}
            next={() => setCurrentPage((prevPage) => prevPage + 1)}
            hasMore={eventData?.length < totalItems}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            <div className="grid sm:grid-cols-3 grid-cols-2 gap-4">
              {eventData?.map((item: EventInterface, index) => (
                <div className="col-span-1" key={index}>
                  <Link to={`/events/${item?.event?.id}`}>
                    <div className="rounded-[20px] overflow-hidden mb-3 h-[184px]">
                      <img
                        src={item?.event?.coverImage?.imageUrl}
                        alt="event-img"
                        width={184}
                        height={184}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <Paragraph text18 className="mb-1 !leading-4">
                      {item?.event?.name}
                    </Paragraph>
                    <Paragraph
                      text14
                      className="mb-2 !text-[#737373] !leading-4 !font-normal"
                    >
                      {formatEventDate(item?.event?.startDateTime)}
                    </Paragraph>
                    <Paragraph
                      text14
                      className="!font-normal flex items-center gap-1"
                    >
                      <ImLocation />
                      {item?.event?.location}
                    </Paragraph>
                  </Link>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </>
  );
}

export default Event;
