import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  signUpUserDetails: null,
};

export const signUpDetailsSlice = createSlice({
  name: "signUpUserDetails",
  initialState,
  reducers: {
    setSignUpDetails: (state, action) => {
      state.signUpUserDetails = action.payload;
    },
    clearSignUpDetails: (state) => {
      state.signUpUserDetails = null;
    },
  },
});

export const signUpDetailsSelector = (state: any) =>
  state.signUpUserDetails.signUpUserDetails;

const { actions, reducer } = signUpDetailsSlice;

export const { setSignUpDetails, clearSignUpDetails } = actions;

export default reducer;
