import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { pathRoute } from "../../../routes/pathRoute";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import Paragraph from "../../../components/core/typography/Paragraph";
import PopularOfWeek from "./PopularOfWeek";
import { HiUserAdd } from "react-icons/hi";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../redux/slices/siteLoaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { tokenSelector, userSelector } from "../../../redux/slices/userSlice";
import { MdBlock } from "react-icons/md";
import IconReport from "../../../assets/images/icon-info.svg";
import NoDataFound from "../../../assets/images/empty_repost.svg";
import {
  getArtistsOfTheWeek,
  getArtsOfTheWeek,
  getDiscoverPosts,
} from "../../../services/website/socialService";
import { TiDelete } from "react-icons/ti";
import CommentSection from "../../../components/web-component/CommentSection";
import {
  addRepost,
  deleteFeedById,
  likeFeedByid,
  removeRepost,
} from "../../../services/website/feedService";
import {
  blockUser,
  followUsers,
  reportUser,
  unfollowUsers,
} from "../../../services/website/peopleService";
import { ReactComponent as IconRepost } from "../../../assets/images/icon_repost.svg";
import PostArtImage from "../../../components/web-component/PostArtImage";
import PostDescription from "../../../components/web-component/PostDescription";
import CommentFooter from "../../../components/web-component/CommentFooter";
import PostZoomInOut from "../../../components/web-component/PostZoomInOut";
import PostFeedImage from "../../../components/web-component/PostFeedImage";
import PostSideProfile from "../../../components/web-component/PostSideProfile";
import ArtistOfWeek from "./ArtistOfWeek";
import { RepostDetails } from "../../../interface/RepostInterface";
import TimeDifference from "../../../components/web-component/TimeDifference";
import Modal from "../../../components/core/Modal";
import ReportProjectContent from "../../../components/core/ReportProjectContent";
import Button from "../../../components/core/form-components/Button";

const Discover = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isLoading = useSelector(siteLoaderSelector); // Select the loading state from Redux

  const [feedData, setFeedData] = useState<any[]>([]);
  const [artData, setArtData] = useState<any[]>([]);
  const [artistData, setArtistData] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [openCommentPostId, setOpenCommentPostId] = useState<number | null>(
    null
  );
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const webProfileData = useSelector(userSelector);
  const [reportPopup, setReportPopup] = useState(false);
  const [reportId, setReportId] = useState<number | null>(null);
  const [selectId, setSelectId] = useState<number | null>(null);

  const userDropDownItems = (isFollowedByMe: boolean) => [
    {
      icon: <HiUserAdd size={16} />,
      Link: "",
      LinkName: isFollowedByMe ? "Following" : "Follow", // Conditional text
      handleAction: true,
    },
    {
      icon: (
        <img src={IconReport} alt="icon-user-delete" width={12} height={12} />
      ),
      Link: "",
      LinkName: "Report",
      handleAction: true,
    },
    {
      icon: <MdBlock />,
      Link: "",
      LinkName: "Block",
      handleAction: true,
    },
  ];

  const profileDropDownItems = [
    {
      icon: <TiDelete />,
      Link: "",
      LinkName: "Delete",
      handleAction: true,
    },
  ];

  const handleReportId = (id: number) => {
    setReportId(id);
  };

  const toggleDropdown = (dropdown: any, item: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };

  const backToLogin = () => {
    navigate(pathRoute.auth.login);
  };

  const fetchAllData = async (page: number) => {
    dispatch(showLoader());
    try {
      let queryString = `?limit=${itemsPerPage}&page=${page}&filter=1`;

      const [feedResponse, artsResponse, artistsResponse] = await Promise.all([
        getDiscoverPosts(queryString),
        getArtsOfTheWeek(),
        getArtistsOfTheWeek(),
      ]);

      // Handle feed data
      if (feedResponse?.data?.publications) {
        setFeedData((prevData) => [
          ...prevData,
          ...feedResponse.data.publications,
        ]);
        setTotalItems(feedResponse?.data?.total || 0);
      }

      // Handle arts data
      if (artsResponse?.data?.code === 200) {
        setArtData(artsResponse.data.arts);
      }

      // Handle artists data
      if (artistsResponse?.data?.code === 200) {
        setArtistData(artistsResponse.data.users);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchAllData(currentPage);
  }, [currentPage]);

  const handleCommentSection = (postId: number) => {
    if (token) {
      setOpenCommentPostId((prevId) => (prevId === postId ? null : postId));
    } else {
      backToLogin();
    }
  };

  const renderCommentsSection = (postId: number) => {
    if (openCommentPostId === postId) {
      return <CommentSection commentId={postId} />;
    }
    return null;
  };

  const handleDeleteItem = async (id: any) => {
    dispatch(showLoader());
    try {
      const response = await deleteFeedById(id);
      if (response?.data?.code === 201) {
        setFeedData((prevData) => prevData?.filter((item) => item.id !== id));
        setTotalItems((prevTotal) => prevTotal - 1);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleFollowUser = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await followUsers(item?.userId, {});
      if (response?.data?.code === 200) {
        setFeedData((prevData) =>
          prevData.map((feedItem) =>
            feedItem.id === item?.id
              ? {
                  ...feedItem,
                  followedByMe: !item?.followedByMe, // Increment only if showing the input box
                }
              : feedItem
          )
        );
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleUnFollowUser = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await unfollowUsers(item?.userId);
      if (response?.data?.code === 200) {
        setFeedData((prevData) =>
          prevData.map((feedItem) =>
            feedItem.id === item?.id
              ? {
                  ...feedItem,
                  followedByMe: !item?.followedByMe, // Increment only if showing the input box
                }
              : feedItem
          )
        );
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleBlock = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await blockUser(item?.userId);
      console.log("Dfsfsdfdsf", response);
      if (response?.data?.code === 201) {
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleLikeOutside = async (id: number) => {
    try {
      if (token) {
        const response = await likeFeedByid(id);

        if (response?.data?.code === 200) {
          setFeedData((prevData) =>
            prevData.map((feedItem) =>
              feedItem.id === id
                ? {
                    ...feedItem,
                    likedByMe: !feedItem.likedByMe,
                    countLikes: feedItem.likedByMe
                      ? feedItem.countLikes - 1
                      : feedItem.countLikes + 1,
                  }
                : feedItem
            )
          );
        }
      } else {
        backToLogin();
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    }
  };

  const closeReportPopup = () => {
    setReportPopup(false);
    setSelectId(null);
  };

  const handleReportApi = async () => {
    dispatch(showLoader());
    try {
      let data = {
        flagId: reportId,
      };
      const response = await reportUser(data, selectId);
      if (response?.data?.code === 201) {
        setReportPopup(false);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleActionsInweb = (type: string, item: any) => {
    console.log("sfdsfsdfdsfdsf", item);
    switch (type) {
      case "Delete":
        handleDeleteItem(item?.id);
        break;

      case "Follow":
        handleFollowUser(item);
        break;

      case "Following":
        handleUnFollowUser(item);
        break;

      case "Block":
        handleBlock(item);
        break;

      case "Report":
        setSelectId(item?.id);
        setReportPopup(!reportPopup);
        break;

      default:
        console.log("Unknown action type:", type);
        break;
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      event.currentTarget.scrollHeight ===
      event.currentTarget.scrollTop + event.currentTarget.clientHeight;
    if (bottom && !isLoading && feedData.length < totalItems) {
      setCurrentPage((prevPage) => prevPage + 1); // Load the next page
    }
  };

  const handleRemoveRepost = async (id: number) => {
    dispatch(showLoader());
    try {
      if (token) {
        const response = await removeRepost(id);

        setFeedData((prevData) =>
          prevData?.map((item) =>
            item.id === id
              ? {
                  ...item,
                  isRepostedByMe: false, // Update isRepostedByMe to false
                }
              : item
          )
        );
      } else {
        backToLogin();
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleAddRepost = async (id: number) => {
    dispatch(showLoader());
    try {
      if (token) {
        let data = {
          publicationId: id,
        };
        const response = await addRepost(data);
        console.log("Fdsfdsfdsf", response);
        setFeedData((prevData) =>
          prevData?.map((item) =>
            item.id === id
              ? {
                  ...item,
                  isRepostedByMe: true, // Update isRepostedByMe to false
                }
              : item
          )
        );
      } else {
        backToLogin();
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };
  return (
    <div className="grid grid-cols-12">
      <div className="xxl:col-span-9 xl:col-span-7 md:col-span-8 col-span-12 md:order-1 order-2 xl:pb-6 pb-4 xl:px-10 lg:px-6 md:px-4 px-3 ">
        {token && (
          <Link
            to={pathRoute.website.createPost}
            className="md:order-1 order-2 border border-medium-grey rounded-lg px-5 py-3.5 flex items-center gap-3  md:mt-0 mt-5"
          >
            <div className="w-[30px] h-[30px] bg-site-black inline-flex items-center justify-center rounded-[6px]">
              <HiOutlinePlusSmall size={28} color="#fff" />
            </div>
            <Paragraph text20 className="font-medium">
              Create post
            </Paragraph>
          </Link>
        )}

        {artData?.length > 0 && <PopularOfWeek artData={artData} />}
        {feedData?.length === 0 && !isLoading ? (
          <div className="break-inside-avoid text-center">
            <img
              src={NoDataFound}
              alt="no-data-found"
              className="mb-6 inline-block"
              width={"250px"}
              height={"142px"}
            />
            <Paragraph text18 className="!font-medium">
              No result found
            </Paragraph>
          </div>
        ) : (
          <div
            onScroll={handleScroll}
            style={{ overflowY: "auto", maxHeight: "calc(100vh - 249px)" }}
          >
            <ul className="max-w-[555px] order-3">
              {feedData?.map((item: RepostDetails, index: number) => {
                const isLastItem = index === feedData.length - 1;

                return (
                  <li
                    className={`block pb-6 mb-6 border-b border-medium-grey ${
                      isLastItem ? "mb-0 border-b-0" : ""
                    }`}
                  >
                    {item?.isReposted && (
                      <div className="bg-light-grey lg:px-5 sm:px-4 px-2 sm:py-[6px] py-1 rounded-lg inline-flex items-center sm:gap-2 gap-1 mb-[6px]">
                        <span className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative flex-shrink-0">
                          <IconRepost />
                        </span>
                        <Paragraph
                          className={`md:text-[18px] md:text-[16px] text-[14px] leading-[17px] font-medium`}
                        >
                          @{item?.reposter?.nickname} reposted{" "}
                          <TimeDifference date={item?.repostCreatedAt || ""} />
                        </Paragraph>
                      </div>
                    )}

                    <div className={`relative md:ps-[66px] sm:ps-[40px] ps-5`}>
                      <PostSideProfile
                        toggleDropdown={(e) => toggleDropdown(e, item)}
                        item={item}
                        profileDropDownItems={
                          item?.user?.id == webProfileData?.id
                            ? profileDropDownItems
                            : userDropDownItems(item?.followedByMe)
                        }
                        handleActionsInweb={(e: any) =>
                          handleActionsInweb(e, item)
                        }
                      />
                      <Modal
                        open={reportPopup}
                        header
                        onClose={closeReportPopup}
                        width="sm:w-[448px] w-[300px]"
                        modalPadding="p-6"
                      >
                        <ReportProjectContent
                          onSelectionChange={handleReportId}
                        />
                        <div className="flex items-center justify-center md:gap-4 gap-3">
                          <Button
                            borderButton
                            className="w-full"
                            onClick={() => setReportPopup(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            primary
                            onClick={handleReportApi}
                            className="w-full"
                          >
                            Send
                          </Button>
                        </div>
                      </Modal>

                      {item?.type === "0" ? (
                        <>
                          <div
                            className={`${
                              item?.images && item?.images?.length > 1
                                ? "relative feed-carousel pb-6 max-w-[490px]"
                                : "relative pb-6 max-w-[490px]"
                            }`}
                          >
                            <PostFeedImage
                              itemData={item}
                              feedData={feedData}
                            />
                            {item?.images?.length === 1 && (
                              <PostZoomInOut
                                images={item?.images[0]?.imageUrl}
                                feedData={feedData}
                              />
                            )}

                            <CommentFooter
                              isRepost={true}
                              isLike={true}
                              isComment={true}
                              id={item?.id || 0}
                              handleCommentSection={handleCommentSection}
                              handleLikeOutside={handleLikeOutside}
                              countLikes={item?.countLikes}
                              countComments={item?.countComments}
                              likedByMe={item?.likedByMe}
                              isRepostedByMe={item?.isRepostedByMe}
                              handleRemoveRepost={handleRemoveRepost}
                              handleAddRepost={handleAddRepost}
                            />
                          </div>
                          {(item?.video || item?.images) && (
                            // when there is image or video show text at bottom
                            <PostDescription
                              description={item?.text?.contentText}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <div className="relative pb-6 max-w-[490px]">
                            <PostArtImage itemData={item} feedData={feedData} />
                            {item?.art?.images?.length === 1 && (
                              <PostZoomInOut
                                images={item.art.images[0]?.imageUrl}
                                feedData={feedData}
                              />
                            )}

                            <CommentFooter
                              isRepost={true}
                              isLike={true}
                              isComment={true}
                              id={item?.id || 0}
                              handleCommentSection={handleCommentSection}
                              handleLikeOutside={handleLikeOutside}
                              countLikes={item?.countLikes}
                              countComments={item?.countComments}
                              likedByMe={item?.likedByMe}
                              isRepostedByMe={item?.isRepostedByMe}
                              handleRemoveRepost={handleRemoveRepost}
                              handleAddRepost={handleAddRepost}
                            />
                          </div>
                          {(item?.art?.video || item?.art?.images) && (
                            // when there is image or video show text at bottom
                            <PostDescription
                              description={item?.text?.contentText}
                            />
                          )}
                        </>
                      )}

                      {renderCommentsSection(item.id || 0)}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      {artistData.length > 0 && (
        <div className="xxl:col-span-3 xl:col-span-5 md:col-span-4 col-span-12 md:order-2 order-1">
          <div className="sticky top-0">
            <ArtistOfWeek artistData={artistData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Discover;
