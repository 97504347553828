import { Navigate, Outlet, useLocation } from "react-router-dom";
import { pathRoute } from "./pathRoute";
import { useSelector } from "react-redux";
import { tokenSelector } from "../redux/slices/userSlice";

function PrivateRoute() {
  const location = useLocation();
  const token = useSelector(tokenSelector);
  const isAuthenticated = Boolean(token);
  console.log("guest11", token);

  if (!isAuthenticated) {
    return (
      <Navigate to={pathRoute.auth.login} state={{ from: location }} replace />
    );
  }

  return <Outlet />;
}

export default PrivateRoute;
