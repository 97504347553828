import React from "react";
import Button from "../../../components/core/form-components/Button";
import Checkbox from "../../../components/core/form-components/Checkbox";
import Paragraph from "../../../components/core/typography/Paragraph";

function TabColorPopup() {
  return (
    <div>
      <Paragraph text20 className="font-medium mb-2.5">
        Tab color
      </Paragraph>
      <Paragraph text16 className="!text-dark-grey mb-5">
        You can choose colors{" "}
      </Paragraph>
      <Paragraph text18 className="font-medium mb-3">
        Select color
      </Paragraph>
      <Checkbox
        name="default"
        id="default"
        w18
        labelText="text-[18px] leading-[17px]"
      >
        Set make it default
      </Checkbox>
      <div className="flex items-center sm:gap-3 gap-2.5 mt-2.5 mb-30">
        <label
          htmlFor="red"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#E93641]"
        >
          <input type="radio" name="color" id="red" className="sr-only" />
        </label>
        <label
          htmlFor="blue"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#4F83E8]"
        >
          <input type="radio" name="color" id="blue" className="sr-only" />
        </label>
        <label
          htmlFor="green"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#47CF4D]"
        >
          <input type="radio" name="color" id="green" className="sr-only" />
        </label>
        <label
          htmlFor="pink"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#F862C5]"
        >
          <input type="radio" name="color" id="pink" className="sr-only" />
        </label>
        <label
          htmlFor="orange"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#F69809]"
        >
          <input type="radio" name="color" id="orange" className="sr-only" />
        </label>
        <label
          htmlFor="purple"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#6D4FE5]"
        >
          <input type="radio" name="color" id="purple" className="sr-only" />
        </label>
      </div>
      <Button className="w-full" primary>
        Select Color
      </Button>
    </div>
  );
}

export default TabColorPopup;
