import React from "react";
import Paragraph from "../../../../components/core/typography/Paragraph";
import Art2 from "../../../../assets/images/art2.png";
import { useSelector } from "react-redux";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import { useLocation } from "react-router-dom";
import ImageWithDefault from "../../../../components/web-component/DefaultImage";

function SaleDetail() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const location = useLocation();
  const item = location?.state?.item;

  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  return (
    <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
      <div className="grid grid-cols-2 lg:gap-6 md:gap-4 gap-3">
        <div className="block col-span-1">
          <div className="rounded-[20px] overflow-hidden mb-3 text-center flex items-center justify-center bg-light-grey">
            <ImageWithDefault
              src={item?.art?.images?.[0]?.imageUrl}
              alt="popular-art"
              className="w-auto"
            />
          </div>
        </div>
        <div className="col-span-1">
          <Paragraph text24 className="mb-4">
            {item?.art?.title}
          </Paragraph>
          <Paragraph text24 className="">
            {item?.art?.price}€
          </Paragraph>
          <ul className="mt-4 pt-4 border-t border-b border-medium-grey">
            <li className="flex items-center justify-between gap-3 mb-3">
              <Paragraph text18 className="!font-normal">
                {LanguageTranslation({
                  labelName: "date",
                  languageCode: langId || "fr",
                }) || "Date"}
              </Paragraph>
              <Paragraph text18 className="!font-normal !text-dark-grey">
                {formatDate(item?.createdAt)}
              </Paragraph>
            </li>
            <li className="flex items-center justify-between gap-3 mb-3">
              <Paragraph text18 className="!font-normal">
                {LanguageTranslation({
                  labelName: "type_art",
                  languageCode: langId || "fr",
                }) || "Type of art"}
              </Paragraph>
              <Paragraph text18 className="!font-normal !text-dark-grey">
                {item?.art?.category?.name}
              </Paragraph>
            </li>
            <li className="flex items-center justify-between gap-3 mb-3">
              <Paragraph text18 className="!font-normal">
                {LanguageTranslation({
                  labelName: "buyer_name",
                  languageCode: langId || "fr",
                }) || "Buyer"}
              </Paragraph>
              <Paragraph text18 className="!font-normal !text-dark-grey">
                {item?.buyer?.nickname}
              </Paragraph>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SaleDetail;
