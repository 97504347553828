import React, { useState } from "react";
import Paragraph from "./typography/Paragraph";
import RadioButton from "./form-components/RadioButton";
import { Button } from "reactstrap";
import { commonListSelector } from "../../redux/slices/website/commonSlice";
import { useSelector } from "react-redux";

interface ReportProjectContentProps {
  onSelectionChange: (id: any, userid?: any) => void; // callback to pass the selected ID to the parent
}

const ReportProjectContent = ({
  onSelectionChange,
}: ReportProjectContentProps) => {
  const allData = useSelector(commonListSelector);
  const [selectedId, setSelectedId] = useState<string | number | null>(null);

  const handleRadioChange = (id: string | number) => {
    setSelectedId(id); // Update the local state
    onSelectionChange(id); // Pass the selected id to the parent component
  };
  return (
    <>
      <Paragraph className="text-[20px] leading-[18px] font-medium mb-3">
        Report a project
      </Paragraph>
      <Paragraph
        text16
        className="!leading-[15px] !text-dark-grey md:mb-4 mb-3"
      >
        Please see the community rules to learn more about what is allowed and
        what is not on Artgapi.
      </Paragraph>
      <div className="flex flex-col md:gap-4 sm:gap-3 gap-2 md:mb-[34px] mb-[24px]">
        {allData?.publicationFlags?.map((item: any) => {
          return (
            <RadioButton
              key={item?.id}
              id={item?.id}
              name={item?.name}
              labelClass="!text-base"
              checked={selectedId === item?.id} // Ensure the selected radio button is checked
              onChange={() => handleRadioChange(item?.id)} // Trigger onChange when a radio is selected
            >
              {item?.name}
            </RadioButton>
          );
        })}
      </div>
    </>
  );
};

export default ReportProjectContent;
