import React, { useEffect, useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ArtWeekInterface } from "../../../interface/ArtWeek";
import DummyProfile from "../../../assets/images/dummy_logo.png";
import ImageWithDefault from "../../../components/web-component/DefaultImage";
import { pathRoute } from "../../../routes/pathRoute";
import { useSelector } from "react-redux";
import { tokenSelector, userSelector } from "../../../redux/slices/userSlice";

function PopularOfWeek({ artData }: any) {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3.5,
          arrows: true,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4.5,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };
  const token = useSelector(tokenSelector);

  const navigate = useNavigate();
  const webProfileData = useSelector(userSelector);

  const handleArt = (id: any) => {
    navigate(`${pathRoute.website.artDetail}/${id}`, {
      state: { isWebsite: true, userId: webProfileData?.id },
    });
  };

  return (
    <div className={`md:order-2 mb-30 ${token ? "mt-[30px]" : "mt-[24px]"} `}>
      <Paragraph text32 className="md:mb-5 mb-3">
        Art of the week
      </Paragraph>
      <Slider {...settings}>
        {artData?.map((item: ArtWeekInterface, idx: number) => {
          return (
            <div key={idx} className="pe-2">
              <div className="col-span-1 rounded-xl overflow-hidden">
                <div
                  onClick={() => handleArt(item?.id)}
                  className="relative cursor-pointer"
                >
                  {/* <img
                    src={item?.image?.imageUrl || item?.video?.imageUrl}
                    alt={`popular-art ${idx + 1}`}
                    width={152}
                    height={144}
                    className="w-full h-[144px] object-cover"
                  /> */}
                  <ImageWithDefault
                    src={
                      item?.image?.imageUrl
                        ? item?.image?.imageUrl
                        : item?.video?.imageUrl
                        ? item?.video?.imageUrl
                        : ""
                    }
                    alt={`popular-art ${idx + 1}`}
                    className="w-full h-[144px] object-cover"
                    width={152}
                    height={144}
                  />
                  <div className="absolute w-full left-0 bottom-0 flex items-center gap-[6px] px-[10px] py-[6px] bg-gradient-to-r from-red -from-[4.18%] backdrop-blur-[19px] to-transparent to-[104.23%]">
                    <ImageWithDefault
                      src={item?.owner?.avatar}
                      alt={`profile ${idx + 1}`}
                      width={24}
                      height={24}
                      className="w-[24px] h-[24px] object-cover rounded-full"
                    />
                    <Paragraph text14 className="!text-white !font-normal">
                      @{item?.owner?.nickname}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default PopularOfWeek;
