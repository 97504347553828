import React from "react";
import Paragraph from "../core/typography/Paragraph";

interface postInterface {
  description: string;
}
const PostOnlyText = ({ description }: postInterface) => {
  return (
    <div className="flex items-center justify-center rounded-[20px] overflow-hidden relative w-auto px-10 py-20 md:min-h-[370px] min-h-[300px] bg-[#FCF4D0]  ">
      <Paragraph className="lg:text-[50px] text-[36px] lg:leading-[56px] leading-[42px] font-medium">
        {description}
      </Paragraph>
    </div>
  );
};

export default PostOnlyText;
