import React, { useEffect, useState } from "react";
import SlidingTabBar from "../../../components/core/SlidingTabBar";
import { Tab } from "../../../interface/Tabs";
import ArtRoom from "../../portfolio/art-room/ArtRoom";
import MyProfileLeftSection from "./MyProfileLeftSection";
import ProfileFeed from "./feed/ProfileFeed";
import Event from "../event/Event";
import Repost from "../repost/Repost";
import ProfileArtRoom from "./artroom/ProfileArtRoom";

function MyProfile() {
  const profileDropDownItems = [
    { Link: "", LinkName: "Report" },
    {
      Link: "",
      LinkName: "Block",
    },
  ];
  const tabs: Tab[] = [
    { id: "art_room", name: "Art Room", content: <ProfileArtRoom /> },
    { id: "feed", name: "Feed", content: <ProfileFeed /> },
    { id: "event", name: "Event", content: <Event /> },
    { id: "repost", name: "Repost", content: <Repost /> },
  ];

  return (
    <div className="py-6 sm:pl-4 pl-3 xl:pr-10 lg:pr-4 pr-3">
      <div className="grid grid-cols-12 xl:gap-[48px] gap-6">
        <MyProfileLeftSection isEditProfile={false} />
        <div className="xl:col-span-7 lg:col-span-10 col-span-12">
          <div className="border border-medium-grey rounded-2xl overflow-hidden pb-30">
            <SlidingTabBar tabs={tabs} borderedTab />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
