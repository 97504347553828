import { ErrorMessage, useField } from "formik";
import React, { useState } from "react";
import { HiOutlineEyeSlash } from "react-icons/hi2";
import { SlEye } from "react-icons/sl";
import { InputTypeProps } from "../../../interface/InputType";

function InputType({
  type,
  name,
  disabled,
  placeholder,
  icon,
  className,
  isCompulsory,
  ref,
  value,
  showError = true,
  onChange,
  maxDate,
  ...props
}: InputTypeProps) {
  const [focused, setFocused] = useState(false);
  const [field, meta, helpers] = useField(name); // meta and helpers added for further use

  const onBlur = () => setFocused(false);
  const onFocus = () => setFocused(true);

  const [isVisible, setVisible] = useState(false);
  const toggle = () => {
    setVisible(!isVisible);
  };
  return (
    <div
      className={`form-control relative  ${
        props.fullWidth ? "w-full flex-shrink" : "flex-shrink-0"
      }`}
    >
      <input
        {...props}
        {...field}
        placeholder={placeholder ? placeholder : ""}
        ref={ref}
        name={name}
        onFocus={onFocus}
        onBlur={(e) => {
          field.onBlur(e);
          onBlur();
        }}
        onChange={(e) => {
          field.onChange(e); // Call Formik's onChange
          if (onChange) onChange(e); // Call additional onChange if provided
        }}
        disabled={disabled !== undefined ? disabled : false}
        type={type === "password" ? (isVisible ? "text" : "password") : type}
        max={type === "date" && maxDate ? maxDate : undefined}
        value={value}
        className={`${
          focused || field.value ? "hasFocus" : ""
        } appearance-none rounded-lg border border-medium-grey text-base leading-5 font-normal w-full focus:outline-0 focus:border-dark-grey placeholder:text-site-grey placeholder:font-normal placeholder:leading-5 text-site-black ${
          type === "search"
            ? "bg-search-icon-grey bg-no-repeat bg-[left_12px_top_8px] ps-[40px] pe-3 py-[10px]"
            : type === "date"
            ? "px-[13px] py-3"
            : type === "password"
            ? "px-[15px] lg:py-[13px] py-[11px] pe-[40px]"
            : "px-[15px] lg:py-[13px] py-[11px]"
        } ${className ? className : ""} `}
      />
      {type === "password" && (
        <span
          className="absolute right-4 top-[13px] cursor-pointer"
          onClick={toggle}
        >
          {isVisible ? (
            <HiOutlineEyeSlash size={20} color="#a4a4a5" />
          ) : (
            <SlEye size={20} color="#a4a4a5" />
          )}
        </span>
      )}

      {showError && (
        <ErrorMessage name={name}>
          {(msg) => (
            <div className="text-site-red text-sm font-medium">{msg}</div>
          )}
        </ErrorMessage>
      )}
    </div>
  );
}

export default InputType;
