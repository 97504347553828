import React from "react";
import CommentFooter from "./CommentFooter";
import Slider from "react-slick";
import FeedImage2 from "../../assets/images/feed-img-2.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PostCarousel = ({ itemImg }: any) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5,
    mobileFirst: true,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      {itemImg?.map((image: any, idx: any) => (
        <div key={idx} className="block rounded-[20px] overflow-hidden">
          <img
            src={image.imageUrl}
            alt={`Feed image ${idx + 1}`}
            className="w-full sm:h-auto h-[180px] max-h-[300px] object-cover rounded-[20px] bg-light-grey cursor-pointer"
            loading="lazy"
          />
        </div>
      ))}
    </Slider>
  );
};

export default PostCarousel;
