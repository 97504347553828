import React, { useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import Dropdown from "../../../components/core/form-components/Dropdown";
import { IoEllipsisVertical } from "react-icons/io5";
import { LuZoomIn } from "react-icons/lu";
import { HiMiniPlusCircle } from "react-icons/hi2";
import { FaHeart } from "react-icons/fa";
import { IoMdChatboxes, IoMdShareAlt } from "react-icons/io";
import { TbRepeat } from "react-icons/tb";
import Profile from "../../../assets/images/dummy_profile.png";
import FeedImage from "../../../assets/images/feed-img-2.png";

function NewFeed() {
  const [isOpen, setIsOpen] = useState(false);
  const profileDropDownItems = [
    { Link: "", LinkName: "Report" },
    {
      Link: "",
      LinkName: "Block",
    },
  ];
  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };
  return (
    <div className="">
      <Paragraph text24 className="font-semibold mb-5">
        New
      </Paragraph>
      <div className="relative block md:pb-[40px] pb-6 mb-6 border-b border-medium-grey md:ps-[66px] sm:ps-[40px] ps-5 pt-[10px]">
        <div className="flex items-center absolute left-0 top-0 z-[1]">
          <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
            <img src={Profile} alt="profile-photo" />
          </div>
          <div className="bg-white flex items-center md:gap-10 gap-4 ps-6 pe-4 md:py-3 py-2 rounded-se-[20px] rounded-ee-[20px] relative sm:-left-[15px] -left-[20px] -z-[1]">
            <div>
              <Paragraph text18 className="!leading-4">
                @Aena Gilbert
              </Paragraph>
              <Paragraph
                text14
                className="!leading-[17px] !font-normal !text-dark-grey"
              >
                2 hours ago
              </Paragraph>
            </div>
            <Dropdown
              onClick={() => toggleDropdown("userProfile")}
              dropdownItems={profileDropDownItems}
              dropdownWidth="w-[174px]"
            >
              <IoEllipsisVertical size={20} />
            </Dropdown>
          </div>
        </div>
        <div className="relative pb-6 max-w-[490px]">
          <div className="block rounded-[20px] overflow-hidden relative w-auto  ">
            <img
              src={FeedImage}
              alt="feed-img"
              className="w-full h-auto rounded-[20px] bg-light-grey cursor-pointer"
            />
          </div>
          <div className="absolute right-0 top-0 inline-flex items-center justify-center bg-white w-[60px] rounded-3xl py-1.5 px-3.5 cursor-pointer">
            <LuZoomIn size={22} color="#FFD45A" />
          </div>
          <div className="flex items-center justify-between gap-2.5 absolute bottom-0 w-full">
            <button className="cursor-pointer bg-site-yellow flex items-center gap-1 rounded-3xl px-3 py-1.5">
              <Paragraph text14 className="!font-medium">
                Comment
              </Paragraph>
              <HiMiniPlusCircle size={18} />
            </button>
            <div className="flex items-center md:gap-5 gap-3">
              <button
                className={`cursor-pointer bg-site-yellow rounded-full w-[30px] h-[30px] flex items-center justify-center relative`}
                disabled
              >
                <FaHeart size={16} />
                <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                  1
                </span>
              </button>
              <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative">
                <IoMdChatboxes
                  size={18}
                  className="cursor-pointer"
                  // onClick={() => handleCommentSection(item)}
                />
                <span className="bg-site-red cursor-default min-w-[18px] text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                  2
                </span>
              </button>
              <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative rotate-90">
                <TbRepeat
                  size={18}
                  className="cursor-pointer"
                  // onClick={() => handleCommentSection(item)}
                />
              </button>
              <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative">
                <IoMdShareAlt
                  size={20}
                  className="cursor-pointer"
                  // onClick={() => handleCommentSection(item)}
                />
              </button>
            </div>
          </div>
        </div>
        <Paragraph
          text14
          className="!text-site-black !font-normal md:mt-5 mt-4"
        >
          Hey! I would like to share latest abstract piece.
        </Paragraph>
      </div>
    </div>
  );
}

export default NewFeed;
