import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { languageIdSelector } from "../../../../../redux/slices/website/languageSlice";
import {
  notificationPrefSelector,
  setNotificationPref,
} from "../../../../../redux/slices/website/notificationSlice";
import { addNotificationPreferences } from "../../../../../services/website/accountService";
import Paragraph from "../../../../../components/core/typography/Paragraph";
import SwitchToggle from "../../../../../components/core/form-components/SwitchToggle";
import {
  hideLoader,
  showLoader,
} from "../../../../../redux/slices/siteLoaderSlice";

function NotificationPref() {
  const language = useSelector(languageIdSelector);
  const notificationListData = useSelector(notificationPrefSelector);
  const [commentsNotifications, setCommentsNotifications] = useState(false);
  const [likesNotifications, setLikesNotifications] = useState(false);
  const [purchasesNotifications, setPurchasesNotifications] = useState(false);
  const [publicationsNotifications, setPublicationsNotifications] =
    useState(false);
  const [masterClassNotifications, setMasterClassNotifications] =
    useState(false);
  const [followsNotifications, setFollowsNotifications] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (notificationListData) {
      setCommentsNotifications(
        notificationListData?.commentsNotifications || false
      );
      setLikesNotifications(notificationListData?.likesNotifications || false);
      setPurchasesNotifications(
        notificationListData?.purchasesNotifications || false
      );
      setPublicationsNotifications(
        notificationListData?.publicationsNotifications || false
      );
      setMasterClassNotifications(
        notificationListData?.masterClassNotifications || false
      );
      setFollowsNotifications(
        notificationListData?.followsNotifications || false
      );
    }
  }, [notificationListData]);

  const handleToggleApi = async () => {
    dispatch(showLoader());
    try {
      let data = {
        publicationsNotifications: publicationsNotifications,
        likesNotifications: likesNotifications,
        commentsNotifications: commentsNotifications,
        purchasesNotifications: purchasesNotifications,
        masterClassNotifications: masterClassNotifications,
        followsNotifications: followsNotifications,
      };
      const response = await addNotificationPreferences(data);
      if (response?.data?.code === 200) {
        dispatch(setNotificationPref(data));
        setIsChanged(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (isChanged) {
      handleToggleApi();
    }
  }, [isChanged]);
  return (
    <div className="lg:p-30 md:p-4 p-3 !pt-0 grid grid-cols-12">
      <div className="xl:col-span-8 col-span-12">
        <ul>
          <li className="flex items-center justify-between gap-4 border border-medium-grey rounded-xl lg:px-6 md:px-4 px-3 md:py-4 py-3 mb-3">
            <Paragraph text18 className="!font-normal">
              {"When someone posts a comment on one of your artworks"}
            </Paragraph>
            <SwitchToggle
              onChange={() => {
                setCommentsNotifications(!commentsNotifications);
                setIsChanged(true);
              }}
              isChecked={commentsNotifications}
            />
          </li>
          <li className="flex items-center justify-between gap-4 border border-medium-grey rounded-xl lg:px-6 md:px-4 px-3 md:py-4 py-3 mb-3">
            <Paragraph text18 className="!font-normal">
              {"When someone likes one of your artworks"}
            </Paragraph>
            <SwitchToggle
              onChange={() => {
                setIsChanged(true);
                setLikesNotifications(!likesNotifications);
              }}
              isChecked={likesNotifications}
            />
          </li>
          <li className="flex items-center justify-between gap-4 border border-medium-grey rounded-xl lg:px-6 md:px-4 px-3 md:py-4 py-3 mb-3">
            <Paragraph text18 className="!font-normal">
              {"When one of your artworks is sold"}
            </Paragraph>
            <SwitchToggle
              onChange={() => {
                setIsChanged(true);
                setPurchasesNotifications(!purchasesNotifications);
              }}
              isChecked={purchasesNotifications}
            />
          </li>
          <li className="flex items-center justify-between gap-4 border border-medium-grey rounded-xl lg:px-6 md:px-4 px-3 md:py-4 py-3 mb-3">
            <Paragraph text18 className="!font-normal">
              {"When an artist you are following posts"}
            </Paragraph>
            <SwitchToggle
              onChange={() => {
                setIsChanged(true);
                setPublicationsNotifications(!publicationsNotifications);
              }}
              isChecked={publicationsNotifications}
            />
          </li>
          <li className="flex items-center justify-between gap-4 border border-medium-grey rounded-xl lg:px-6 md:px-4 px-3 md:py-4 py-3 mb-3">
            <Paragraph text18 className="!font-normal">
              {"Would you like to allow masterclass notification"}
            </Paragraph>
            <SwitchToggle
              onChange={() => {
                setIsChanged(true);
                setMasterClassNotifications(!masterClassNotifications);
              }}
              isChecked={masterClassNotifications}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NotificationPref;
