import React, { useState } from "react";
import Paragraph from "../core/typography/Paragraph";
import { HiMiniPlusCircle } from "react-icons/hi2";
import { FaHeart } from "react-icons/fa";
import { IoMdChatboxes } from "react-icons/io";
import { TbRepeat } from "react-icons/tb";
import { ReactComponent as IconRepost } from "../../assets/images/icon_repost.svg";

interface commentFooterinterface {
  isComment: Boolean;
  isLike: Boolean;
  isRepost: Boolean;
  id: number;
  countLikes: number;
  countComments: number;
  likedByMe: boolean;
  handleCommentSection: (id: number) => void;
  handleLikeOutside: (id: number) => void;
  handleRemoveRepost?: (id: number) => void;
  isRepostedByMe?: boolean;
  handleAddRepost?: (id: number) => void;
}
const CommentFooter = ({
  isComment,
  isLike,
  isRepost,
  id,
  countLikes,
  countComments,
  likedByMe,
  handleCommentSection,
  handleLikeOutside,
  handleRemoveRepost,
  isRepostedByMe,
  handleAddRepost,
}: commentFooterinterface) => {
  const handleRemove = (id: number) => {
    if (handleRemoveRepost) {
      handleRemoveRepost(id || 0);
    }
  };

  const handleAdd = (id: number) => {
    if (handleAddRepost) {
      handleAddRepost(id || 0);
    }
  };

  return (
    <div className="flex items-center justify-between gap-2.5 absolute bottom-0 w-full">
      {isComment && (
        <button
          onClick={() => handleCommentSection(id || 0)}
          className="cursor-pointer bg-site-yellow flex items-center gap-1 rounded-3xl px-3 py-1.5"
        >
          <Paragraph text14 className="!font-medium">
            Comment
          </Paragraph>
          <HiMiniPlusCircle size={18} />
        </button>
      )}

      <div className="flex items-center md:gap-5 gap-3">
        {isLike && (
          <button
            className={`cursor-pointer bg-site-yellow rounded-full w-[30px] h-[30px] flex items-center justify-center relative`}
            onClick={() => handleLikeOutside(id)}
          >
            <FaHeart size={16} color={likedByMe ? "#F61111" : "#121111"} />
            {countLikes > 0 && (
              <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                {countLikes}
              </span>
            )}
          </button>
        )}

        {isComment && (
          <button
            onClick={() => handleCommentSection(id || 0)}
            className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative"
          >
            <IoMdChatboxes size={18} className="cursor-pointer" />
            {countComments > 0 && (
              <span className="bg-site-red cursor-default min-w-[18px] text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                {countComments}
              </span>
            )}
          </button>
        )}
        {isRepostedByMe ? (
          <span
            onClick={() => handleRemove(id || 0)}
            className="bg-site-yellow rounded-full cursor-pointer w-[30px] h-[30px] flex items-center justify-center relative flex-shrink-0"
          >
            <IconRepost />
          </span>
        ) : isRepost ? (
          <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative rotate-90">
            <TbRepeat
              size={18}
              className="cursor-pointer"
              onClick={() => handleAdd(id || 0)}
            />
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CommentFooter;
