import React, { useEffect, useState } from "react";

import Paragraph from "../../../../components/core/typography/Paragraph";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import { useDispatch, useSelector } from "react-redux";
import { FaCircleCheck } from "react-icons/fa6";
import NoDataFound from "../../../../assets/images/empty_repost.svg";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../../redux/slices/siteLoaderSlice";
import { getTransactionsList } from "../../../../services/website/accountService";
import InfiniteScroll from "react-infinite-scroll-component";
import { TransactionsInterface } from "../../../../interface/Transactions";

function MyTransactions() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const dispatch = useDispatch();
  const [transactionList, setTransactionList] = useState<
    TransactionsInterface[]
  >([]);
  const [currentResponsePage, setCurrentResponsePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const isLoading = useSelector(siteLoaderSelector); // Select the loading state from Redux

  const itemsPerPage = 10;

  const fetchTransactionList = async (page: number) => {
    dispatch(showLoader());
    try {
      let queryString = `?limit=${itemsPerPage}&page=${page}&startDate=&endDate=&order=desc`;

      const response = await getTransactionsList(queryString);
      // Handle feed data
      if (response?.data?.success) {
        setTransactionList((prevData) => [
          ...prevData,
          ...response.data.transactions,
        ]);
        setTotalItems(response?.data?.total || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchTransactionList(currentResponsePage);
  }, [currentResponsePage]);

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };

    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      new Date(dateString)
    );
    return formattedDate.replace(/\s(\d{4})/, ", $1"); // Adds a comma before the year
  };

  return (
    <div className="">
      <Paragraph text24 className="font-semibold mb-6 ">
        {LanguageTranslation({
          labelName: "my_transactions",
          languageCode: langId || "fr",
        }) || "My Transactions"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
        {transactionList?.length === 0 && !isLoading ? (
          <div className="break-inside-avoid text-center">
            <img
              src={NoDataFound}
              alt="no-data-found"
              className="mb-6 inline-block"
              width={"250px"}
              height={"142px"}
            />
            <Paragraph text18 className="!font-medium">
              No result found
            </Paragraph>
          </div>
        ) : (
          <div
            id="scrollableDiv"
            style={{ overflowY: "auto", maxHeight: "80vh", height: "100%" }}
          >
            <InfiniteScroll
              dataLength={transactionList?.length}
              next={() => setCurrentResponsePage((prevPage) => prevPage + 1)}
              hasMore={transactionList?.length < totalItems}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              <ul className="max-h-[calc(100vh-224px)] overflow-y-auto">
                {transactionList?.map((item: TransactionsInterface) => {
                  return (
                    <li className="border border-medium-grey rounded-xl lg:p-6 p-4 flex items-center justify-between gap-4 mb-3">
                      <div className="flex items-start gap-4">
                        <FaCircleCheck size={30} color="#1AB53C" />
                        <div>
                          <Paragraph
                            text16
                            className="!font-normal  !text-dark-grey"
                          >
                            #{item?.id} | {formatDate(item?.date)}
                          </Paragraph>
                          <Paragraph text16 className="!font-normal  ">
                            {item?.plan_name}
                          </Paragraph>
                          <Paragraph
                            text16
                            className="!font-normal !text-dark-grey"
                          >
                            {item?.masterclass_title}
                          </Paragraph>
                        </div>
                      </div>
                      <Paragraph text20 className="!font-medium">
                        180€
                      </Paragraph>
                    </li>
                  );
                })}
              </ul>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyTransactions;
