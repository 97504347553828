import React from "react";
import Paragraph from "../../../../../components/core/typography/Paragraph";
import { useFormikContext } from "formik";
import InputType from "../../../../../components/core/form-components/InputType";
import FormLabel from "../../../../../components/core/typography/FormLabel";

function BasicInfo() {
  const today = new Date().toISOString().split("T")[0];

  const { values } = useFormikContext<{
    name: string;
    surname: string;
    email: string;
    nickName: string;
    birthdate?: string;
  }>();

  return (
    <div>
      <Paragraph text20 className="mb-4 font-medium">
        Basic Info
      </Paragraph>

      <div className="form-group md:mb-6 mb-4">
        <FormLabel>
          Name<span className="text-site-red">*</span>
        </FormLabel>
        <InputType
          name="name"
          placeholder="Enter your name"
          value={values.name}
          type="text"
        />
      </div>
      <div className="form-group md:mb-6 mb-4">
        <FormLabel>
          Surname<span className="text-site-red">*</span>
        </FormLabel>
        <InputType
          name="surname"
          placeholder="Enter your surname"
          value={values.surname}
          type="text"
        />
      </div>
      <div className="form-group md:mb-6 mb-4">
        <FormLabel>
          Pseudonym name<span className="text-site-red">*</span>
        </FormLabel>
        <InputType
          name="nickName"
          placeholder="Enter pseudonym name"
          value={values.nickName}
          type="text"
        />
      </div>
      <div className="form-group md:mb-6 mb-4">
        <FormLabel>
          Email address<span className="text-site-red">*</span>
        </FormLabel>
        <InputType
          type="email"
          name="email"
          placeholder="Enter email address"
          value={values.email}
        />
      </div>
      <div className="form-group md:mb-6 mb-4">
        <FormLabel>
          Birthdate<span className="text-site-red"></span>
        </FormLabel>
        <InputType
          type="date"
          name="birthdate"
          placeholder=""
          value={values.birthdate}
          maxDate={today}
          onKeyDown={(e: any) => e.preventDefault()} // Prevent manual typing
          onPaste={(e: any) => e.preventDefault()} // Prevent pasting into the field
        />
      </div>
    </div>
  );
}

export default BasicInfo;
