import { API } from "../../apiEndPoints/website/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../axios/axiosMiddleware";

export const getMasterClassList = (queryString: String) => {
  return axiosGet(`${API?.OTHER?.MASTER_CLASS}/${queryString}`);
};

export const getMasterClassListDetails = (id: number) => {
  return axiosGet(`${API?.OTHER?.MASTER_CLASS}/${id}`);
};
