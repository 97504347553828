import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationListData: null,
};

export const notificationPrefSlice = createSlice({
  name: "notificationListData",
  initialState,
  reducers: {
    setNotificationPref: (state, action) => {
      state.notificationListData = action.payload;
    },
  },
});

export const notificationPrefSelector = (state: any) =>
  state.notificationListData.notificationListData;

const { actions, reducer } = notificationPrefSlice;

export const { setNotificationPref } = actions;

export default reducer;
