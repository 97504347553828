import React from "react";
import moment from "moment";

interface TimeDifferenceProps {
  date: string; // The date string to calculate the difference from
}

const TimeDifference: React.FC<TimeDifferenceProps> = ({ date }) => {
  const calculateTimeDifference = (date: string): string => {
    const today = moment();
    const createdDate = moment(date);
    let diff = today.diff(createdDate, "years");
    let message = diff === 1 ? "year ago" : "years ago";

    if (diff === 0) {
      diff = today.diff(createdDate, "months");
      message = diff === 1 ? "month ago" : "months ago";

      if (diff === 0) {
        diff = today.diff(createdDate, "weeks");
        message = diff === 1 ? "week ago" : "weeks ago";

        if (diff === 0) {
          diff = today.diff(createdDate, "days");
          message = diff === 1 ? "day ago" : "days ago";

          if (diff === 0) {
            diff = today.diff(createdDate, "hours");
            message = diff === 1 ? "hour ago" : "hours ago";

            if (diff === 0) {
              diff = today.diff(createdDate, "minutes");
              message = diff === 1 ? "minute ago" : "minutes ago";

              if (diff === 0) {
                return "just now";
              }
            }
          }
        }
      }
    }

    return `${diff} ${message}`;
  };

  return <span>{calculateTimeDifference(date)}</span>;
};

export default TimeDifference;
