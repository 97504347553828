import { API } from "../../apiEndPoints/website/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../axios/axiosMiddleware";

export const getPiecesList = (queryString: String) => {
  return axiosGet(`${API?.OTHER?.PIECES}/${queryString}`);
};

export const deletePiecesList = (id: number) => {
  return axiosDelete(`${API?.OTHER?.DELETE_FEED}/${id}`);
};

export const editArt = (data: any, id: any) => {
  return axiosPatch(`${API?.OTHER?.GET_ARTROOM_DETAILS}/${id}`, data);
};

export const createPost = (data: any) => {
  return axiosPost(`${API?.OTHER?.CREATE_POST}`, data);
};

export const createPostVideo = (data: any) => {
  return axiosPost(`${API?.OTHER?.CREATE_POST_VIDEO}`, data);
};

export const createArtroomPost = (data: any) => {
  return axiosPost(`${API?.OTHER?.CREATE_ARTROOM_POST}`, data);
};

export const createPostText = (data: any) => {
  return axiosPost(`${API?.OTHER?.CREATE_POST_TEXT}`, data);
};
