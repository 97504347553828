import React, { useEffect, useState } from "react";
import { pathRoute } from "../../../routes/pathRoute";
import Breadcrumb from "../../../components/core/Breadcrumb";
import MasterClassDummy2 from "../../../assets/images/master_class_dummy_2.png";
import { GoClockFill } from "react-icons/go";
import Paragraph from "../../../components/core/typography/Paragraph";
import { Link, useLocation, useParams } from "react-router-dom";
import Art1 from "../../../assets/images/sketch_dummy.png";
import { IoMdPlay } from "react-icons/io";
import { IoLockClosed } from "react-icons/io5";
import Button from "../../../components/core/form-components/Button";
import { getMasterClassListDetails } from "../../../services/website/masterClassService";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { useDispatch } from "react-redux";
import {
  LearningPoint,
  MasterClassDetailsInterface,
  relatedMasterClassDetailsInterface,
} from "../../../interface/MasterClass";

function MasterClassDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [relatedMasterClass, setRelatedMasterClass] = useState<
    relatedMasterClassDetailsInterface[]
  >([]);
  const [masterClassDetails, setMasterClassDetails] = useState<
    MasterClassDetailsInterface | undefined
  >(undefined);

  const fetchMasterClassDetails = async (id: any) => {
    dispatch(showLoader());

    try {
      const response = await getMasterClassListDetails(id);
      if (response?.data?.success) {
        setRelatedMasterClass(response?.data?.relatedMasterClasses);
        setMasterClassDetails(response?.data?.masterClass);
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (id) {
      fetchMasterClassDetails(id);
    }
  }, [id]);

  const formatDuration = (duration: any) => {
    const durationInSeconds = duration;

    const minutes = Math.floor(durationInSeconds / 60);
    const remainingSeconds = durationInSeconds % 60;

    return durationInSeconds > 60
      ? `${minutes} min ${remainingSeconds.toFixed(0)} sec`
      : durationInSeconds + "sec";
  };

  return (
    <div className="grid grid-cols-12 lg:gap-5 md:gap-4 gap-3 py-4 xl:px-10 lg:px-6 md:px-4 px-3">
      <div className="xxl:col-start-2 xxl:col-span-8 xl:col-start-2 xl:col-span-10 col-span-12">
        <div className="border border-medium-grey rounded-[20px] xl:p-30 lg:p-6 md:p-5 p-4 md:flex gap-[30px] mb-5">
          <div className="inline-block overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 flex-shrink-0">
            <img
              src={masterClassDetails?.thumbnail}
              alt="video-thumb"
              width={368}
              height={551}
              className="sm:max-w-[368px] sm:w-[368px] max-w-[280px] w-full aspect-[2/3]"
            />
            <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
              <IoLockClosed color="#fff" size={22} />
            </span>
          </div>
          <div>
            <Paragraph text24 className="mb-4">
              {masterClassDetails?.title}
            </Paragraph>
            <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey md:mb-30 mb-4">
              <GoClockFill color="#4D4B4B" size={18} />
              Class: {formatDuration(masterClassDetails?.videoDuration)}
            </span>
            {masterClassDetails?.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: masterClassDetails?.description || "",
                }}
              />
            )}
            <Button primary className="mt-30 w-full">
              Subscribe to Purchase
            </Button>
          </div>
        </div>
      </div>
      <div className="xxl:col-start-2 xxl:col-span-8 col-span-12">
        <Paragraph text24 className="md:text-left text-center">
          What you’ll learn
        </Paragraph>
      </div>
      <div className="xxl:col-start-2 xxl:col-span-8 md:col-span-12 sm:col-start-3 sm:col-span-8 col-span-12">
        <div className="grid grid-cols-12 md:gap-[18px] gap-3">
          {masterClassDetails?.learningPoints?.map((item: LearningPoint) => {
            return (
              <div className="md:col-span-3 col-span-6" key={item?.id}>
                <div className="relative rounded-xl overflow-hidden block">
                  <img
                    src={item?.imageUrl}
                    alt="popular-art"
                    width={263}
                    height={263}
                    className="w-full h-[245px] object-cover"
                  />
                  <div className="absolute w-full left-0 bottom-0 flex items-center justify-center gap-[6px] px-[10px] py-[11px] bg-gradient-to-r from-red -from-[4.18%] backdrop-blur-[19px] to-transparent to-[104.23%]">
                    <Paragraph text20 className="!text-white !font-normal">
                      {item?.point}
                    </Paragraph>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="xxl:col-start-2 xxl:col-span-8 col-span-12 mt-5">
        <Paragraph text24 className="md:text-left text-center">
          Related Masterclass
        </Paragraph>
      </div>
      <div className="xxl:col-start-2 xxl:col-span-8 md:col-span-12 sm:col-start-3 sm:col-span-8 col-span-12">
        <div className="grid grid-cols-12 md:gap-[18px] gap-3">
          {relatedMasterClass?.map(
            (item: relatedMasterClassDetailsInterface) => {
              return (
                <div className="md:col-span-3 col-span-6">
                  <Link
                    to={`${pathRoute.website.relatedMasterClassDetail}/${item?.id}`}
                    key={item?.id}
                  >
                    <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
                      <img
                        src={item?.thumbnail}
                        alt="video-thumb"
                        width={263}
                        height={394}
                        className="aspect-[2/3]"
                      />

                      <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                        <IoLockClosed color="#fff" size={22} />
                      </span>
                    </div>
                    <Paragraph
                      text20
                      className="!font-medium line-clamp-1 mb-3"
                    >
                      {item?.title}
                    </Paragraph>
                    <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
                      <GoClockFill color="#4D4B4B" size={18} />
                      Class: {item?.videoDuration}
                    </span>
                  </Link>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}

export default MasterClassDetail;
