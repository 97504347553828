import { API } from "../../apiEndPoints/website/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../axios/axiosMiddleware";

export const WebCheckShippingAddress = (data: object, id: number) => {
  return axiosPost(`${API.OTHER.CHECK_SHIPPING_DETAILS}/${id}`, data);
};
