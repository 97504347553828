import { API } from "../../apiEndPoints/website/apiEndPoints";
import {
  axiosDelete,
  axiosPatch,
  axiosPost,
} from "../../axios/axiosMiddleware";

export const LoginUser = (data: any) => {
  return axiosPost(API?.AUTH?.LOGIN, data);
};

export const LogoutApi = () => {
  return axiosDelete(API?.AUTH?.LOGIN);
};

export const ForgotPasswordApi = (data: any) => {
  return axiosPost(API?.AUTH?.FORGOT_PASSWORD, data);
};

export const LoginWIthNumber = (data: any) => {
  return axiosPost(API?.AUTH?.GETOTP, data);
};

export const VerifyOtp = (data: any) => {
  return axiosPost(API?.AUTH?.VERIFY_OTP, data);
};

export const ResendOtp = (data: any) => {
  return axiosPost(API?.AUTH?.RESEND_OTP, data);
};

export const ResetPasswordApi = (params: any, id: any, data: any) => {
  return axiosPatch(`${API.AUTH.FORGOT}/${id}/${params}`, data);
};

export const Register = (data: any) => {
  return axiosPost(API?.AUTH?.REGISTER, data);
};
