import React from "react";
import ArtistOfWeek from "../social/ArtistOfWeek";
import NewFeed from "./NewFeed";
import Pieces from "./Pieces";
import MasterClass from "./MasterClass";
import Opportunities from "./Opportunities";

function SearchList() {
  return (
    <div className="grid grid-cols-12 items-stretch h-full">
      <div className="xxl:col-span-8 md:col-span-7 col-span-12 md:order-1 order-2 xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3 md:border-r md:border-medium-grey ">
        <NewFeed />
        <Pieces />
        <MasterClass />
        <Opportunities />
      </div>
      <div className="xxl:col-span-4 md:col-span-5 col-span-12 md:order-2 order-1">
        <div className="sticky top-0">
          <ArtistOfWeek />
        </div>
      </div>
    </div>
  );
}

export default SearchList;
