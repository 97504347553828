import React, { useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import Checkbox from "../../../components/core/form-components/Checkbox";
import Button from "../../../components/core/form-components/Button";
import uploadImg from "../../../assets/images/icon_image_upload.svg";
import { ReactComponent as IconUpload } from "../../../assets/images/icon_upload.svg";
import { editProfileCoverImage } from "../../../services/website/profileService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";

interface coverInterface {
  handleClosePopup: () => void;
}

function CoverPopup({ handleClosePopup }: coverInterface) {
  const dispatch = useDispatch();
  const handleCoverImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    dispatch(showLoader());
    try {
      if (file) {
        if (file.type.includes("image")) {
          const formData = new FormData();
          formData.append("coverImage", file);
          const response = await editProfileCoverImage(formData);
          if (response?.data?.code === 200) {
            handleClosePopup();
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div>
      <Paragraph text20 className="font-medium mb-2.5">
        Cover image
      </Paragraph>
      <Paragraph text16 className="!text-dark-grey mb-5">
        Upload your cover image wth overlay
      </Paragraph>
      <Paragraph text18 className="font-medium mb-3">
        Add cover image
      </Paragraph>
      <label
        htmlFor="file"
        className="inline-flex items-center gap-1 rounded-3xl bg-light-grey py-1.5 px-3 mb-6"
      >
        <input
          type="file"
          name="file"
          id="file"
          className="sr-only"
          accept="image/*"
          onChange={handleCoverImageChange}
        />
        <IconUpload />
        <span className="text-sm leading-[16px] font-medium">Upload file</span>
      </label>
      <Paragraph text16 className="!text-dark-grey mb-3.5">
        OR
      </Paragraph>
      <Paragraph text18 className="font-medium mb-3">
        Select color
      </Paragraph>
      <div className="flex items-center sm:gap-3 gap-2.5 mt-2.5 mb-30">
        <label
          htmlFor="red"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#E93641]"
        >
          <input type="radio" name="color" id="red" className="sr-only" />
        </label>
        <label
          htmlFor="blue"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#4F83E8]"
        >
          <input type="radio" name="color" id="blue" className="sr-only" />
        </label>
        <label
          htmlFor="green"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#47CF4D]"
        >
          <input type="radio" name="color" id="green" className="sr-only" />
        </label>
        <label
          htmlFor="pink"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#F862C5]"
        >
          <input type="radio" name="color" id="pink" className="sr-only" />
        </label>
        <label
          htmlFor="orange"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#F69809]"
        >
          <input type="radio" name="color" id="orange" className="sr-only" />
        </label>
        <label
          htmlFor="purple"
          className="cursor-pointer sm:w-9 sm:h-9 w-8 h-8 rounded-full border border-medium-grey has-[:checked]:border-site-black bg-[#6D4FE5]"
        >
          <input type="radio" name="color" id="purple" className="sr-only" />
        </label>
      </div>
      <Button className="w-full" primary>
        Save
      </Button>
    </div>
  );
}

export default CoverPopup;
