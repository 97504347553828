import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

// Icon component
interface IconProps {
  isOpen: boolean;
}

const Icon: React.FC<IconProps> = ({ isOpen }) => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="20"
    stroke="#808186"
    strokeWidth="1.5"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={isOpen ? "translate" : ""}
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

// CloseIcon component
const CloseIcon = () => (
  <svg
    viewBox="0 0 24 24"
    width="14"
    height="14"
    stroke="#919297"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

interface Option {
  value: string | number;
  label: string;
  image?: any;
  selected?: boolean;
  labelId?: number;
}

interface SelectTypeProps {
  placeholder?: string;
  error?: string;
  options: Option[];
  isMulti?: boolean;
  isSearchable?: boolean;
  onChange: (value: any) => void;
  sm?: boolean;
  xs?: boolean;
  value?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  fontSize?: string;
  countryDropdown?: boolean;
  onSearchChange?: (data?: any) => void;
}

const SelectType: React.FC<SelectTypeProps> = ({
  placeholder,
  error,
  options,
  isMulti,
  isSearchable,
  onChange,
  sm,
  xs,
  value,
  fullWidth,
  disabled,
  className,
  fontSize,
  countryDropdown,
  onSearchChange,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>(
    value || (isMulti ? [] : placeholder ? null : options[0])
  );
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const measureRef = useRef<HTMLDivElement>(null);
  const [selectWidth, setSelectWidth] = useState<string | number>("auto");

  const [preventClose, setPreventClose] = useState(false);

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        // Delay the menu closing to allow onItemClick to process
        setTimeout(() => {
          setShowMenu(false);
        }, 200);
      }
    };

    window.addEventListener("mousedown", handler);
    return () => {
      window.removeEventListener("mousedown", handler);
    };
  }, [inputRef]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    if (options) {
      const widths = options?.map((option) => {
        if (measureRef.current) {
          measureRef.current.innerText = option?.label;
          return measureRef.current.clientWidth;
        }
        return 0;
      });
      const maxWidth = Math.max(...widths);
      setSelectWidth(maxWidth + 32);
    }
  }, [options]);

  const handleInputClick = () => {
    setShowMenu(!showMenu);
  };
  console.log("value", value, selectedValue);
  const getDisplay = () => {
    if (!selectedValue || selectedValue?.length === 0) {
      const selectedOption = options?.find((option) => option?.selected);

      if (countryDropdown) {
        return (
          <div className="truncate">
            {selectedOption ? (
              <div className="flex items-center gap-[10px]">
                <img
                  src={selectedOption?.image}
                  alt={selectedOption.label}
                  width={24}
                  height={24}
                />
                <p
                  className={`text-base leading-4 text-site-black ${
                    countryDropdown && "capitalize"
                  }`}
                >
                  {selectedOption.value}
                </p>
              </div>
            ) : (
              options[0]?.label
            )}
          </div>
        );
      } else {
        return placeholder || options[0]?.label;
      }
    }

    if (isMulti) {
      return (
        <div className="flex flex-wrap gap-1">
          {selectedValue?.map((option: Option, index: number) => (
            <div
              key={`${option?.value}-${index}`}
              className={`bg-light-grey text-site-black font-medium text-xs px-1 rounded-md flex items-center gap-1 py-1`}
            >
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="flex item-center"
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className="truncate">
        {countryDropdown && (
          <div className="flex items-center gap-[10px] capitalize">
            <img
              src={selectedValue?.image}
              alt={selectedValue.label}
              width={24}
              height={24}
            />
            {selectedValue?.value}
          </div>
        )}
        {!countryDropdown && selectedValue.label}
      </div>
    );
  };

  const removeOption = (option: Option) =>
    selectedValue?.filter((o: Option) => o.value !== option.value);

  const onTagRemove = (e: React.MouseEvent, option: Option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option: Option, e: React.MouseEvent) => {
    e.stopPropagation();
    setPreventClose(true); // Set flag to prevent closing
    let newValue;
    if (isMulti) {
      if (
        selectedValue?.findIndex((o: Option) => o.value === option.value) >= 0
      ) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
    setTimeout(() => {
      if (!preventClose) {
        setShowMenu(false);
      }
    }, 0);
  };

  useEffect(() => {
    if (preventClose) {
      setPreventClose(false); // Reset flag after handling
    }
  }, [showMenu]);

  const isSelected = (option: Option) => {
    if (isMulti) {
      return (
        selectedValue?.filter((o: Option) => o.value === option.value).length >
        0
      );
    }

    if (!selectedValue) {
      return false;
    }
    return selectedValue?.value === option?.value;
  };
  let timeoutId: NodeJS.Timeout;
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);

    clearTimeout(timeoutId);

    // Set a new timeout to trigger the debounced function
    timeoutId = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(e.target.value || ""); // Pass the search value to the parent
      }
    }, 500); // Adjust debounce delay as needed
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options?.filter(
      (option) =>
        option?.label?.toLowerCase()?.indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div
      className={`relative cursor-pointer flex-shrink-0 ${
        fullWidth ? "!w-full" : countryDropdown ? "!w-auto" : ""
      }`}
      style={{
        width: (typeof selectWidth === "number" ? selectWidth : 0) + 12,
      }}
    >
      <div
        ref={inputRef}
        onClick={handleInputClick}
        className={`${
          sm
            ? "xxl:py-[7px] py-[10px] px-4"
            : xs
            ? "xxl:py-[3px] py-[5px] px-[10px]"
            : "lg:py-[13px] py-[11px] px-4"
        } flex items-center justify-between  border border-medium-grey rounded-lg ${
          countryDropdown ? "gap-0" : "gap-1"
        } ${className ? className : ""} ${
          disabled ? "pointer-events-none" : ""
        }`}
      >
        <div
          className={`truncate text-base leading-5  ${
            !selectedValue || selectedValue?.length === 0
              ? "placeholder text-site-grey"
              : "text-site-black"
          } ${fontSize ? fontSize : ""}`}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {getDisplay()}
        </div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            {countryDropdown && (
              <IoIosArrowDown
                size={20}
                color={"#808186"}
                className={`transition-all ${showMenu ? "rotate-180" : ""}`}
              />
            )}
            {!countryDropdown && (
              <IoIosArrowDown
                size={20}
                color={"#808186"}
                className={`transition-all ${showMenu ? "rotate-180" : ""}`}
              />
            )}
          </div>
        </div>
      </div>

      {showMenu && (
        <div
          className={`${
            countryDropdown ? "w-[140px]" : "w-full"
          } pt-3 pb-2 absolute translate-y-[6px] rounded-md overflow-auto bg-white z-[10] max-h-[300px] min-h-[50px] shadow-dropdownShadow `}
        >
          {isSearchable && (
            <div className="px-3 pb-[8px]">
              <input
                className={`w-full border rounded-lg border-medium-grey py-[6px] ps-[40px] pe-3 focus:outline-0 placeholder:text-site-grey placeholder:text-sm bg-search-icon-grey bg-no-repeat bg-[left_12px_top_6px] `}
                onChange={onSearch}
                value={searchValue}
                ref={searchRef}
                type="search"
                placeholder="Search here"
              />
            </div>
          )}
          {getOptions()?.map((option) => (
            <div
              onClick={(e) => onItemClick(option, e)}
              key={option?.value}
              className={`px-3 py-[6px] mb-[2px] cursor-pointer transition-all duration-300 ease ${
                countryDropdown ? "text-base" : "text-sm"
              } font-normal text-site-black hover:bg-[#FBF0D0] hover:text-site-black ${
                isSelected(option) && "bg-[#FBF0D0] text-site-black"
              }`}
            >
              {countryDropdown && (
                <div className="flex items-center gap-[10px]">
                  <img
                    src={option?.image}
                    alt={option.label}
                    width={24}
                    height={24}
                  />
                  {option.label}
                </div>
              )}
              {!countryDropdown && option.label}
            </div>
          ))}
        </div>
      )}
      <div
        ref={measureRef}
        className="absolute invisible whitespace-nowrap px-3 py-[6px] font-semibold text-sm"
      ></div>
      {error && (
        <div className="text-site-red text-sm font-medium">{error}</div>
      )}
    </div>
  );
};

export default SelectType;
