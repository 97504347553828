import React from "react";
import DummyQR from "../../../assets/images/dummy_qr.svg";
import Paragraph from "../../../components/core/typography/Paragraph";
function QRPopup() {
  return (
    <div className="text-center">
      <img
        src={DummyQR}
        alt="dummy-qr"
        width={300}
        height={300}
        className="inline-block mb-[18px]"
      />
      <Paragraph text24 className="">
        @Alena Gilbert
      </Paragraph>
    </div>
  );
}

export default QRPopup;
