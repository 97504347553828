import { API } from "../../apiEndPoints/website/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../axios/axiosMiddleware";

export const getProfileInfo = () => {
  return axiosGet(API?.OTHER?.GET_PROFILE_DETAILS);
};

export const getWebsiteArtRoomInfo = (id: number, query: any) => {
  return axiosGet(`${API?.OTHER?.GET_ARTROOM_INFO}/${id}/publications${query}`);
};

export const getWebsiteArtRoomDetails = (id: number) => {
  return axiosGet(`${API.OTHER.GET_ARTROOM_DETAILS}/${id}`);
};
export const getWebsiteRepostDetails = (id: number, query: any) => {
  return axiosGet(`${API.OTHER.GET_REPOST_DETAILS}/${id}${query}`);
};

export const getEventInfo = (id: number, queryString: String) => {
  return axiosGet(`${API?.OTHER?.GET_EVENT}/${id}/publications${queryString}`);
};

export const editProfile = (data: any) => {
  return axiosPatch(`${API?.OTHER?.GET_PROFILE_DETAILS}`, data);
};

export const editProfileCoverImage = (data: any) => {
  return axiosPatch(`${API?.OTHER?.GET_PROFILE_DETAILS}/cover-image`, data);
};

export const editProfileImage = (data: any) => {
  return axiosPatch(`${API?.OTHER?.GET_PROFILE_DETAILS}`, data);
};
