import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";
import PaymentSuccess from "./PaymentSuccess";
import Modal from "../../../components/core/Modal";
import {
  discardPaymentInfo,
  paymetInfoSelector,
} from "../../../redux/slices/paymentInfo";
import { formatStringWithPrice } from "../../../components/core/form-components/FormatString";
import {
  isConfirmServiceSelector,
  notConfirmService,
} from "../../../redux/slices/isConfirmServicesSlice";
import { isPaymentSuccess } from "../../../services/checkoutService";
import {
  discardOtherServices,
  servicesSelector,
} from "../../../redux/slices/otherServices";
import { ArtDetails } from "../../../interface/ArtDetails";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { useTranslation } from "react-i18next";

interface images {
  imageUrl: string;
}

interface PaymentInterface {
  artDetails: ArtDetails;
  values: any;
  currentStep: number;
}
const PaymentDetailsCheckout = ({
  artDetails,
  currentStep,
  values,
}: PaymentInterface) => {
  const [open, setOpen] = useState(false);
  const recapShipment = useSelector(paymetInfoSelector);
  const isConfirm = useSelector(isConfirmServiceSelector);
  const services = useSelector(servicesSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openPopup = () => {
    setOpen(!open);
  };
  const closePopup = () => {
    setOpen(false);
  };

  const handleProceedCheckout = async () => {
    dispatch(showLoader());
    let data = {
      paymentMethod: values?.paymentMethodId,
      // saveCard: false,
      billingAddress: values?.billing_address,
      billingZipCode: values?.billing_zip,
      billingCity: values?.billing_city,
      billingCountry: values?.blilling_country,
      shippingAddress: values?.shipping_address,
      shippingZipCode: values?.shipping_zip,
      shippingCity: values?.shipping_city,
      shippingCountry: values?.shiiping_country,
      buyerCityId: services?.buyerCityId,
      sellerCityId: services?.sellerCityId,
      serviceCode:
        services?.transportServices?.computedServicesPrices[0]?.serviceCode,
      artId: values?.id,
      insurance: values?.insurance,
      name: values?.name,
      email: values?.email,
      phoneNb: values?.phone_number.toString(),
      countryCode: values?.phone_country_code,
    };
    const response = await isPaymentSuccess(data);
    if (response?.data?.code === 201) {
      setOpen(!open);
      dispatch(notConfirmService());
      dispatch(discardPaymentInfo());
      dispatch(discardOtherServices());
    }
    dispatch(hideLoader()); // Hide loader
  };

  return (
    <>
      <div className="lg:col-span-4 col-span-12">
        <div className="bg-light-grey md:p-[30px] p-4 rounded-lg  md:sticky top-[100px]">
          <Paragraph text20 className="font-medium mb-4">
            Item
          </Paragraph>
          <div className="flex items-start gap-4 pb-5 mb-5 border-b border-medium-grey">
            <div className="xl:w-[100px] xl:h-[100px] md:w-[70px] md:h-[70px] w-[60px] h-[60px] xl:rounded-[20px] rounded-xl bg-medium-grey overflow-hidden flex-shrink-0">
              {artDetails?.video ? (
                <img
                  src={artDetails.video?.imageUrl}
                  alt="video-thumbnail"
                  className="w-full h-full object-cover"
                />
              ) : (
                <img
                  src={artDetails?.images && artDetails?.images[0]?.imageUrl}
                  alt="artt-imgg"
                  className="w-full h-full object-cover"
                />
              )}
            </div>
            <div>
              <Paragraph text18 className="">
                {artDetails?.title}
              </Paragraph>
              <Paragraph text18 className="">
                {t("profile.art")} | {artDetails?.width}*{artDetails?.height}cm
              </Paragraph>
            </div>
          </div>
          <ul>
            <li className="flex items-center justify-between gap-3 mb-2">
              <Paragraph text18 className="font-normal">
                {t("profile.price")}
              </Paragraph>
              <Paragraph text18 className="font-normal">
                {formatStringWithPrice((artDetails?.price ?? 0).toFixed(3))}
              </Paragraph>
            </li>
            {currentStep === 3 && isConfirm && (
              <>
                <li className="flex items-center justify-between gap-3 mb-2">
                  <Paragraph text18 className="font-normal">
                    {t("checkout.shipment_fee")}
                  </Paragraph>
                  <Paragraph text18 className="font-normal">
                    {recapShipment?.transport?.computedPrices &&
                    recapShipment?.transport?.computedPrices?.length > 0
                      ? formatStringWithPrice(
                          (
                            (recapShipment?.transport
                              ?.sellTotalPriceWithTaxes ?? 0) -
                            (recapShipment?.transport?.computedPrices[0]
                              ?.subServicesPricings[0]?.sellPriceWithTaxes ?? 0)
                          ).toFixed(3)
                        )
                      : ""}
                  </Paragraph>
                </li>
                {values?.insurance && (
                  <li className="flex items-center justify-between gap-3 mb-2">
                    <Paragraph text18 className="font-normal">
                      {t("checkout.insurance_fee")}
                    </Paragraph>
                    <Paragraph text18 className="font-normal">
                      {formatStringWithPrice(
                        (
                          recapShipment?.transport?.computedPrices[0]
                            ?.subServicesPricings[0]?.sellPriceWithTaxes ?? 0
                        ).toFixed(3) // Provide fallback value of 0
                      )}
                    </Paragraph>
                  </li>
                )}
                <li className="flex items-center justify-between gap-3 mb-2">
                  <Paragraph text18 className="font-normal">
                    {t("checkout.service_fee")}
                  </Paragraph>
                  <Paragraph text18 className="font-normal">
                    {formatStringWithPrice(
                      (recapShipment?.artgapiFees ?? 0).toFixed(3)
                    )}
                  </Paragraph>
                </li>
              </>
            )}

            {/* Different for last child maintain this in loop */}
            <li className="flex items-center justify-between gap-3 mt-2 mb-4">
              <Paragraph text18>Total</Paragraph>
              <Paragraph text18>
                {currentStep === 3 && isConfirm
                  ? formatStringWithPrice(
                      (recapShipment?.total ?? 0).toFixed(3)
                    )
                  : formatStringWithPrice((artDetails?.price ?? 0).toFixed(3))}
              </Paragraph>
            </li>
          </ul>
          <Button
            primary={isConfirm ? true : false}
            disabled={!isConfirm}
            className="w-full"
            onClick={handleProceedCheckout}
          >
            {t("checkout.proceed_checkout")}
          </Button>
          <Modal open={open} width={"md:w-[424px]"}>
            <PaymentSuccess />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default PaymentDetailsCheckout;
