import React from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import { useSelector } from "react-redux";
import { languageIdSelector } from "../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../components/language-translation/LanguageTranslation";
import { NavLink } from "react-router-dom";
import { pathRoute } from "../../../routes/pathRoute";
import {
  orderSidebarItems,
  settingsSidebarItems,
} from "../../../constant/constant";
import { tokenSelector } from "../../../redux/slices/userSlice";

function AccountSidebar() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const token = useSelector(tokenSelector);
  const visibleOrderSidebarItems = orderSidebarItems.filter(
    (item) => !item.requireAuth || (item.requireAuth && token)
  );
  const visibleSettingsSidebarItems = settingsSidebarItems.filter(
    (item) => !item.requireAuth || (item.requireAuth && token)
  );
  return (
    <div className="">
      <div
        className={`  ${token ? "p-4 pt-0 border-b border-b-medium-grey" : ""}`}
      >
        {token && (
          <Paragraph text18 className="mb-3">
            {LanguageTranslation({
              labelName: "orders",
              languageCode: langId || "fr",
            }) || "Orders"}
          </Paragraph>
        )}

        <ul className="">
          {visibleOrderSidebarItems?.map((item) => (
            <li
              className={`transition-all ease-in-out delay-700 duration-500 block`}
            >
              <NavLink
                to={item?.href}
                className={({ isActive }) =>
                  `flex items-center gap-[10px] p-[6px] mb-3 rounded-[20px] transition-all ease-in-out duration-500 ${
                    isActive
                      ? "bg-light-grey hover:bg-light-grey"
                      : "bg-white hover:bg-light-grey"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <item.icon />

                    <span
                      className={`text-[18px] leading-[17px] font-normal text-site-black`}
                    >
                      {LanguageTranslation({
                        labelName: item.labelName,
                        languageCode: langId || "fr",
                      }) || item.name}
                    </span>
                  </>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="p-4">
        <Paragraph text18 className="mb-3">
          {LanguageTranslation({
            labelName: "web_settings",
            languageCode: langId || "fr",
          }) || "Web Settings"}
        </Paragraph>
        <ul className="">
          {visibleSettingsSidebarItems?.map((item) => (
            <li
              className={`transition-all ease-in-out delay-700 duration-500 block`}
            >
              <NavLink
                to={item?.href}
                className={({ isActive }) =>
                  `flex items-center gap-[10px] p-[6px] mb-3 rounded-[20px] transition-all ease-in-out duration-500 ${
                    isActive
                      ? "bg-light-grey hover:bg-light-grey"
                      : "bg-white hover:bg-light-grey"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <item.icon />

                    <span
                      className={`text-[18px] leading-[17px] font-normal text-site-black`}
                    >
                      {LanguageTranslation({
                        labelName: item.labelName,
                        languageCode: langId || "fr",
                      }) || item.name}
                    </span>
                  </>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AccountSidebar;
