import React, { useState } from "react";
import Paragraph from "../core/typography/Paragraph";

interface postInterface {
  description: string;
}

const PostDescription = ({ description }: postInterface) => {
  const maxCharacters = 150; // Max characters for truncated text
  const [showMore, setShowMore] = useState(false);
  const truncatedText = description?.slice(0, maxCharacters);
  const shouldTruncate = description?.length > maxCharacters;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Paragraph text14 className="!text-site-black !font-normal md:mt-5 mt-4">
      <span>
        {showMore || !shouldTruncate ? description : truncatedText}
        {shouldTruncate && (
          <button
            onClick={toggleShowMore}
            className="text-site-black font-medium  ml-1 inline"
            style={{ cursor: "pointer" }}
          >
            {showMore ? "Read less" : "Read more..."}
          </button>
        )}
      </span>
    </Paragraph>
  );
};

export default PostDescription;
