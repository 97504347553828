import React, { useState } from "react";
import { LuZoomIn } from "react-icons/lu";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";

const PostZoomInOut = ({ images, feedData }: any) => {
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);
  const [imageLightboxOpen, setImageLightboxOpen] = useState(false);

  const openImgLightbox = (imgUrl: string) => {
    setImgUrl(imgUrl);
    setImageLightboxOpen(true);
  };

  const closeImgLightbox = () => {
    setImageLightboxOpen(false);
    setImgUrl(undefined);
  };

  return (
    <>
      <div className="absolute right-0 top-0 inline-flex items-center justify-center bg-white w-[60px] rounded-3xl py-1.5 px-3.5 cursor-pointer">
        <LuZoomIn
          size={22}
          color="#FFD45A"
          onClick={() => {
            const imageUrl = images;
            if (imageUrl) {
              openImgLightbox(imageUrl);
            }
          }}
        />
      </div>
      {imageLightboxOpen && (
        <div className={feedData?.length === 1 ? "lightbox-no-navigation" : ""}>
          <Lightbox
            open={imageLightboxOpen}
            close={closeImgLightbox}
            slides={[
              {
                type: "image",
                src: imgUrl || "",
              },
            ]}
            carousel={{
              finite: true,
              padding: 0,
            }}
          />
        </div>
      )}
    </>
  );
};

export default PostZoomInOut;
