import React, { useEffect, useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import SwitchToggle from "../../../components/core/form-components/SwitchToggle";
import { Link, useNavigate } from "react-router-dom";
import { ArtistWeekInterface } from "../../../interface/ArtistWeek";
import ImageWithDefault from "../../../components/web-component/DefaultImage";
import { pathRoute } from "../../../routes/pathRoute";

function ArtistOfWeek({ artistData }: any) {
  const navigate = useNavigate();

  const handleDetailPage = (id: number) => {
    navigate(`${pathRoute.website.userProfile}/${id}`);
  };

  return (
    <div className="xl:pb-6 pb-4 md:pb-4 xl:px-10 lg:px-5 md:px-4 px-3 xl:pe-30">
      <div className="md:border md:border-medium-grey rounded-2xl xl:p-6 p-3 xxl:max-w-[390px]">
        <div className="flex items-center justify-between gap-2 lg:mb-6 md:mb-5 mb-4 flex-wrap">
          <Paragraph text24 className="">
            Artist of the week
          </Paragraph>
          {/* <Link
            to={""}
            className="flex items-center gap-1.5 text-site-black font-medium"
          >
            <span className="xxl:text-base text-sm leading-[20px]">
              View More
            </span>{" "}
            <MdKeyboardArrowRight size={22} className="mt-[-2px]" />
          </Link> */}
        </div>
        <ul className="hidden md:block">
          {artistData?.map((item: ArtistWeekInterface, index: number) => {
            const isLastItem = index === artistData.length - 1;

            return (
              <li
                onClick={() => handleDetailPage(item?.id || 0)}
                className={`flex cursor-pointer items-center gap-2.5 pb-3 border-b border-light-grey mb-3 ${
                  isLastItem ? "mb-0 border-b-0" : ""
                }`}
              >
                <div className="relative cursor-pointer">
                  <ImageWithDefault
                    src={item?.avatar}
                    alt="user-profile"
                    width={60}
                    height={60}
                    className="rounded-md w-full h-full object-cover max-w-[60px] flex-shrink-0"
                  />
                </div>
                <span className="block">
                  <div className="text-base leading-5">@{item?.nickname}</div>
                  {/* <SwitchToggle withInnerText /> */}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default ArtistOfWeek;
