import { API } from "../../apiEndPoints/website/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../axios/axiosMiddleware";

export const changePassword = (data: any) => {
  return axiosPatch(`${API?.OTHER?.CHANGE_PASSWORD}`, data);
};

export const getPurchaseList = (queryString: any) => {
  return axiosGet(`${API?.OTHER?.PURCHASE_LIST}${queryString}`);
};

export const getSalesList = (queryString: any) => {
  return axiosGet(`${API?.OTHER?.SALES_LIST}${queryString}`);
};

export const getMasterClassSubscriptions = (id: number) => {
  return axiosGet(`${API?.OTHER?.MASTER_CLASS_LIST}/${id}`);
};

export const getTransactionsList = (queryString: any) => {
  return axiosGet(`${API?.OTHER?.TRANSACTION_LIST}${queryString}`);
};

export const addNotificationPreferences = (data: any) => {
  return axiosPatch(`${API?.OTHER?.NOTIFICATION_PREF}`, data);
};

export const reportABug = (data: any) => {
  return axiosPost(`${API?.OTHER?.REPORT_BUG}`, data);
};
