import { API } from "../../apiEndPoints/website/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../axios/axiosMiddleware";

export const getArtsOfTheWeek = () => {
  return axiosGet(API?.OTHER?.GET_ARTS_WEEKS);
};

export const getSocialArtworks = () => {
  return axiosGet(API?.OTHER?.GET_ARTS_WEEKS);
};

export const getArtistsOfTheWeek = () => {
  return axiosGet(API?.OTHER?.GET_ARTISTS_WEEKS);
};

export const getDiscoverPosts = (queryString: string) => {
  return axiosGet(`${API?.OTHER?.GET_HOME_POSTS}/${queryString}`);
};

export const getAllStartData = () => {
  return axiosGet(`${API?.OTHER?.START}`);
};

export const getOpportunitiesList = (queryString: string) => {
  return axiosGet(`${API?.OTHER?.OPPORTUNITIES}/${queryString}`);
};

export const applyOpportunity = (id: number) => {
  return axiosGet(`${API?.OTHER?.APPLY_OPPORTUNITY}/${id}`);
};
