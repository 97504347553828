import React, { useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import SelectType from "../../../components/core/form-components/SelectType";
import RadioButton from "../../../components/core/form-components/RadioButton";
import Checkbox from "../../../components/core/form-components/Checkbox";
import SwitchToggle from "../../../components/core/form-components/SwitchToggle";
import { IoIosArrowDown } from "react-icons/io";

function SearchFilter() {
  const [openSections, setOpenSections] = useState({
    artag: true,
    category: true,
    colourimetry: true,
    price: true,
  });

  const toggleSection = (section: keyof typeof openSections) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const artTagOptions = [
    {
      label: "Tag 1",
      value: "tag-1",
    },
    {
      label: "Tag 2",
      value: "tag-2",
    },
  ];

  const handleSelect = () => {
    console.log("select");
  };

  return (
    <div>
      {/* Artag Section */}
      <div className="border-b border-b-light-grey mb-4">
        <div
          className="cursor-pointer flex items-center justify-between gap-3 mb-3"
          onClick={() => toggleSection("artag")}
        >
          <Paragraph text16 className="font-medium">
            Artag
          </Paragraph>
          <IoIosArrowDown
            color="#808186"
            size={20}
            className={`transition-transform duration-300 ${
              openSections.artag ? "rotate-180" : ""
            }`}
          />
        </div>
        {openSections.artag && (
          <div className="pb-4">
            <SelectType
              options={artTagOptions}
              onChange={handleSelect}
              isSearchable
              fullWidth
              placeholder="Select tag "
            />
          </div>
        )}
      </div>

      {/* Category Section */}
      <div className="border-b border-b-light-grey mb-4">
        <div
          className="cursor-pointer flex items-center justify-between gap-3 mb-3"
          onClick={() => toggleSection("category")}
        >
          <Paragraph text16 className="font-medium">
            Category
          </Paragraph>
          <IoIosArrowDown
            color="#808186"
            size={20}
            className={`transition-transform duration-300 ${
              openSections.category ? "rotate-180" : ""
            }`}
          />
        </div>
        {openSections.category && (
          <ul className="flex flex-col gap-3 pb-4">
            <li className="flex">
              <RadioButton name="category" id="numerique">
                Art numerique
              </RadioButton>
            </li>
            <li className="flex">
              <RadioButton name="category" id="autre">
                Autre
              </RadioButton>
            </li>
          </ul>
        )}
      </div>

      {/* Colourimetry Section */}
      <div className="border-b border-b-light-grey mb-4">
        <div
          className="cursor-pointer flex items-center justify-between gap-3 mb-3"
          onClick={() => toggleSection("colourimetry")}
        >
          <Paragraph text16 className="font-medium ">
            Colourimetry
          </Paragraph>
          <IoIosArrowDown
            color="#808186"
            size={20}
            className={`transition-transform duration-300 ${
              openSections.colourimetry ? "rotate-180" : ""
            }`}
          />
        </div>
        {openSections.colourimetry && (
          <div className="pb-4">
            <Paragraph text14 className="!font-normal !text-dark-grey mb-4">
              Note: You can select a maximum of two colors.
            </Paragraph>
            <ul className="flex flex-col gap-3 mb-4">
              <li className="flex">
                <Checkbox name="colourimetry" id="black" w18>
                  <div className="flex items-center gap-2">
                    <span className="w-5 h-5 rounded-full block bg-black"></span>{" "}
                    Black
                  </div>
                </Checkbox>
              </li>
              <li className="flex">
                <Checkbox name="colourimetry" id="blue" w18>
                  <div className="flex items-center gap-2">
                    <span className="w-5 h-5 rounded-full block bg-[#4F83E8]"></span>{" "}
                    Blue
                  </div>
                </Checkbox>
              </li>
            </ul>
            <span className="text-site-red text-sm leading-[14px]">
              +5 More
            </span>
          </div>
        )}
      </div>

      {/* Price Section */}
      <div className="border-b border-b-light-grey mb-4">
        <div
          className="cursor-pointer flex items-center justify-between gap-3 mb-3"
          onClick={() => toggleSection("price")}
        >
          <Paragraph text16 className="font-medium">
            Price
          </Paragraph>
          <IoIosArrowDown
            color="#808186"
            size={20}
            className={`transition-transform duration-300 ${
              openSections.price ? "rotate-180" : ""
            }`}
          />
        </div>
        {openSections.price && (
          <div className="pb-4">
            <Paragraph>Price Range Component</Paragraph>
          </div>
        )}
      </div>

      {/* Framed Artwork Section */}
      <div className="pb-4 flex items-center gap-3 justify-between">
        <Paragraph text16 className="font-medium">
          Framed artwork
        </Paragraph>
        <SwitchToggle isChecked></SwitchToggle>
      </div>
    </div>
  );
}

export default SearchFilter;
