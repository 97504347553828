import React from "react";
import MasterClassDummy1 from "../../../assets/images/master_class_dummy_1.png";
import MasterClassDummy2 from "../../../assets/images/master_class_dummy_2.png";
import { IoMdPlay } from "react-icons/io";
import { Link } from "react-router-dom";
import Paragraph from "../../../components/core/typography/Paragraph";
import { GoClockFill } from "react-icons/go";
import { pathRoute } from "../../../routes/pathRoute";
import { MdKeyboardArrowRight } from "react-icons/md";

function MasterClass() {
  return (
    <div className="pb-6 mb-6 border-b border-medium-grey">
      <div className="flex items-center justify-between gap-3 mb-5">
        <Paragraph text24 className="font-semibold ">
          Masterclass
        </Paragraph>
        <Link
          to={""}
          className="flex items-center gap-1.5 text-site-black font-medium"
        >
          <span className="text-base leading-[20px]">View More</span>{" "}
          <MdKeyboardArrowRight size={22} className="mt-[-2px]" />
        </Link>
      </div>
      <div className="grid xxl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 grid-cols-2 gap-3">
        <div className="col-span-1">
          <Link to={pathRoute.website.masterClassDetail}>
            <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
              <img
                src={MasterClassDummy1}
                alt="video-thumb"
                width={263}
                height={394}
                className="w-full"
              />

              <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                <IoMdPlay color="#fff" size={22} />
              </span>
            </div>
            <Paragraph text20 className="!font-medium line-clamp-1 mb-3">
              Comprendre ses besoins Comprendre ses besoins
            </Paragraph>
            <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
              <GoClockFill color="#4D4B4B" size={18} />
              Class: 35 min
            </span>
          </Link>
        </div>
        <div className="col-span-1">
          <Link to={pathRoute.website.masterClassDetail}>
            <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
              <img
                src={MasterClassDummy2}
                alt="video-thumb"
                width={263}
                height={394}
                className="w-full"
              />

              <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                <IoMdPlay color="#fff" size={22} />
              </span>
            </div>
            <Paragraph text20 className="!font-medium line-clamp-1 mb-3">
              Comprendre ses besoins Comprendre ses besoins
            </Paragraph>
            <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
              <GoClockFill color="#4D4B4B" size={18} />
              Class: 35 min
            </span>
          </Link>
        </div>
        <div className="col-span-1">
          <Link to={pathRoute.website.masterClassDetail}>
            <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
              <img
                src={MasterClassDummy2}
                alt="video-thumb"
                width={263}
                height={394}
                className="w-full"
              />

              <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                <IoMdPlay color="#fff" size={22} />
              </span>
            </div>
            <Paragraph text20 className="!font-medium line-clamp-1 mb-3">
              Comprendre ses besoins Comprendre ses besoins
            </Paragraph>
            <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
              <GoClockFill color="#4D4B4B" size={18} />
              Class: 35 min
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MasterClass;
