import React, { useEffect, useState } from "react";
import NoDataFound from "../../../../assets/images/empty_artroom.svg";
import { useDispatch, useSelector } from "react-redux";
import { FaPlayCircle } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArtRoomInterface } from "../../../../interface/Artroom";
import {
  tokenSelector,
  userSelector,
} from "../../../../redux/slices/userSlice";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../../redux/slices/siteLoaderSlice";
import { getWebsiteArtRoomInfo } from "../../../../services/website/profileService";
import { pathRoute } from "../../../../routes/pathRoute";
import Paragraph from "../../../../components/core/typography/Paragraph";
import ImageWithDefault from "../../../../components/web-component/DefaultImage";
import { ProductStatus } from "../../../../constant/constant";
import Button from "../../../../components/core/form-components/Button";

function ProfileArtRoom() {
  const [artRoomData, setArtRoomData] = useState<ArtRoomInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imageLightboxOpen, setImageLightboxOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);
  const { t } = useTranslation();
  const webProfileData = useSelector(userSelector);
  const { id } = useParams();
  const [isUserId, setIsUserId] = useState("");
  const isLoading = useSelector(siteLoaderSelector); // Select the loading state from Redux
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (id) {
      setIsUserId(id);
    } else {
      setIsUserId(webProfileData?.id);
    }
  }, [id]);

  const fetchArtroomForWebsite = async (page: number, id: any) => {
    let queryString = `?type=0&filter=0&page=${page}&limit=10&keyword=`;
    dispatch(showLoader());
    try {
      const response = await getWebsiteArtRoomInfo(id, queryString);
      const count = response?.data?.total || 0;
      if (response?.data?.publications) {
        setArtRoomData((prevData) => [
          ...prevData,
          ...response.data.publications,
        ]);
        setTotalItems(count);
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (isUserId) {
      fetchArtroomForWebsite(currentPage, isUserId);
    }
  }, [currentPage, isUserId]);

  const handleBuyNowClick = (item: any) => {
    navigate(`${pathRoute.website.artDetail}/${item?.art?.id}`, {
      state: { isWebsite: true, userId: webProfileData?.id },
    });
  };

  const openLightbox = (videoUrl: string) => {
    console.log("Opening lightbox with video URL:", videoUrl);
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const openImgLightbox = (imgUrl: string) => {
    setImgUrl(imgUrl);
    setImageLightboxOpen(true);
  };

  const closeImgLightbox = () => {
    setImageLightboxOpen(false);
    setImgUrl(undefined);
  };

  return (
    <>
      {artRoomData?.length === 0 && !isLoading ? (
        <div className="break-inside-avoid text-center">
          <img
            src={NoDataFound}
            alt="no-data-found"
            className="mb-6 inline-block"
            width={"250px"}
            height={"142px"}
          />
          <Paragraph text18 className="!font-medium">
            No result found
          </Paragraph>
        </div>
      ) : (
        <div
          className={"px-4"}
          id="scrollableDiv"
          style={{ overflowY: "auto", maxHeight: "80vh", height: "100%" }}
        >
          <InfiniteScroll
            dataLength={artRoomData?.length}
            next={() => setCurrentPage((prevPage) => prevPage + 1)}
            hasMore={artRoomData?.length < totalItems}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            <div
              className={`${
                artRoomData?.length === 0 ? "sm:columns-1" : "sm:columns-2"
              } lg:gap-6 sm:gap-4 gap-3`}
            >
              {artRoomData?.map((item: ArtRoomInterface) => (
                <div className="break-inside-avoid" key={item.id}>
                  <div className="relative pb-6 group">
                    <div className="block relative overflow-hidden rounded-[20px] before:bg-black/25 before:absolute before:w-full before:h-full before:left-0 before:top-0 before:transition-all before:duration-700 before:ease-in-out before:opacity-0 group-hover:before:opacity-100 mb-3">
                      {item?.art?.video ? (
                        <div className="relative">
                          {item?.art?.quantity === 0 ? (
                            <div className="before:absolute before:w-full before:h-full before:bg-site-black/60 before:left-0 before:top-0 before:z-[1]">
                              <ImageWithDefault
                                src={item?.art.video?.imageUrl}
                                alt="video-thumbnail"
                                width={340}
                                height={340}
                                className="max-h-[390px] w-full rounded-[20px] bg-light-grey cursor-default"
                              />
                              <Paragraph
                                text24
                                className="text-white text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[1] font-normal "
                              >
                                {t("profile.out_of_Stock")}
                              </Paragraph>
                            </div>
                          ) : (
                            <>
                              <ImageWithDefault
                                src={item?.art.video?.imageUrl}
                                alt="video-thumbnail"
                                width={340}
                                height={340}
                                className="max-h-[390px] w-full rounded-[20px] bg-light-grey cursor-default"
                              />
                              <span className="absolute inset-0 flex items-center justify-center ">
                                <FaPlayCircle
                                  size={40}
                                  className="text-base cursor-pointer"
                                  onClick={() =>
                                    openLightbox(item?.art?.video?.videoUrl)
                                  }
                                />
                              </span>
                            </>
                          )}
                        </div>
                      ) : item?.art?.quantity !== 0 ? (
                        <ImageWithDefault
                          src={
                            (item?.art?.images &&
                              item?.art?.images[0]?.imageUrl) ||
                            ""
                          }
                          width={340}
                          height={340}
                          className="cursor-pointer relative"
                          alt="feed-imgg"
                          onClick={() => {
                            const imageUrl =
                              item?.art?.images &&
                              item?.art?.images[0]?.imageUrl;
                            console.log("Image URL:", imageUrl); // Debugging line
                            if (imageUrl) {
                              openImgLightbox(imageUrl);
                            }
                          }}
                        />
                      ) : (
                        <div className="before:absolute before:w-full before:h-full before:bg-site-black/60 before:left-0 before:top-0 before:z-[1]">
                          <img
                            src={
                              item?.art?.images &&
                              item?.art?.images[0]?.imageUrl
                            }
                            alt="feed-imgg"
                            onClick={() => {
                              const imageUrl =
                                item?.art?.images &&
                                item?.art?.images[0]?.imageUrl;
                              console.log("Image URL:", imageUrl); // Debugging line
                              if (imageUrl) {
                                openImgLightbox(imageUrl);
                              }
                            }}
                            className="cursor-pointer relative"
                            width={"340px"}
                            height={"340px"}
                          />
                          <Paragraph
                            text24
                            className="text-white text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[1] font-normal "
                          >
                            {"Out of stock"}
                          </Paragraph>
                        </div>
                      )}

                      <div
                        className={`p-[10px] absolute w-full bottom-0 opacity-0 transition-all duration-700 group-hover:opacity-100 pointer-events-auto ${
                          item?.art?.quantity === 0 ? "z-[2]" : ""
                        }`}
                      >
                        <Button
                          primary
                          className="w-full"
                          onClick={() => handleBuyNowClick(item)}
                        >
                          {"View more"}
                        </Button>
                      </div>
                    </div>
                    <Paragraph
                      text14
                      className="mb-[6px] !text-dark-grey !font-normal"
                    >
                      {item?.art?.status === ProductStatus.OnSale
                        ? t("profile.sale")
                        : item?.art?.status === ProductStatus.Exposed
                        ? t("profile.exhibited")
                        : item?.art?.status}
                    </Paragraph>
                    <Paragraph text22 className="mb-[10px]">
                      {item?.art?.title}
                    </Paragraph>
                    {item?.art?.status === ProductStatus.OnSale && (
                      <Paragraph text18 className="font-normal !leading-[17px]">
                        {item?.art?.price}€
                      </Paragraph>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      )}
      {lightboxOpen && (
        <div
          className={artRoomData?.length === 1 ? "lightbox-no-navigation" : ""}
        >
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
      {imageLightboxOpen && (
        <div
          className={artRoomData?.length === 1 ? "lightbox-no-navigation" : ""}
        >
          <Lightbox
            open={imageLightboxOpen}
            close={closeImgLightbox}
            slides={[
              {
                type: "image",
                src: imgUrl || "",
              },
            ]}
            carousel={{
              finite: true,
              padding: 0,
            }}
          />
        </div>
      )}
    </>
  );
}

export default ProfileArtRoom;
