import * as Yup from "yup";

export const postValidationSchema = Yup.object({
  title: Yup.string()
    .trim()
    .max(255, "Maximum 255 characters allowed")
    .required("Title is required"),

  width: Yup.number()
    .required("Width is required")
    .min(1, "Width must be atleast 1")
    .max(10000, "Width is too large"),

  height: Yup.number()
    .required("Height is required")
    .min(1, "Height must be atleast 1")
    .max(10000, "Height is too large"),

  depth: Yup.number()
    .required("Depth is required")
    .positive("Depth must be a positive number")
    .max(10000, "Depth is too large"),

  weight: Yup.number()
    .required("Weight is required")
    .min(1, "Weight must be atleast 1")
    .max(10000, "Weight is too large"),

  price: Yup.number()
    .required("Price is required")
    .min(1, "Price must be atleast 1")
    .max(1000000, "Price is too large"),

  quantity: Yup.number()
    .required("Quantity is required")
    .min(1, "Quantity must be atleast 1")
    .max(10000, "Quantity is too large"),

  category: Yup.string()
    .trim()
    .max(255, "Maximum 255 characters allowed")
    .required("Category is required"),

  artTag: Yup.object({
    id: Yup.number().required("Art tag ID is required"),
    name: Yup.string()
      .trim()
      .max(255, "Maximum 255 characters allowed")
      .required("Art tag name is required"),
  }).required("Art tag is required"),

  mainColor: Yup.object({
    name: Yup.string()
      .trim()
      .max(255, "Maximum 255 characters allowed")
      .required("Main color name is required"),
  }).required("Main color is required"),

  secondaryColor: Yup.object({
    name: Yup.string()
      .trim()
      .max(255, "Maximum 255 characters allowed")
      .required("Secondary color name is required"),
  }).required("Secondary color is required"),
});
