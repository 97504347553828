import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  commonListData: null,
};

export const commonSlice = createSlice({
  name: "commonListData",
  initialState,
  reducers: {
    setCommonData: (state, action) => {
      state.commonListData = action.payload;
    },
  },
});

export const commonListSelector = (state: any) =>
  state.commonListData.commonListData;

const { actions, reducer } = commonSlice;

export const { setCommonData } = actions;

export default reducer;
