import { API } from "../../apiEndPoints/website/apiEndPoints";
import { axiosGet } from "../../axios/axiosMiddleware";

export const LanguageSettings = (date: any) => {
  return axiosGet(`${API?.OTHER?.LANGUAGE_SETTINGS}?updatedDate=${date}`);
};

export const getAllLanguages = () => {
  return axiosGet(API.OTHER.ALL_LANGUAGES);
};
