import { API } from "../../apiEndPoints/website/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../axios/axiosMiddleware";

export const getNotifications = (queryString: any) => {
  return axiosGet(`${API?.OTHER?.NOTIFICATION_LIST}/${queryString}`);
};

export const deleteNotification = (id: any) => {
  return axiosDelete(`${API?.OTHER?.NOTIFICATIONS}/${id}`);
};

export const deactivateNotification = (id: any) => {
  return axiosPost(`${API?.OTHER?.MUTE_NOTIFICATIONS}/${id}`, {});
};
export const muteNotification = (data: any) => {
  return axiosPatch(`${API?.OTHER?.NOTIFICATION_PREF}`, data);
};

export const getNotificationCount = () => {
  return axiosGet(`${API?.OTHER?.NOTIFICATION_COUNT}`);
};
