import React, { ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Paragraph from "./typography/Paragraph";
import { IoCloseOutline } from "react-icons/io5";
import Button from "./form-components/Button";

interface OffCanvasProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  position?: "left" | "right" | "top" | "bottom";
  size?: string; // Width or height based on position
  backdrop?: boolean; // Enable or disable the backdrop
  offCanvasTitle?: string;
}

function OffCanvas({
  isOpen,
  onClose,
  children,
  position,
  size,
  backdrop,
  offCanvasTitle,
}: OffCanvasProps) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      if (onClose) {
        onClose();
      }
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
      // Add style to body to prevent scrolling
      document.body.style.overflow = "hidden";
    } else {
      document.removeEventListener("keydown", handleKeyDown);
      // Remove the style to restore scrolling
      document.body.style.overflow = "";
    }

    // Clean up the event listener and body style on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      {backdrop && isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
      )}
      <div
        className={`fixed z-50 bg-white transition-all delay-300 duration-500 ease-in-out ${
          position === "left"
            ? `top-0 left-0 h-full ${
                isOpen ? "translate-x-0" : "-translate-x-full"
              }`
            : position === "right"
            ? `top-0 right-0 h-full ${
                isOpen ? "translate-x-0" : "translate-x-full"
              }`
            : position === "top"
            ? `top-0 left-0 w-full ${
                isOpen ? "translate-y-0" : "-translate-y-full"
              }`
            : `bottom-0 left-0 w-full ${
                isOpen ? "translate-y-0" : "translate-y-full"
              }`
        }`}
        style={{
          width:
            position === "left" || position === "right"
              ? `${windowWidth > 575 ? size : "100%"}`
              : undefined,
          height:
            position === "top" || position === "bottom"
              ? `${windowWidth > 575 ? size : "100%"}`
              : undefined,
        }}
      >
        <div className="bg-light-grey py-4 lg:px-30 sm:px-4 px-3 flex items-center gap-3 relative">
          <Paragraph text20 className="font-medium">
            {offCanvasTitle}
          </Paragraph>
          <button
            onClick={onClose}
            className="absolute top-3 right-6 text-xl font-bold"
          >
            <IoCloseOutline size={24} color="#333333" />
          </button>
        </div>
        <div className="py-4 lg:px-30 sm:px-4 px-3 h-[calc(100vh-118px)] overflow-y-auto">
          {children}
        </div>
        <div className="bg-light-grey py-3 lg:px-30 sm:px-4 px-3 flex items-center gap-3 relative ">
          <div className="flex items-center gap-4 w-full">
            <Button className="w-full" borderButton>
              Reset
            </Button>
            <Button className="w-full" primary>
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("modal-root")!
  );
}

export default OffCanvas;
