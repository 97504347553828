import React, { useEffect, useState } from "react";
import Paragraph from "../../../../components/core/typography/Paragraph";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import { useDispatch, useSelector } from "react-redux";
import Art1 from "../../../../assets/images/art1.png";
import Art2 from "../../../../assets/images/art2.png";
import Art3 from "../../../../assets/images/art3.png";
import Art4 from "../../../../assets/images/art4.png";
import Art5 from "../../../../assets/images/art5.png";
import { Link, useNavigate } from "react-router-dom";
import { pathRoute } from "../../../../routes/pathRoute";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../../redux/slices/siteLoaderSlice";
import { getSalesList } from "../../../../services/website/accountService";
import { PurchaseInterface } from "../../../../interface/Purchase";
import NoDataFound from "../../../../assets/images/empty_repost.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import ImageWithDefault from "../../../../components/web-component/DefaultImage";

function MySales() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const dispatch = useDispatch();
  const [salesList, setSalesList] = useState<PurchaseInterface[]>([]);
  const [currentResponsePage, setCurrentResponsePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const isLoading = useSelector(siteLoaderSelector); // Select the loading state from Redux
  const navigate = useNavigate();

  const itemsPerPage = 10;

  const fetchPurchaseList = async (page: number) => {
    dispatch(showLoader());
    try {
      let queryString = `?limit=${itemsPerPage}&page=${page}`;

      const response = await getSalesList(queryString);
      console.log("Sdfdsfdsfsfsd", response);
      // Handle feed data
      if (response?.data?.code === 200) {
        setSalesList((prevData) => [...prevData, ...response.data.sellings]);
        setTotalItems(response?.data?.total || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchPurchaseList(currentResponsePage);
  }, [currentResponsePage]);

  const handleDetailPage = (item: any) => {
    const routes = `${pathRoute.website.saleDetail}/${item?.id}`;
    navigate(routes, { state: { item: item } });
  };

  return (
    <div>
      <Paragraph text24 className="font-semibold mb-6 ">
        {LanguageTranslation({
          labelName: "my_sales",
          languageCode: langId || "fr",
        }) || "My Sales"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
        {salesList?.length === 0 && !isLoading ? (
          <div className="break-inside-avoid text-center">
            <img
              src={NoDataFound}
              alt="no-data-found"
              className="mb-6 inline-block"
              width={"250px"}
              height={"142px"}
            />
            <Paragraph text18 className="!font-medium">
              No result found
            </Paragraph>
          </div>
        ) : (
          <div
            id="scrollableDiv"
            style={{ overflowY: "auto", maxHeight: "80vh", height: "100%" }}
          >
            <InfiniteScroll
              dataLength={salesList?.length}
              next={() => setCurrentResponsePage((prevPage) => prevPage + 1)}
              hasMore={salesList?.length < totalItems}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              <div className="grid xxl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4">
                {salesList?.map((item: PurchaseInterface) => {
                  return (
                    <div
                      onClick={() => handleDetailPage(item)}
                      className="block col-span-1"
                    >
                      <div className="rounded-[20px] overflow-hidden mb-3">
                        <ImageWithDefault
                          src={item?.art?.images?.[0]?.imageUrl}
                          alt="popular-art"
                          className="aspect-square"
                        />
                      </div>
                      <Paragraph text18 className="">
                        {item?.art?.title}
                      </Paragraph>
                      <Paragraph text18 className="!font-normal">
                        {item?.art?.price}€
                      </Paragraph>
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
}

export default MySales;
