import React, { useEffect, useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import { Form, Formik, useFormikContext } from "formik";
import FormLabel from "../../../components/core/typography/FormLabel";
import { languageIdSelector } from "../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../components/language-translation/LanguageTranslation";
import { useDispatch, useSelector } from "react-redux";
import InputType from "../../../components/core/form-components/InputType";
import SelectType from "../../../components/core/form-components/SelectType";
import MyProfileLeftSection from "./MyProfileLeftSection";
import TextArea from "../../../components/core/form-components/TextArea";
import { getCountriesByInfo } from "../../../services/countryService";
import {
  editProfile,
  getProfileInfo,
} from "../../../services/website/profileService";
import { ProfileDetails } from "../../../interface/ProfileDetails";
import { profileValidationSchema } from "../../../validations/website/profileValidationSchema";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { pathRoute } from "../../../routes/pathRoute";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/core/form-components/Button";

interface FormValues {
  nickname: string;
  mail: string;
  description?: string;
  address: string;
  zip: string;
  city: string;
  countryId?: string;
  surname: string;
  name: string;
  birthdate?: string;
  phoneNb?: string;
  countryCode?: string;
}

function EditProfile() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState<
    { label: string; value: string; id: number }[]
  >([]);
  const today = new Date().toISOString().split("T")[0];

  const [defaultInitialValues, setDefaultInitialValues] = useState({
    nickname: "",
    mail: "",
    description: "",
    address: "",
    zip: "",
    city: "",
    countryId: "",
    surname: "",
    name: "",
    birthdate: "",
    phoneNb: "",
    countryCode: "",
  });

  useEffect(() => {
    dispatch(showLoader());

    const fetchProfileData = async () => {
      try {
        const response = await getProfileInfo();
        if (response?.data?.code === 200) {
          let profileData = response?.data?.data;
          let data = {
            nickname: profileData.nickname || "",
            mail: profileData.mail || "",
            description: profileData.description || "",
            address: profileData.address || "",
            zip: profileData.zip || "",
            city: profileData.city || "",
            countryId: profileData.country.id || "",
            surname: profileData.surname || "",
            name: profileData.name || "",
            birthdate: profileData.birthdate
              ? profileData.birthdate.split("T")[0]
              : "", // Ensure date format is YYYY-MM-DD
            phoneNb: profileData.phoneNb || "",
            countryCode: profileData.country.code || "",
          };
          setDefaultInitialValues({ ...data });
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        dispatch(hideLoader());
      }
    };

    fetchProfileData();
  }, []);

  const onSubmit = async (values: FormValues) => {
    dispatch(showLoader());
    const params = {
      description: values?.description,
      nickname: values?.nickname,
      mail: values?.mail,
      address: values?.address,
      zip: values?.zip,
      city: values?.city,
      countryId: values?.countryId,
      surname: values?.surname,
      name: values?.name,
      birthdate: values?.birthdate,
    };
    try {
      const response = await editProfile(params);
      if (response?.data?.code === 201) {
        navigate(pathRoute.website.myProfile);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        let queryString = `?sort_by=code&order=asc`;

        const response = await getCountriesByInfo(queryString);
        const formattedData = response?.data?.countries.map(
          (category: any) => ({
            label: category.code,
            value: category.code,
            id: category?.id,
          })
        );
        setCountryList(formattedData || []);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleCancel = () => {
    navigate(pathRoute.website.myProfile);
  };

  return (
    <div className="py-6 sm:pl-4 pl-3 xl:pr-10 lg:pr-4 pr-3">
      <div className="grid grid-cols-12 xl:gap-[48px] gap-6">
        <MyProfileLeftSection isEditProfile={true} />

        <div className="xl:col-span-7 lg:col-span-10 col-span-12">
          <div className="border border-medium-grey rounded-2xl lg:p-30 md:p-5 p-4">
            <Formik
              initialValues={defaultInitialValues}
              onSubmit={onSubmit}
              validationSchema={profileValidationSchema}
              enableReinitialize
            >
              {({ isSubmitting, errors, values, setFieldValue }) => (
                <Form>
                  <>
                    <div className="grid grid-cols-2 lg:gap-6 md:gap-5 gap-4">
                      <div className="col-span-2">
                        <Paragraph text20 className="font-medium">
                          Personal Info.
                        </Paragraph>
                      </div>
                      <div className="form-group sm:col-span-1 col-span-2">
                        <FormLabel>
                          {LanguageTranslation({
                            labelName: "first_name",
                            languageCode: langId || "fr",
                          }) || "First name"}
                          <span className="text-site-red">*</span>
                        </FormLabel>
                        <InputType
                          required
                          name="name"
                          type="text"
                          value={values.name}
                          placeholder="Enter first name"
                        />
                      </div>
                      <div className="form-group sm:col-span-1 col-span-2">
                        <FormLabel>
                          {LanguageTranslation({
                            labelName: "last_name",
                            languageCode: langId || "fr",
                          }) || "Last name"}
                          <span className="text-site-red">*</span>
                        </FormLabel>
                        <InputType
                          required
                          name="surname"
                          type="text"
                          value={values.surname}
                          placeholder="Enter last name"
                        />
                      </div>
                      <div className="form-group sm:col-span-1 col-span-2">
                        <FormLabel>
                          {LanguageTranslation({
                            labelName: "pseudonym_name",
                            languageCode: langId || "fr",
                          }) || "Pseudonym name"}
                          <span className="text-site-red">*</span>
                        </FormLabel>
                        <InputType
                          required
                          name="nickname"
                          type="text"
                          value={values.nickname}
                          placeholder="Enter pseudonym name"
                        />
                      </div>
                      <div className="form-group sm:col-span-1 col-span-2">
                        <FormLabel>
                          {LanguageTranslation({
                            labelName: "birthdate",
                            languageCode: langId || "fr",
                          }) || "Birthdate"}
                          <span className="text-site-red"></span>
                        </FormLabel>
                        <InputType
                          required
                          name="birthdate"
                          type="date"
                          value={values.birthdate}
                          placeholder="Enter birthdate"
                          maxDate={today}
                          onKeyDown={(e: any) => e.preventDefault()} // Prevent manual typing
                          onPaste={(e: any) => e.preventDefault()} // Prevent pasting into the field
                        />
                      </div>
                      <div className="form-group sm:col-span-2 col-span-2">
                        <FormLabel>
                          {LanguageTranslation({
                            labelName: "about",
                            languageCode: langId || "fr",
                          }) || "About"}
                          <span className="text-site-red"></span>
                        </FormLabel>
                        <TextArea
                          name="description"
                          rows={2}
                          placeholder="About"
                          // values={values.about}
                        />
                      </div>
                      <div className="col-span-2">
                        <Paragraph text20 className="font-medium mt-1">
                          Contact Info.
                        </Paragraph>
                      </div>
                      <div className="form-group sm:col-span-1 col-span-2">
                        <FormLabel>
                          {LanguageTranslation({
                            labelName: "email",
                            languageCode: langId || "fr",
                          }) || "Email"}
                          <span className="text-site-red">*</span>
                        </FormLabel>
                        <InputType
                          required
                          name="mail"
                          placeholder="Enter email"
                          value={values.mail}
                        />
                      </div>
                      <div className="form-group sm:col-span-1 col-span-2">
                        <FormLabel>
                          {LanguageTranslation({
                            labelName: "phone",
                            languageCode: langId || "fr",
                          }) || "Contact number"}
                          <span className="text-site-red">*</span>
                        </FormLabel>
                        <div className=" flex items-stretch border border-medium-grey rounded-lg">
                          <SelectType
                            options={countryList}
                            className=" border-0 "
                            placeholder={"Select"}
                            onChange={(option) => {
                              setFieldValue("countryCode", option?.value);
                              setFieldValue("countryId", option.id);
                            }}
                            value={
                              countryList?.find(
                                (option) =>
                                  option?.value === values?.countryCode
                              ) || ""
                            }
                            disabled
                          ></SelectType>
                          <InputType
                            fullWidth
                            name="phoneNb"
                            type="number"
                            value={values.phoneNb}
                            placeholder="Enter contact number"
                            className="border-0 "
                            showError={false}
                            disabled
                          />
                        </div>
                        {errors?.phoneNb && (
                          <div className="text-site-red text-sm font-medium">
                            {errors?.phoneNb}
                          </div>
                        )}
                      </div>
                      <div className="col-span-2">
                        <Paragraph text20 className="font-medium mt-1">
                          Address Info.
                        </Paragraph>
                      </div>
                      <div className="form-group sm:col-span-1 col-span-2">
                        <FormLabel>
                          {LanguageTranslation({
                            labelName: "block_no",
                            languageCode: langId || "fr",
                          }) || "Block Number"}
                          <span className="text-site-red">*</span>
                        </FormLabel>
                        <InputType
                          required
                          name="address"
                          type="text"
                          value={values.address}
                          placeholder="Enter block number"
                        />
                      </div>
                      <div className="form-group sm:col-span-1 col-span-2">
                        <FormLabel>
                          {LanguageTranslation({
                            labelName: "post_code",
                            languageCode: langId || "fr",
                          }) || "Post code"}
                          <span className="text-site-red">*</span>
                        </FormLabel>
                        <InputType
                          required
                          name="zip"
                          type="number"
                          value={values.zip}
                          placeholder="Enter post code"
                        />
                      </div>
                      <div className="form-group sm:col-span-1 col-span-2">
                        <FormLabel>
                          {LanguageTranslation({
                            labelName: "city",
                            languageCode: langId || "fr",
                          }) || "City"}
                          <span className="text-site-red">*</span>
                        </FormLabel>
                        <InputType
                          required
                          name="city"
                          type="text"
                          value={values.city}
                          placeholder="Enter city"
                        />
                      </div>

                      <div className="col-span-1 col-start-1">
                        <Button
                          borderButton
                          className="w-full"
                          onClick={handleCancel}
                        >
                          cancel
                        </Button>
                      </div>
                      <div className="col-span-1 ">
                        <Button primary type="submit" className="w-full">
                          Update
                        </Button>
                      </div>
                    </div>
                  </>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
