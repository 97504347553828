import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/black_logo.svg";
import HeadingOne from "../../../components/core/typography/HeadingOne";
import Paragraph from "../../../components/core/typography/Paragraph";
import FormLabel from "../../../components/core/typography/FormLabel";
import InputType from "../../../components/core/form-components/InputType";
import TextLink from "../../../components/core/form-components/TextLink";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../../components/core/form-components/Button";
import { FaApple, FaFacebook } from "react-icons/fa";
import { pathRoute } from "../../../routes/pathRoute";
import { getCountriesByInfo } from "../../../services/countryService";
import { contactNumberValidationSchema } from "../../../validations/website/contactNumberValidationSchema";
import SelectType from "../../../components/core/form-components/SelectType";
import { Link, useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { languageIdSelector } from "../../../redux/slices/website/languageSlice";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { LoginWIthNumber } from "../../../services/website/authService";

interface RegisterFormValues {
  countryCode: string;
  phoneNumber: string;
  countryId?: number;
}

const Register = () => {
  const initialValues: RegisterFormValues = {
    countryCode: "+33",
    phoneNumber: "",
    countryId: 79,
  };
  const [countryList, setCountryList] = useState<
    { label: string; value: string }[]
  >([]);
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        let queryString = `?sort_by=code&order=asc`;

        const response = await getCountriesByInfo(queryString);
        const formattedData = response?.data?.countries.map(
          (category: any) => ({
            label: category.code,
            value: category.code,
            id: category?.id,
          })
        );
        setCountryList(formattedData || []);
      } catch (error) {
        console.error("Error validating token:", error);
      }
    };

    fetchCountries();
  }, []);

  const onSubmit = async (values: RegisterFormValues) => {
    console.log("Form Data: ", values);
    dispatch(showLoader());

    const data = {
      type: 1,
      phoneNb: values.phoneNumber.toString(),
      countryCode: values.countryCode,
      languageId: langId,
      countryId: values?.countryId,
    };
    try {
      const response = await LoginWIthNumber(data);
      if (response?.data?.code === 201) {
        navigate(`${pathRoute.auth.otp}`, {
          state: {
            otpData: response?.data?.data,
            countryId: values?.countryId,
            type: 1,
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="xl:px-[105px] lg:px-[60px] md:px-10 px-3 xxl:pt-15 lg:py-10 py-[30px]">
      <div className="text-center xl:mb-[60px] lg:mb-[40px] mb-[30px]">
        <img
          src={Logo}
          alt="logo"
          width={180}
          height={74}
          className="text-center inline-block"
        />
      </div>
      <Link
        to={pathRoute?.auth.social}
        className="absolute top-[30px] right-[30px]"
      >
        <IoCloseOutline color="#333333" size={30} />
      </Link>
      <Paragraph text24 className="mb-[10px] !font-semibold">
        Sign Up
      </Paragraph>
      <Paragraph text18 className="font-normal mb-6 !text-dark-grey">
        Discover a wide range of Artwork created by our community of passionate
        artists.
      </Paragraph>
      <Formik
        initialValues={initialValues}
        validationSchema={contactNumberValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue, values, errors }) => (
          <Form>
            <FormLabel>
              Contact number<span className="text-site-red">*</span>
            </FormLabel>
            <div className=" flex items-stretch border border-medium-grey rounded-lg">
              <SelectType
                options={countryList}
                className=" border-0 "
                placeholder={"Select"}
                onChange={(option) => {
                  setFieldValue("countryCode", option?.value);
                  setFieldValue("countryId", option.id);
                }}
                value={
                  countryList?.find(
                    (option) => option?.value === values?.countryCode
                  ) || ""
                }
              ></SelectType>
              <InputType
                fullWidth
                name="phoneNumber"
                type="number"
                value={values.phoneNumber}
                placeholder="Enter contact number"
                className="border-0 "
                showError={false}
              />
            </div>
            {errors?.phoneNumber && (
              <div className="text-site-red text-sm font-medium mt-2">
                {errors?.phoneNumber}
              </div>
            )}

            <Button
              primary
              disabled={isSubmitting}
              type="submit"
              className="w-full mt-[30px]"
            >
              Sign Up
            </Button>
            <Paragraph
              text14
              className="!text-dark-grey text-center !font-normal mt-4 mb-5"
            >
              Already have an account?{" "}
              <TextLink to={pathRoute.auth.login}>Sign In</TextLink>
            </Paragraph>
            <Paragraph
              text18
              className="mb-6 flex justify-center items-center gap-3 font-normal before:w-[100px] before:h-[1px] before:bg-medium-grey before:block after:w-[100px] after:h-[1px] after:bg-medium-grey after:block !text-medium-grey"
            >
              OR
            </Paragraph>
            <div className="flex items-center justify-center gap-4 mb-6">
              <button className="flex items-center gap-2 border border-medium-grey hover:border-site-black ease-in-out transition-all duration-300 rounded-3xl px-4 py-2">
                <FaApple size={28} />
                <span className="block">
                  <span className="block text-xs leading-3 text-[#737373] mb-[2px] text-left">
                    Sign in with
                  </span>
                  <span className="block text-[18px] leading-[16px] font-semibold text-left">
                    Apple
                  </span>
                </span>
              </button>
              <button className="flex items-center gap-2 border border-medium-grey hover:border-[#4487EC] ease-in-out transition-all duration-300 rounded-3xl px-4 py-2">
                <FaFacebook color="#4487EC" size={26} />
                <span className="block">
                  <span className="block text-xs leading-3 text-[#737373] mb-[2px] text-left">
                    Sign in with
                  </span>
                  <span className="block text-[18px] leading-[16px] font-semibold text-left text-[#4487EC]">
                    Facebook
                  </span>
                </span>
              </button>
            </div>
            <Paragraph text14 className="text-center !font-normal">
              By registering on artgapi, I accept the{" "}
              <TextLink to="#">Terms and Conditions</TextLink> of use and of
              sale and the{" "}
              <TextLink to="">Personal Data Protection Policy</TextLink>.
            </Paragraph>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Register;
