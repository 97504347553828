import React, { useState } from "react";
import { IoMdPlay } from "react-icons/io";
import PostCarousel from "./PostCarousel";
import Paragraph from "../core/typography/Paragraph";
import { RepostDetails } from "../../interface/RepostInterface";
import PostOnlyText from "./PostOnlyText";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";

interface PostImageProps {
  itemData: RepostDetails; // Adjust type as per your data
  feedData: any;
}

const PostArtImage: React.FC<PostImageProps> = ({ itemData, feedData }) => {
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const openLightbox = (videoUrl: string) => {
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const renderMedia = (item: RepostDetails): React.ReactElement | null => {
    if (
      item?.art?.video ||
      (item?.art?.images && item?.art?.images?.length > 0)
    ) {
      if (item?.art?.video) {
        return (
          <div className="block rounded-[20px] overflow-hidden relative w-auto">
            <img
              id={`image-${item.id}`}
              src={item?.art.video?.imageUrl}
              alt="Video thumbnail"
              className="h-auto rounded-[20px] bg-light-grey w-full"
              loading="lazy"
            />
            <button
              aria-label="Play video"
              className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center bg-gradient-to-r from-site-black to-site-black/0 backdrop-blur-md"
            >
              <IoMdPlay
                color="#fff"
                size={22}
                onClick={() => openLightbox(item?.art?.video?.videoUrl)}
              />
            </button>
          </div>
        );
      }

      if (item?.art?.images && item?.art?.images.length > 0) {
        return item?.art?.images.length > 1 ? (
          <div className="block rounded-[20px] overflow-hidden relative w-auto">
            <PostCarousel itemImg={item?.art?.images} />
          </div>
        ) : (
          <div className="block rounded-[20px] overflow-hidden relative w-auto">
            <img
              src={item?.art?.images[0]?.imageUrl}
              alt="Single feed image"
              className="w-full h-auto rounded-[20px] bg-light-grey cursor-pointer"
              loading="lazy"
            />
          </div>
        );
      }
    } else {
      return <PostOnlyText description={item?.art?.title || ""} />;
    }

    return null; // Ensure the function always returns a valid value
  };

  return (
    <>
      {renderMedia(itemData)}
      {lightboxOpen && (
        <div className={feedData?.length === 1 ? "lightbox-no-navigation" : ""}>
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
    </>
  );
};

export default PostArtImage;
