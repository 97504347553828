export const API = {
  AUTH: {
    LOGIN: "v2/auth",
    FORGOT: "reset-password",
    GETOTP: "v2/authentication/getOtp",
    VERIFY_OTP: "v2/authentication/verifyOtp",
    RESEND_OTP: "v2/authentication/resendOtp",
    REGISTER: "v2/web/auth/register",
    FORGOT_PASSWORD: "v2/web/auth/reset-password",
  },
  OTHER: {
    LANGUAGE_SETTINGS: "v2/label/web",
    ALL_LANGUAGES: "v2/languages",
    GET_INTERESTS: "v2/interest/withCategory",
    GET_PROFILE_DETAILS: "v2/self",
    GET_ARTROOM_INFO: "v2/users",
    GET_ARTROOM_DETAILS: "arts",
    CHECK_SHIPPING_DETAILS: "v2/shipping/services",
    GET_REPOST_DETAILS: "v2/repost/users",
    GET_EVENT: "v2/users",
    DELETE_FEED: "publications",
    FEED_RESPONSES: "publications-comments",
    ADD_COMMENTS: "v2/web/publication",
    GET_ARTS_WEEKS: "v2/artOfTheWeek/arts/topArts",
    GET_ARTISTS_WEEKS: "v2/artistOfTheWeek/artist/topArtist",
    GET_HOME_POSTS: "v2/publications/repost",
    PIECES: "search/arts",
    PEOPLE: "search/arts",
    START: "startup/v2",
    TAGS: "search/tags",
    OPPORTUNITIES: "opportunities",
    APPLY_OPPORTUNITY: "opportunities/submit-application",
    MASTER_CLASS: "v2/masterclasses",
    USERS_PROFILE: "v2/users/publishers",
    FOLLOW_USERS: "v2/follows/follow",
    CREATE_POST: "publications/image",
    UNFOLLOW_USERS: "v2/follows/unfollow",
    CREATE_ARTROOM_POST: "publications/art-images",
    CREATE_POST_VIDEO: "publications/video",
    CHANGE_PASSWORD: "v2/self/password",
    CREATE_POST_TEXT: "publications/text",
    DELETE_REPOST: "v2/repost/publication",
    ADD_REPOST: "v2/repost",
    PURCHASE_LIST: "history/purchases",
    SALES_LIST: "history/sellings",
    MASTER_CLASS_LIST: "v2/user/masterClassSubscription",
    TRANSACTION_LIST: "v2/transaction/user",
    NOTIFICATION_PREF: "v2/self/preferences",
    REPORT_BUG: "bug-reports",
    REPORT_USER: "flags/publications",
    NOTIFICATION_LIST: "notifications/mynotifications",
    NOTIFICATIONS: "notifications",
    MUTE_NOTIFICATIONS: "notifications/mutenotificationsfor",
    NOTIFICATION_COUNT: "notifications/count",
  },
};
