import { useState } from "react";
import DummyProfile from "../../assets/images/dummy_logo.png";

interface imageInterface {
  src: string;
  alt: string;
  className?: any;
  height?: number;
  width?: number;
  onClick?: any;
}
const ImageWithDefault = ({
  src,
  alt,
  className,
  width,
  height,
  onClick,
  ...props
}: imageInterface) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleError = () => {
    setImageSrc(DummyProfile);
  };

  return (
    <img
      className={className}
      src={imageSrc}
      onError={handleError}
      alt={alt}
      width={width}
      height={height}
      onClick={onClick}
      {...props}
    ></img>
  );
};

export default ImageWithDefault;
