import React from "react";
import { Link } from "react-router-dom";
import Art1 from "../../../assets/images/popular-week-art1.png";
import Art2 from "../../../assets/images/popular-week-art2.png";
import Art3 from "../../../assets/images/popular-week-art3.png";
import Art4 from "../../../assets/images/popular-week-art4.png";
import Art5 from "../../../assets/images/popular-week-art5.png";
import Profile from "../../../assets/images/dummy_profile.png";
import Paragraph from "../../../components/core/typography/Paragraph";
import { useSelector } from "react-redux";
import { languageIdSelector } from "../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../components/language-translation/LanguageTranslation";
import { MdKeyboardArrowRight } from "react-icons/md";

function Pieces() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  return (
    <div className="pb-6 mb-6 border-b border-medium-grey">
      <div className="flex items-center justify-between gap-3 mb-5">
        <Paragraph text24 className="font-semibold ">
          Pieces
        </Paragraph>
        <Link
          to={""}
          className="flex items-center gap-1.5 text-site-black font-medium"
        >
          <span className="text-base leading-[20px]">View More</span>{" "}
          <MdKeyboardArrowRight size={22} className="mt-[-2px]" />
        </Link>
      </div>
      <div className="grid xxl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-3 grid-cols-2 gap-3">
        <Link to={""} className="block col-span-1">
          <div className="rounded-[20px] overflow-hidden mb-3">
            <img src={Art1} alt="" className="aspect-square w-full" />
          </div>
          <Paragraph text18 className="">
            {LanguageTranslation({
              labelName: "product_detail",
              languageCode: langId || "fr",
            }) || "Bonsai"}
          </Paragraph>
          <Paragraph text18 className="!font-normal">
            {LanguageTranslation({
              labelName: "product_price",
              languageCode: langId || "fr",
            }) || "180€"}
          </Paragraph>
        </Link>
        <Link to={""} className="block col-span-1">
          <div className="rounded-[20px] overflow-hidden mb-3">
            <img src={Art2} alt="" className="aspect-square  w-full" />
          </div>
          <Paragraph text18 className="">
            Bonsai
          </Paragraph>
          <Paragraph text18 className="!font-normal">
            180€
          </Paragraph>
        </Link>
        <Link to={""} className="block col-span-1">
          <div className="rounded-[20px] overflow-hidden mb-3">
            <img src={Art3} alt="" className="aspect-square  w-full" />
          </div>
          <Paragraph text18 className="">
            Bonsai
          </Paragraph>
          <Paragraph text18 className="!font-normal">
            180€
          </Paragraph>
        </Link>
      </div>
    </div>
  );
}

export default Pieces;
