import React, { useEffect, useState } from "react";
import NoDataFound from "../../../../assets/images/empty_repost.svg";
import { IoEllipsisVertical } from "react-icons/io5";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import DummyProfile from "../../../../assets/images/dummy_logo.png";
import { useTranslation } from "react-i18next";
import {
  tokenSelector,
  userSelector,
} from "../../../../redux/slices/userSlice";
import {
  hideLoader,
  showLoader,
} from "../../../../redux/slices/siteLoaderSlice";
import Paragraph from "../../../../components/core/typography/Paragraph";
import Dropdown from "../../../../components/core/form-components/Dropdown";
import { FeedInterface } from "../../../../interface/Feed";
import "yet-another-react-lightbox/styles.css";
import {
  deleteFeedById,
  getWebFeedById,
  likeFeedByid,
} from "../../../../services/website/feedService";
import TimeDifference from "../../../../components/web-component/TimeDifference";
import { pathRoute } from "../../../../routes/pathRoute";
import { Link, useNavigate, useParams } from "react-router-dom";
import CommentFooter from "../../../../components/web-component/CommentFooter";
import CommentSection from "../../../../components/web-component/CommentSection";
import PostFeedImage from "../../../../components/web-component/PostFeedImage";
import PostZoomInOut from "../../../../components/web-component/PostZoomInOut";
import PostDescription from "../../../../components/web-component/PostDescription";
import ImageWithDefault from "../../../../components/web-component/DefaultImage";
import {
  blockUser,
  reportUser,
} from "../../../../services/website/peopleService";
import Modal from "../../../../components/core/Modal";
import ReportProjectContent from "../../../../components/core/ReportProjectContent";
import Button from "../../../../components/core/form-components/Button";

function ProfileFeed() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const webProfileData = useSelector(userSelector);
  const [feedData, setFeedData] = useState<FeedInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false); // Loading state for API calls
  const [totalItems, setTotalItems] = useState(0); // Track total available items
  const [openCommentPostId, setOpenCommentPostId] = useState<number | null>(
    null
  );
  const [reportPopup, setReportPopup] = useState(false);
  const [reportId, setReportId] = useState<number | null>(null);
  const [selectId, setSelectId] = useState<number | null>(null);

  const token = useSelector(tokenSelector);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isUserId, setIsUserId] = useState("");
  const profileDropDownItems = [
    { Link: "", LinkName: "Delete", handleAction: true },
  ];
  const userDropDownItems = [
    { Link: "", LinkName: "Report", handleAction: true },
    { Link: "", LinkName: "Block", handleAction: true },
  ];

  console.log("feedId", isUserId);

  useEffect(() => {
    if (id) {
      setIsUserId(id);
    } else {
      setIsUserId(webProfileData?.id);
    }
  }, [id]);

  const handleReportId = (id: number) => {
    setReportId(id);
  };

  const fecthFeedList = async (page: number, id: any) => {
    let queryString = `?type=1&filter=0&page=${page}&limit=10&keyword=`;
    dispatch(showLoader());
    setLoading(true); // Start loading when fetching data
    try {
      const response = await getWebFeedById(id, queryString);
      const count = response?.data?.total || 0;
      if (response?.data?.publications) {
        setFeedData((prevData) => [...prevData, ...response.data.publications]);

        setTotalItems(count); // Update the total number of items
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
      setLoading(false); // End loading after fetching data
    }
  };

  const handleDeleteItem = async (id: any) => {
    dispatch(showLoader());
    setLoading(true); // Start loading when fetching data
    try {
      const response = await deleteFeedById(id);
      if (response?.data?.code === 201) {
        setFeedData((prevData) => prevData?.filter((item) => item.id !== id));
        setTotalItems((prevTotal) => prevTotal - 1);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
      setLoading(false); // End loading after fetching data
    }
  };

  useEffect(() => {
    if (isUserId) {
      fecthFeedList(currentPage, isUserId);
    }
  }, [isUserId, currentPage]);

  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };

  const backToLogin = () => {
    navigate(pathRoute.auth.login);
  };
  const handleCommentSection = (postId: number) => {
    if (token) {
      setOpenCommentPostId((prevId) => (prevId === postId ? null : postId));
    } else {
      backToLogin();
    }
  };

  const handleLikeOutside = async (id: number) => {
    try {
      if (token) {
        const response = await likeFeedByid(id);

        if (response?.data?.code === 200) {
          // Toggle the likedByMe status and update the feedData state
          setFeedData((prevData) =>
            prevData.map((feedItem) =>
              feedItem.id === id
                ? {
                    ...feedItem,
                    likedByMe: !feedItem.likedByMe, // Toggle likedByMe
                    countLikes: feedItem.likedByMe
                      ? feedItem.countLikes - 1 // Decrease countLikes if already liked
                      : feedItem.countLikes + 1, // Increase countLikes if not liked
                  }
                : feedItem
            )
          );
        }
      } else {
        backToLogin();
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      event.currentTarget.scrollHeight ===
      event.currentTarget.scrollTop + event.currentTarget.clientHeight;
    if (bottom && !loading && feedData.length < totalItems) {
      setCurrentPage((prevPage) => prevPage + 1); // Load the next page
    }
  };

  // Rendering the comments section
  const renderCommentsSection = (postId: number) => {
    if (openCommentPostId === postId) {
      return <CommentSection commentId={postId} />;
    }
    return null;
  };

  const handleBlock = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await blockUser(item?.user?.id);
      if (response?.data?.code === 201) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const closeReportPopup = () => {
    setReportPopup(false);
  };

  const handleReportApi = async () => {
    dispatch(showLoader());
    try {
      let data = {
        flagId: reportId,
      };
      const response = await reportUser(data, selectId);
      if (response?.data?.code === 201) {
        setReportPopup(false);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleActionsInweb = (type: string, item: any) => {
    switch (type) {
      case "Delete":
        handleDeleteItem(item?.id);
        break;
      case "Block":
        handleBlock(item);
        break;
      case "Report":
        setSelectId(item?.id);
        setReportPopup(!reportPopup);
        break;
      default:
        console.log("Unknown action type:", type);
        break;
    }
  };
  console.log("feedData", feedData);
  return (
    <div className="px-6">
      {isUserId === webProfileData?.id && (
        <Link
          to={pathRoute.website.createPost}
          className="md:order-1 order-2 border border-medium-grey rounded-lg px-5 py-3.5 flex items-center gap-3 md:mb-30 md:mt-0 mt-5"
        >
          <div className="w-[30px] h-[30px] bg-site-black inline-flex items-center justify-center rounded-[6px]">
            <HiOutlinePlusSmall size={28} color="#fff" />
          </div>
          <Paragraph text20 className="font-medium">
            Create post
          </Paragraph>
        </Link>
      )}

      <div
        onScroll={handleScroll}
        style={{ overflowY: "auto", maxHeight: "80vh", overflowX: "hidden" }}
      >
        {feedData?.length === 0 ? (
          <div className="break-inside-avoid text-center">
            <img
              src={NoDataFound}
              alt="no-data-found"
              className="mb-6 inline-block"
              width={"250px"}
              height={"142px"}
            />
            <Paragraph text18 className="!font-medium">
              No result found
            </Paragraph>
          </div>
        ) : (
          <ul>
            {feedData?.map((item: FeedInterface, index: number) => {
              const isLastItem = index === feedData.length - 1;

              return (
                <>
                  <li
                    className={`relative block pb-6 mb-6 border-b border-medium-grey md:ps-[66px] sm:ps-[40px] ps-5 ${
                      isLastItem ? "mb-0 border-b-0" : ""
                    }`}
                  >
                    <div className="flex absolute left-0 top-0 z-[1]">
                      <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                        <ImageWithDefault
                          src={item?.user?.avatar}
                          alt="profile-photo"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="bg-white flex items-center md:gap-10 gap-4 ps-6 pe-4 md:py-3 py-2 rounded-se-[20px] rounded-ee-[20px] relative sm:-left-[15px] -left-[20px] -z-[1]">
                        <div>
                          <Paragraph text18 className="!leading-4">
                            @{item?.user?.nickname}
                          </Paragraph>
                          <Paragraph
                            text14
                            className="!leading-[17px] !font-normal !text-dark-grey"
                          >
                            <TimeDifference date={item?.createdAt} />
                          </Paragraph>
                        </div>
                        <Dropdown
                          onClick={() => toggleDropdown("userProfile")}
                          dropdownItems={
                            isUserId === webProfileData?.id
                              ? profileDropDownItems
                              : userDropDownItems
                          }
                          dropdownWidth="w-[174px]"
                          handleActionsInweb={
                            handleActionsInweb
                              ? (e: any) => handleActionsInweb(e, item)
                              : undefined
                          }
                        >
                          <IoEllipsisVertical size={20} />
                        </Dropdown>
                        <Modal
                          open={reportPopup}
                          header
                          onClose={closeReportPopup}
                          width="sm:w-[448px] w-[300px]"
                          modalPadding="p-6"
                        >
                          <ReportProjectContent
                            onSelectionChange={handleReportId}
                          />
                          <div className="flex items-center justify-center md:gap-4 gap-3">
                            <Button
                              borderButton
                              className="w-full"
                              onClick={() => setReportPopup(false)}
                            >
                              Cancel
                            </Button>
                            <Button
                              primary
                              onClick={handleReportApi}
                              className="w-full"
                            >
                              Send
                            </Button>
                          </div>
                        </Modal>
                      </div>
                    </div>
                    {/* type 0 is for feed and 1 is for art */}

                    <div
                      className={`my-profile-feed ${
                        item?.images && item?.images?.length > 1
                          ? "relative feed-carousel pb-6 max-w-[490px]"
                          : "relative pb-6 max-w-[490px]"
                      }`}
                    >
                      <PostFeedImage itemData={item} feedData={feedData} />
                      {item?.images?.length === 1 && (
                        <PostZoomInOut
                          images={item?.images[0]?.imageUrl}
                          feedData={feedData}
                        />
                      )}

                      <CommentFooter
                        isRepost={false}
                        isLike={true}
                        isComment={true}
                        id={item?.id || 0}
                        handleCommentSection={handleCommentSection}
                        handleLikeOutside={handleLikeOutside}
                        countLikes={item?.countLikes}
                        countComments={item?.countComments}
                        likedByMe={item?.likedByMe}
                      />
                    </div>
                    {(item?.video || item?.images) && (
                      // when there is image or video show text at bottom
                      <PostDescription description={item?.text?.contentText} />
                    )}

                    {renderCommentsSection(item.id || 0)}
                  </li>
                </>
              );
            })}
          </ul>
        )}
        {loading && <div>Loading...</div>} {/* Loading Spinner */}
      </div>
    </div>
  );
}

export default ProfileFeed;
