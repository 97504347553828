import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./slices/userSlice";
import siteLoaderReducer from "./slices/siteLoaderSlice";
import toastReducer from "./slices/toastSlice";
import profileReducer from "./slices/profileSlice";
import countryReducer from "./slices/countriesSlice";
import servicesReducer from "./slices/otherServices";
import PaymentInfoReducer from "./slices/paymentInfo";
import IsConfirmServiceReducer from "./slices/isConfirmServicesSlice";
import langaugeIdReducer from "./slices/website/languageSlice";
import sessionStorage from "redux-persist/es/storage/session";
import commonListData from "./slices/website/commonSlice";
import signUpDetails from "./slices/website/signUpOtpDetailsSlice";
import notificationListData from "./slices/website/notificationSlice";

const persistConfig = {
  key: "Artgapi_Web",
  storage: sessionStorage,
  whitelist: ["user", "language", "notificationListData"],
};

const rootReducer = combineReducers({
  user: userReducer,
  siteLoader: siteLoaderReducer,
  toast: toastReducer,
  profile: profileReducer,
  countries: countryReducer,
  services: servicesReducer,
  paymentInfo: PaymentInfoReducer,
  isConfirmService: IsConfirmServiceReducer,
  language: langaugeIdReducer,
  signUpUserDetails: signUpDetails,
  commonListData: commonListData,
  notificationListData: notificationListData,
});

export default persistReducer(persistConfig, rootReducer);
